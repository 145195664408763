import React from "react";

const RequestLogImg = ({ fill = "#0C0C0C" }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5 2.5H2.5C1.121 2.5 0 3.622 0 5V21.5H24V5C24 3.622 22.879 2.5 21.5 2.5ZM23 5V8.5H7V3.5H21.5C22.327 3.5 23 4.173 23 5ZM7 9.5H23V14.5H7V9.5ZM6 14.5H1V9.5H6V14.5ZM2.5 3.5H6V8.5H1V5C1 4.173 1.673 3.5 2.5 3.5ZM1 15.5H6V20.5H1V15.5ZM7 20.5V15.5H23V20.5H7Z"
      fill={fill}
    />
  </svg>
);

export default RequestLogImg;
