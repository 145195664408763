export const requestStates = (value) => {
  if (value.toString() === "OFPD") {
    return "Awaiting Acceptance";
  } else if (value.toString() === "DBCD") {
    return "Awaiting Repayment";
  } else if (value.toString() === "AODCD") {
    return "Awaiting Repayment";
  } else if (value.toString() === "ACCEPTED") {
    return "Accepted";
  } else if (value.toString() === "POAD") {
    return "Awaiting Disbursement";
  } else if (value.toString() === "OFAD") {
    return "Awaiting Disbursement";
  } else if (value.toString() === "FRAD") {
    return "Finance Request Approved";
  } else if (value.toString() === "CANCELLED") {
    return "Cancelled";
  } else if (value.toString() === "COMPLETED") {
    return "Completed";
  } else if (value.toString() === "FRCD") {
    return "Request Closed";
  } else if (value.toString() === "FRDL") {
    return "Request Declined";
  } else if (value.toString() === "RODL") {
    return "Request Offer Declined";
  } else if (value.toString() === "REQUEST_CREATED") {
    return "Request Created";
  } else if (value.toString() === "DECLINED") {
    return "Declined";
  } else if (value.toString() === "RPDF") {
    return "Default";
  } else if (value.toString() === "AORDF ") {
    return "Default";
  } else if (value.toString() === "DISBURSED") {
    return "Disbursed";
  } else if (value.toString() === "DISBURSEMENT") {
    return "Disbursement";
  } else if (value.toString() === "DISBURSEMENT_DONE") {
    return "Disbursement Done";
  } else if (value.toString() === "DBCR") {
    return "Disbursement Created";
  } else if (value.toString() === "DBIN") {
    return "Disbursement Initiated";
  } else if (value.toString() === "DBCD") {
    return "Disbursement Completed";
  } else if (value.toString() === "POAD ") {
    return "Disbursement Pending";
  } else if (value.toString() === "POAD ") {
    return "Disbursement Pending";
  } else if (value.toString() === "POEX") {
    return "Expired";
  } else if (value.toString() === "FAILED") {
    return "Failed";
  } else if (value.toString() === "FRCD") {
    return "Finance Request Closed";
  } else if (value.toString() === "FULLY_PAID") {
    return "Fully Paid";
  } else if (value.toString() === "IN_PROCESS") {
    return "In Process";
  } else if (value.toString() === "INPROCESS") {
    return "In Process";
  } else if (value.toString() === "INITIATED") {
    return "Initiated";
  } else if (value.toString() === "OFFER_ACCEPTED") {
    return "Offer Accepted";
  } else if (value.toString() === "FREX") {
    return "Finance Request Expired";
  } else if (value.toString() === "OFPD") {
    return "Offer Placed";
  } else if (value.toString() === "ROCR") {
    return "Open Offer";
  } else if (value.toString() === "OPEN_REQUEST") {
    return "Open Request";
  } else if (value.toString() === "OVERDUE") {
    return "Overdue";
  } else if (value.toString() === "PARTIALLY_PAID") {
    return "Partially Paid";
  } else if (value.toString() === "PDCD") {
    return "Placed Offer Closed";
  } else if (value.toString() === "PLACED_OFFER_DECLINED") {
    return "Placed Offer Declined";
  } else if (value.toString() === "FRARJ") {
    return "Request Rejected";
  } else if (value.toString() === "ROEX") {
    return "Request Expired";
  } else if (value.toString() === "FRCR") {
    return "Awaiting Offer";
  } else if (value.toString() === "DBCD ") {
    return "Pending Repayment";
  } else if (value.toString() === "DBCD ") {
    return "Repayment Pending";
  } else if (value.toString() === "REQUEST_ACCEPTED") {
    return "Request Accepted";
  } else if (value.toString() === "REQUEST_INPROCESS") {
    return "Request Inprocess";
  } else if (value.toString() === "AORIN ") {
    return "Repayment Done";
  } else if (value.toString() === "RPCD") {
    return "Repayment Done";
  } else if (value.toString() === "ROCD") {
    return "Request Offer Closed";
  } else if (value.toString() === "FRPR") {
    return "Request Processed";
  } else if (value.toString() === "REQUEST_PROCESSING") {
    return "Request Processing";
  } else if (value.toString() === "PDCD") {
    return "Placed Offer Closed";
  } else if (value.toString() === "REQUEST_RAISED") {
    return "Request Raised";
  } else if (value.toString() === "PROCESSING") {
    return "Processing";
  } else if (value.toString() === "FRARJ") {
    return "Rejected";
  } else if (value.toString() === "FRPRJ") {
    return "Rejected by profintech";
  } else if (value.toString() === "RPCR") {
    return "Repayment Created";
  } else if (value.toString() === "RPIN") {
    return "Repayment Initiated";
  } else if (value.toString() === "SCHEDULED") {
    return "Scheduled";
  } else if (value.toString() === "FRUR") {
    return "Finance Request Under Review";
  } else if (value.toString() === "STCR") {
    return "Settlement Created";
  } else if (value.toString() === "STCD") {
    return "Settlement Completed";
  } else if (value.toString() === "PSCR") {
    return "Participant Settlement Created";
  } else if (value.toString() === "PSCD") {
    return "Participant Settlement Completed";
  } else if (value.toString() === "RPOD ") {
    return "Repayment Overdue";
  } else if (value.toString() === "AOROD ") {
    return "Repayment Overdue";
  } else if (value.toString() === "PENDING_DISBURSEMENT") {
    return "Pending Disbursement";
  } else if (value.toString() === "RPPP") {
    return "Repayment Partialy Paid";
  } else if (value.toString() === "ROIP") {
    return "Request Offer Creation In-progess ";
  } else if (value.toString() === "ROPD") {
    return "Request Offer Placed ";
  } else if (value.toString() === "RPPCD") {
    return "Repayment Paid Partially ";
  } else if (value.toString() === "AORPCD") {
    return "Repayment Paid Partially ";
  } else if (value.toString() === "PORJ") {
    return "Placed Offer Rejected";
  } else if (value.toString() === "POCS") {
    return "Placed Offer Closed";
  }
};

export const newstates = (value) => {
  if (value.toString() === "NOT_REVIEWED") {
    return "Not Reviewed";
  } else if (value.toString() === "APPROVED") {
    return "Approved";
  } else if (value.toString() === "FRARJ") {
    return "Rejected";
  }
};
export const dateFormat = (date, days) => {
  date.setDate(date.getDate() + days);
  let month = date.toLocaleString("default", { month: "short" }); //months from 1-12
  let day = date.getUTCDate();
  let year = date.getUTCFullYear();
  let hour = date.getHours();
  let minutes = date.getMinutes();
  return month + " " + day + " " + year + ", " + hour + ":" + minutes;
};

const actionDescription = (actionCode) => {
  if (actionCode === "fetch") {
    return "Fetching ....";
  } else if (actionCode === "new") {
    return "Creating a new Placed Offer";
  }
};

export const contextObj = (authContextObj, id) => {
  return {
    context: authContextObj,
    message: {
      id: id,
    },
  };
};
export const contextObjforcrepostapicall = (
  authContextObj,
  id,
  credata,
  individualAssessmentList,
  selectedRecords,
  draftRequest
) => {
  return {
    context: authContextObj,
    anchorTraderUlidId: id,
    cbcreProcess: credata,
    individualAssessmentList: individualAssessmentList,
    tradeMasterList: selectedRecords,
    draftRequest: draftRequest,
  };
};

export const contextObjforcreapicall = (authContextObj, id, amount) => {
  return {
    context: authContextObj,
    anchorTraderUlidId: id,
    amount: amount,
  };
};

export const contextObjforlimitsapicall = (authContextObj, id) => {
  return {
    context: authContextObj,
    anchorTraderUlidId: id,
  };
};
export const contextforfilterapicall = (
  authContextObj,
  id,
  giveIndividualAssessmentsList
) => {
  return {
    context: authContextObj,
    message: {
      id: id,
      msgDetails: {
        individualAssessmentsList: giveIndividualAssessmentsList,
      },
    },
  };
};
export const contextObjforapicall = (authContextObj, id) => {
  return {
    context: authContextObj,
    message: {
      id: id,
    },
  };
};

//set disursment start
export const contextObjforapicallStartWizard = (authContextObj) => {
  return {
    context: authContextObj,
    message: {
      startWizard: {
        wizardTypeId: "1500",
      },
      msgDetails: {},
    },
  };
};

export const analyticsDashboardObject = (gstId) => {
  return [
    {
      template_id: "12",
      temp_variables: {
        ptgstin: gstId,
        fiscal_year: "'FY 2024-2025'",
        fiscal_year1: "'FY 2023-2024'",
      },
    },
    {
      template_id: "13",
      temp_variables: {
        ptgstin: gstId,
        fiscal_year: "'FY 2024-2025'",
        fiscal_year1: "'FY 2023-2024'",
      },
    },
    {
      template_id: "14",
      temp_variables: {
        ptgstin: gstId,
        fiscal_year: "'FY 2024-2025'",
        fiscal_year1: "'FY 2023-2024'",
      },
    },
    {
      template_id: "15",
      temp_variables: {
        ptgstin: gstId,
        fiscal_year: "'FY 2024-2025'",
        fiscal_year1: "'FY 2023-2024'",
      },
    },
    {
      template_id: "16",
      temp_variables: {
        ptgstin: gstId,
        fiscal_year: "'FY 2024-2025'",
        fiscal_year1: "'FY 2023-2024'",
      },
    },
    {
      template_id: "17",
      temp_variables: {
        ptgstin: gstId,
        fiscal_year: "'FY 2024-2025'",
        fiscal_year1: "'FY 2023-2024'",
      },
    },
    {
      template_id: "18",
      temp_variables: {
        ptgstin: gstId,
        fiscal_year: "'FY 2024-2025'",
        fiscal_year1: "'FY 2023-2024'",
      },
    },
    {
      template_id: "19",
      temp_variables: {
        ptgstin: gstId,
        fiscal_year: "'FY 2024-2025'",
        fiscal_year1: "'FY 2023-2024'",
      },
    },
    {
      template_id: "20",
      temp_variables: {
        ptgstin: gstId,
        fiscal_year: "'FY 2024-2025'",
        fiscal_year1: "'FY 2023-2024'",
      },
    },
    {
      template_id: "21",
      temp_variables: {
        ptgstin: gstId,
        fiscal_year: "'FY 2024-2025'",
        fiscal_year1: "'FY 2023-2024'",
      },
    },
    {
      template_id: "22",
      temp_variables: {
        ptgstin: gstId,
        fiscal_year: "'FY 2024-2025'",
        fiscal_year1: "'FY 2023-2024'",
      },
    },
  ];
};

export const contextObjforapicallNextBtnWizard = (
  authContextObj,
  wizardId,
  wizardStepInstanceId,
  wizardPageId,
  nextButtonId
) => {
  return {
    context: authContextObj,
    message: {
      nextButton: {
        wizardTypeId: "1500",
        wizardId: wizardId,
        wizardInstanceId: wizardStepInstanceId,
        wizardPageId: wizardPageId,
        nextButtonId: nextButtonId,
      },
      msgDetails: {},
    },
  };
};

export const contextObjforapicallActionWizard = (
  authContextObj,
  wizardId,
  wizardStepInstanceId,
  wizardPageId,
  actionButtonId,
  acceptedOfferId
) => {
  return {
    context: authContextObj,
    message: {
      actionButton: {
        wizardTypeId: "1500",
        wizardId: wizardId,
        wizardInstanceId: wizardStepInstanceId,
        wizardPageId: wizardPageId,
        actionButtonId: actionButtonId,
      },
      msgDetails: {
        acceptedOfferId: acceptedOfferId,
      },
    },
  };
};

export const tradeDocReaderGetATController = (id) => {
  return {
    template_id: id,
  };
};

export const fileUploadTradeDoc = (file) => {
  return {
    file,
  };
};

export const searchFilesControllerObj = (path) => {
  return {
    gcsPath: path,
  };
};

export const pdfControllerObj = (key, bucket) => {
  return {
    gcs_key: key,
    gcs_bucket: bucket,
    expiration_time: 3600,
  };
};
export const controllerObjectForShowInfo = (path) => {
  return {
    gcs_path: path,
  };
};
export const contextObjforapicallCloseWizard = (
  authContextObj,
  wizardId,
  wizardStepInstanceId,
  id
) => {
  return {
    context: authContextObj,
    message: {
      closeButton: {
        wizardId: wizardId,
        wizardInstanceId: wizardStepInstanceId,
        closeButtonId: id,
      },
      msgDetails: {},
    },
  };
};

// set Disbursment closed

//reconcile Disbursment

export const contextObjforStartWizardReconcile = (authContextObj) => {
  return {
    context: authContextObj,
    message: {
      startWizard: {
        wizardTypeId: "1501",
      },
      msgDetails: {},
    },
  };
};

export const contextObjforNextWizardReconcile = (
  authContextObj,
  wizardId,
  wizardStepInstanceId,
  wizardPageId,
  nextButtonId
) => {
  return {
    context: authContextObj,
    message: {
      nextButton: {
        wizardTypeId: "1501",
        wizardId: wizardId,
        wizardInstanceId: wizardStepInstanceId,
        wizardPageId: wizardPageId,
        nextButtonId: nextButtonId,
      },
      msgDetails: {},
    },
  };
};

export const contextObjforCloseWizardReconcileDisb = (
  authContextObj,
  wizardId,
  wizardStepInstanceId,
  closeId
) => {
  return {
    context: authContextObj,
    message: {
      closeButton: {
        // wizardTypeId: "1501",
        wizardId: wizardId,
        wizardInstanceId: wizardStepInstanceId,
        // wizardPageId: wizardPageId,
        closeButtonId: closeId,
      },
      msgDetails: {},
    },
  };
};
export const contextObjReconcileActionWizard = (
  authContextObj,
  wizardId,
  wizardStepInstanceId,
  wizardPageId,
  actionButtonId,
  id
) => {
  return {
    context: authContextObj,
    message: {
      actionButton: {
        wizardTypeId: "1501",
        wizardId: wizardId,
        wizardInstanceId: wizardStepInstanceId,
        wizardPageId: wizardPageId,
        actionButtonId: actionButtonId,
      },
      msgDetails: {
        escrowTransactionRecordId: id,
      },
    },
  };
};

export const contextObjReconcilePrepareDisbAction = (
  authContextObj,
  wizardId,
  wizardStepInstanceId,
  wizardPageId,
  actionButtonId,
  id
) => {
  return {
    context: authContextObj,
    message: {
      actionButton: {
        wizardTypeId: "1501",
        wizardId: wizardId,
        wizardInstanceId: wizardStepInstanceId,
        wizardPageId: wizardPageId,
        actionButtonId: actionButtonId,
      },
      msgDetails: {
        disbursementExecuteAPIRequest: { financeRequestId: id },
      },
    },
  };
};

export const contextObjReconcileBeneValAction = (
  authContextObj,
  wizardId,
  wizardStepInstanceId,
  wizardPageId,
  actionButtonId,
  beneValidationS3Path,
  disbursement
) => {
  return {
    context: authContextObj,
    message: {
      actionButton: {
        wizardTypeId: "1501",
        wizardId: wizardId,
        wizardInstanceId: wizardStepInstanceId,
        wizardPageId: wizardPageId,
        actionButtonId: actionButtonId,
      },
      msgDetails: {
        triggerBeneValidationRequest: {
          disbursement: disbursement,
          beneValidationS3Path: beneValidationS3Path,
        },
      },
    },
  };
};

export const contextObjReconcileFundTransferAction = (
  authContextObj,
  wizardId,
  wizardStepInstanceId,
  wizardPageId,
  actionButtonId,
  path
) => {
  return {
    context: authContextObj,
    message: {
      actionButton: {
        wizardTypeId: "1501",
        wizardId: wizardId,
        wizardInstanceId: wizardStepInstanceId,
        wizardPageId: wizardPageId,
        actionButtonId: actionButtonId,
      },
      msgDetails: {
        triggerFundsTransferRequest: {
          fundTransferS3Path: path,
        },
      },
    },
  };
};

export const contextObjReconcileDisbCompleteDisbursment = (
  authContextObj,
  wizardId,
  wizardStepInstanceId,
  wizardPageId,
  actionButtonId,
  disbursement,
  placedOffer,
  acceptedOffer,
  disbursementDate
) => {
  return {
    context: authContextObj,
    message: {
      actionButton: {
        wizardTypeId: "1501",
        wizardId: wizardId,
        wizardInstanceId: wizardStepInstanceId,
        wizardPageId: wizardPageId,
        actionButtonId: actionButtonId,
      },
      msgDetails: {
        completeDisbursementAPIRequest: {
          disbursement: disbursement,
          placedOffer: placedOffer,
          acceptedOffer: acceptedOffer,
          disbursementDate: disbursementDate,
        },
      },
    },
  };
};

export const contextObjReconcileDisbuForPrepareRepayment = (
  authContextObj,
  wizardId,
  wizardStepInstanceId,
  wizardPageId,
  actionButtonId,
  id
) => {
  return {
    context: authContextObj,
    message: {
      actionButton: {
        wizardTypeId: "1501",
        wizardId: wizardId,
        wizardInstanceId: wizardStepInstanceId,
        wizardPageId: wizardPageId,
        actionButtonId: actionButtonId,
      },
      msgDetails: {
        createRepaymentAPIRequest: {
          disbursementId: id,
        },
      },
    },
  };
};

//Reconcile Repayment wizard

export const contextObjforStartWizardRepayment = (authContextObj) => {
  return {
    context: authContextObj,
    message: {
      startWizard: {
        wizardTypeId: "1502",
      },
      msgDetails: {},
    },
  };
};

export const contextObjforNextWizardRepayment = (
  authContextObj,
  wizardId,
  wizardStepInstanceId,
  wizardPageId,
  nextButtonId
) => {
  return {
    context: authContextObj,
    message: {
      nextButton: {
        wizardTypeId: "1502",
        wizardId: wizardId,
        wizardInstanceId: wizardStepInstanceId,
        wizardPageId: wizardPageId,
        nextButtonId: nextButtonId,
      },
      msgDetails: {},
    },
  };
};

export const contextObjReconcileRepaymentAction = (
  authContextObj,
  wizardId,
  wizardStepInstanceId,
  wizardPageId,
  actionButtonId,
  id
) => {
  return {
    context: authContextObj,
    message: {
      actionButton: {
        wizardTypeId: "1502",
        wizardId: wizardId,
        wizardInstanceId: wizardStepInstanceId,
        wizardPageId: wizardPageId,
        actionButtonId: actionButtonId,
      },
      msgDetails: {
        adminReconcileRepaymentAPIRequest: { escrowTransactionRecordId: id },
      },
    },
  };
};

export const contextObjSetSettlementAction = (
  authContextObj,
  wizardId,
  wizardStepInstanceId,
  wizardPageId,
  actionButtonId,
  id
) => {
  return {
    context: authContextObj,
    message: {
      actionButton: {
        wizardTypeId: "1502",
        wizardId: wizardId,
        wizardInstanceId: wizardStepInstanceId,
        wizardPageId: wizardPageId,
        actionButtonId: actionButtonId,
      },
      msgDetails: {
        genericAPIRequest: { repaymentId: id },
      },
    },
  };
};

// Settlement step logic

export const contextObjforStartWizardExecuteSettlement = (authContextObj) => {
  return {
    context: authContextObj,
    message: {
      startWizard: {
        wizardTypeId: "1503",
      },
      msgDetails: {},
    },
  };
};

export const contextObjforNextWizardSettlement = (
  authContextObj,
  wizardId,
  wizardStepInstanceId,
  wizardPageId,
  nextButtonId
) => {
  return {
    context: authContextObj,
    message: {
      nextButton: {
        wizardTypeId: "1503",
        wizardId: wizardId,
        wizardInstanceId: wizardStepInstanceId,
        wizardPageId: wizardPageId,
        nextButtonId: nextButtonId,
      },
      msgDetails: {},
    },
  };
};

export const contextObjPrepareSettlementObject = (
  authContextObj,
  wizardId,
  wizardStepInstanceId,
  wizardPageId,
  actionButtonId,
  financeRequestId,
  participantSettlementId
) => {
  return {
    context: authContextObj,
    message: {
      actionButton: {
        wizardTypeId: "1503",
        wizardId: wizardId,
        wizardInstanceId: wizardStepInstanceId,
        wizardPageId: wizardPageId,
        actionButtonId: actionButtonId,
      },
      msgDetails: {
        preparePartcipantSettlementRequest: {
          financeRequestId: financeRequestId,
          participantSettlementId: participantSettlementId,
        },
      },
    },
  };
};

export const contextObjBeneValidation = (
  authContextObj,
  wizardId,
  wizardStepInstanceId,
  wizardPageId,
  actionButtonId,
  participantSettlement,
  beneValidationS3Path
) => {
  return {
    context: authContextObj,
    message: {
      actionButton: {
        wizardTypeId: "1503",
        wizardId: wizardId,
        wizardInstanceId: wizardStepInstanceId,
        wizardPageId: wizardPageId,
        actionButtonId: actionButtonId,
      },
      msgDetails: {
        triggerBeneValidationRequest: {
          participantSettlement: participantSettlement,
          beneValidationS3Path: beneValidationS3Path,
        },
      },
    },
  };
};

export const contextObjFundsTransferSettlement = (
  authContextObj,
  wizardId,
  wizardStepInstanceId,
  wizardPageId,
  actionButtonId,
  fundTransferS3Path
) => {
  return {
    context: authContextObj,
    message: {
      actionButton: {
        wizardTypeId: "1503",
        wizardId: wizardId,
        wizardInstanceId: wizardStepInstanceId,
        wizardPageId: wizardPageId,
        actionButtonId: actionButtonId,
      },
      msgDetails: {
        triggerFundsTransferRequest: {
          fundTransferS3Path: fundTransferS3Path,
        },
      },
    },
  };
};

export const contextObjForParticipantSettlementCompletion = (
  authContextObj,
  wizardId,
  wizardStepInstanceId,
  wizardPageId,
  actionButtonId,
  participantSettlement,
  fundsTransferTransactionDetails
) => {
  return {
    context: authContextObj,
    message: {
      actionButton: {
        wizardTypeId: "1503",
        wizardId: wizardId,
        wizardInstanceId: wizardStepInstanceId,
        wizardPageId: wizardPageId,
        actionButtonId: actionButtonId,
      },
      msgDetails: {
        completeParticipantSettlementRequest: {
          participantSettlement: participantSettlement,
          fundsTransferTransactionDetails: fundsTransferTransactionDetails,
        },
      },
    },
  };
};

export const contextObjForSettlementCompletion = (
  authContextObj,
  wizardId,
  wizardStepInstanceId,
  wizardPageId,
  actionButtonId,
  financeRequest,
  requestOffer,
  placedOffer,
  acceptedOffer,
  repayment,
  settlement
) => {
  return {
    context: authContextObj,
    message: {
      actionButton: {
        wizardTypeId: "1503",
        wizardId: wizardId,
        wizardInstanceId: wizardStepInstanceId,
        wizardPageId: wizardPageId,
        actionButtonId: actionButtonId,
      },
      msgDetails: {
        completeSettlementRequest: {
          financeRequest: financeRequest,
          requestOffer: requestOffer,
          placedOffer: placedOffer,
          acceptedOffer: acceptedOffer,
          repayment: repayment,
          settlement: settlement,
        },
      },
    },
  };
};

export const contextObjforCloseWizardSettlement = (
  authContextObj,
  wizardId,
  wizardStepInstanceId,
  closeId
) => {
  return {
    context: authContextObj,
    message: {
      closeButton: {
        // wizardTypeId: "1501",
        wizardId: wizardId,
        wizardInstanceId: wizardStepInstanceId,
        // wizardPageId: wizardPageId,
        closeButtonId: closeId,
      },
      msgDetails: {},
    },
  };
};

////////////////////////////////////////////////
export const objectforOPSsetdisb = (id) => {
  return {
    acceptedOfferId: id,
  };
};
export const objectforOPSReconciledisb = (id) => {
  return {
    escrowTransactionRecordId: id,
  };
};

export const objectforOPSPrepareDisb = (id) => {
  return {
    financeRequestId: id,
  };
};
export const objectforOPSForReconcileRep = (id) => {
  return {
    escrowTransactionRecordId: id,
  };
};
export const objectforRealfundsTransfer = (path) => {
  return {
    fundTransferS3Path: path,
  };
};
export const objectforsetrepaymentdetails = (id) => {
  return {
    disbursementId: id,
  };
};
export const objecttocompleteDisbursment = (
  disbursement,
  placedOffer,
  acceptedOffer,
  disbursementDate
) => {
  return {
    disbursement: disbursement,
    placedOffer: placedOffer,
    acceptedOffer: acceptedOffer,
    disbursementDate: disbursementDate,
  };
};

export const objectforsetSettlement = (id) => {
  return {
    repaymentId: id,
  };
};

export const completeParticipantSettlement = (
  participantSettlement,
  fundsTransferTransactionDetails
) => {
  return {
    participantSettlement: participantSettlement,
    fundsTransferTransactionDetails: fundsTransferTransactionDetails,
  };
};
export const PreparetriggerSettlementFundsTransfer = (fundTransferS3Path) => {
  return {
    fundTransferS3Path: fundTransferS3Path,
  };
};

export const CompleteSettlement = (
  financeRequest,
  requestOffer,
  placedOffer,
  acceptedOffer,
  repayment,
  settlement
) => {
  return {
    financeRequest: financeRequest,
    requestOffer: requestOffer,
    placedOffer: placedOffer,
    acceptedOffer: acceptedOffer,
    repayment: repayment,
    settlement: settlement,
  };
};
export const PreparetriggerSettlementBeneValidation = (
  participantSettlement,
  beneValidationS3Path
) => {
  return {
    participantSettlement: participantSettlement,
    beneValidationS3Path: beneValidationS3Path,
  };
};
export const PrepareParticipantSettlement = (
  financeRequestId,
  participantSettlementId
) => {
  return {
    financeRequestId: financeRequestId,
    participantSettlementId: participantSettlementId,
  };
};
export const objectforBeneval = (beneValidationS3Path, disbursement) => {
  return {
    disbursement: disbursement,
    beneValidationS3Path: beneValidationS3Path,
  };
};

export const contextforrejectingOffer = (authContextObj, id, finalReason) => {
  return {
    context: authContextObj,
    message: {
      requestOfferId: id,
      rejectedOfferReason: finalReason,
    },
  };
};
export const contextObjForPlacingOffer = (
  authContextObj,
  requestOfferObj,
  placedOfferObj
) => {
  return {
    context: authContextObj,
    message: {
      id: "",
      msgDetails: {
        requestOffer: requestOfferObj,
        placedOffer: placedOfferObj,
      },
    },
  };
};

export const contextObjController = (authContextObj, anchorTraderID) => {
  return {
    context: authContextObj,
    message: {
      id: anchorTraderID,
    },
  };
};

export const profileContextObjController = (
  authContextObj,
  anchorTraderID,
  anchorTraderObj = {}
) => {
  return {
    context: authContextObj,
    message: {
      id: anchorTraderID,
      msgDetails: {
        anchorTrader: anchorTraderObj,
        firmMemberDetailsList: [],
      },
      // ...(searchText ? {query: `${searchText}${!isNaN(searchText) ? `  OR exportID:${searchText}` : ''}`} : {}),
    },
  };
};
export const signUp = (formData) => {
  return {
    firmName: formData.firmName,
    ownerName: formData?.ownerName,
    email: formData?.emailAddress,
    gstNumber: formData?.gstNumber,
    typeOfFirm: formData?.typeOfFirm,
    phoneNumber: formData?.phoneNumber,
    address: formData?.address,
    personaType: formData?.sellerBuyer,
    controllerSignUp: true,
  };
};
export const multigstController = (
  authContextObj,
  anchorTraderID,
  gstcompanyname,
  gstNumber
) => {
  return {
    context: authContextObj,
    message: {
      id: anchorTraderID,
      companyName: gstcompanyname,
      companyGstId: gstNumber,
    },
  };
};

export const kycMultiGstIdController = (
  authContextObj,
  gstId,
  anchorTraderID
) => {
  return {
    context: authContextObj,
    message: {
      id: anchorTraderID,
      gstinId: gstId,
    },
  };
};

export const objectforGetERP = (authContextObj, gstinId, isErpAccess) => {
  return {
    context: authContextObj,
    message: {
      gstinId: gstinId,
      isErpAccess: isErpAccess,
    },
  };
};
export const kycContextObjController = (
  authContextObj,
  anchorTraderID,
  anchorTraderObj = {},
  firmMemberDetailsList,
  kycMaster = {},
  gstinId
) => {
  return {
    context: authContextObj,
    message: {
      id: anchorTraderID,
      gstinId: gstinId,
      msgDetails: {
        anchorTrader: anchorTraderObj,
        kycMaster: kycMaster,
        firmMemberDetailsList: firmMemberDetailsList,
      },
      // ...(searchText ? {query: `${searchText}${!isNaN(searchText) ? `  OR exportID:${searchText}` : ''}`} : {}),
    },
  };
};

export const panValidateContextObjController = (
  authContextObj,
  anchorTraderID,
  gstID,
  panRequest
) => {
  return {
    context: authContextObj,
    message: {
      id: anchorTraderID,
      gstinId: gstID,
      msgDetails: {
        panRequest: panRequest,
      },
    },
  };
};

export const generateOTPContextObjController = (
  authContextObj,
  id,
  generateOTPRequest
) => {
  return {
    context: authContextObj,
    message: {
      id: "",
      msgDetails: {
        generateOTPRequest: generateOTPRequest,
      },
    },
  };
};

export const verifyOTPContextObjController = (
  authContextObj,
  id,
  gstID,
  validateOTPRequest
) => {
  return {
    context: authContextObj,
    message: {
      id: id,
      gstinId: gstID,
      msgDetails: {
        validateOTPRequest: validateOTPRequest,
      },
    },
  };
};

export const presignedContextObjController = (
  authContextObj,
  id,
  documentCode
) => {
  return {
    context: authContextObj,
    message: {
      id: id,
      msgDetails: {
        documentCode: documentCode,
      },
    },
  };
};

export const CreateRequestOffer = (
  financeRequestId,
  interestPercentage,
  term
) => {
  return {
    financeRequestId: financeRequestId,
    interestPercentage: interestPercentage,
    term: term,
  };
};
export const CreateTradeConstructObject = (
  authContextObj,
  tradeMasterDocReader
) => {
  return {
    context: authContextObj,
    message: {
      id: "",
      msgDetails: {
        tradeMaster: tradeMasterDocReader,
      },
    },
  };
};
export const gstCompanyDetailsContextObjController = (authContextObj, id) => {
  return {
    context: authContextObj,
    message: {
      id: id,
    },
  };
};

export const TemplateBasedFpCall = (id) => {
  return {
    template_id: id,
  };
};
export const gstCompanyGenerateOTPContextObjController = (
  authContextObj,
  gstNumber,
  gstUsername
) => {
  return {
    context: authContextObj,
    message: {
      msgDetails: {
        generateGSTOTPRequest: {
          gstin_no: gstNumber,
          gstin_username: gstUsername,
        },
      },
    },
  };
};

export const gstCompanyVerifyOTPContextObjController = (
  authContextObj,
  gstNumber,
  gstUsername,
  gstOTP,
  accessToken
) => {
  return {
    context: authContextObj,
    message: {
      msgDetails: {
        verifyGSTOTPRequest: {
          gstin_no: gstNumber,
          gstin_username: gstUsername,
          gstin_otp: gstOTP,
          access_token: accessToken,
        },
      },
    },
  };
};

export const onAcceptedOfferContextObjController = (
  authContextObj,
  placedOfferUlidId,
  financeRequestUlidId,
  msgDetails
) => {
  return {
    context: authContextObj,
    message: {
      placedOfferUlidId: placedOfferUlidId,
      financeRequestUlidId: financeRequestUlidId,
      msgDetails: msgDetails,
    },
  };
};
