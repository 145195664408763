import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Alert,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
} from "@mui/material";
import { useAuth } from "../../../context/auth";
import {
  contextObjforNextWizardRepayment,
  contextObjReconcilePrepareDisbAction,
  contextObjReconcileRepaymentAction,
  objectforOPSForReconcileRep,
} from "../../Common/Constants";
import { opscontroller } from "../../API/FP/Actions";
import { controllerAPI } from "../../API/FP/Actions";
import { useNavigate } from "react-router-dom";

const Step2 = ({
  selectedRecordDetails,
  nextStep,
  handleChange,
  formData,
  setstep2output,

  wizardRecordId,
  wizardRecordInstanceId,
  setWizardRecordInstanceId,
  wizardPageId,
  setWizardPageId,
  actionButtonId,
  setActionButtonId,
  NextButtonId,
  setNextButtonId,
}) => {
  const handleInputChange = (e) => {
    handleChange(e.target.name, e.target.value);
  };
  const [btnenable, setbtnenable] = useState(false);
  const [auth, setAuth] = useAuth();
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [showdata, setshowdata] = useState(false);
  const [data, setdata] = useState([]);
  const navigate = useNavigate();
  const [wizardRecordInstanceIdStep1, setwizardRecordInstanceIdStep1] =
    useState(wizardRecordInstanceId);

  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };
  const ActionButtonResponse = async (id) => {
    setLoading(true);
    try {
      setLoading(true);
      const constructObj = contextObjReconcileRepaymentAction(
        authContextObj,
        wizardRecordId,
        wizardRecordInstanceId,
        wizardPageId,
        actionButtonId,
        id
      );
      // Updated API call with pagination parameters
      const ATTradesresponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        `reconcile-repayment-wizard`
      );

      if (ATTradesresponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        navigate("/creditbazaar/");
      }

      if (ATTradesresponse.res.status === 200) {
        console.log("sucess");
        setActionButtonId(actionButtonId + 1);
        setwizardRecordInstanceIdStep1(
          ATTradesresponse?.res?.data?.data?.reconcileRepaymentWizardStepData
            ?.id
        );
        setOpenSnackbar(true);
        setApiResultMessage("Reconcile Repayment Successful");
        setAlertSeverity("success");
        setshowdata(true);
        setbtnenable(true);
        try {
          const parseMsg = JSON.parse(
            ATTradesresponse?.res?.data?.data?.reconcileRepaymentWizardStepData
              ?.apiResponse
          );
          const parsTwo = JSON.parse(parseMsg);
          console.log("api response data is:", parsTwo);
          setstep2output(parsTwo?.data);
          setdata(parsTwo?.data);
          console.log("data being set is :", parsTwo?.data);
        } catch (error) {
          console.error("Failed to parse API response:", error);
        }

        setbtnenable(true);
        setshowdata(true);
      }
      if (ATTradesresponse?.response?.status === 400) {
        console.log(
          "response?.response?.data?.error",
          ATTradesresponse?.response?.data?.erro
        );
        setOpenSnackbar(true);
        setApiResultMessage(ATTradesresponse?.response?.data?.error);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error fetching  details:", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error in Reconcile Repayment!");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const NextButton = async () => {
    setLoading(true);
    try {
      setLoading(true);
      const constructObj = contextObjforNextWizardRepayment(
        authContextObj,
        wizardRecordId,
        wizardRecordInstanceIdStep1,
        wizardPageId,
        NextButtonId
      );
      // Updated API call with pagination parameters
      const ATTradesresponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        `reconcile-repayment-wizard`
      );

      if (ATTradesresponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        navigate("/creditbazaar/");
      }

      if (ATTradesresponse.res.status === 200) {
        console.log("sucess");
        setNextButtonId(NextButtonId + 1);
        setWizardRecordInstanceId(
          ATTradesresponse?.res?.data?.data?.reconcileRepaymentWizardStepData
            ?.id
        );
        setWizardPageId(wizardPageId + 1);
        nextStep();
      }
      if (ATTradesresponse?.response?.status === 400) {
        console.log(
          "response?.response?.data?.error",
          ATTradesresponse?.response?.data?.erro
        );
        setOpenSnackbar(true);
        setApiResultMessage(ATTradesresponse?.response?.data?.error);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error fetching  details:", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error in Reconcile Repayment!");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  return (
    <StepContainer>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="step-content">
          <div>
            {" "}
            <h2> Step 2: Reconcile Repayment</h2>
            <h5>
              Check the details below and click on Proceed to Reconcile the
              Repayment
            </h5>
            <p>
              The Escrow Transaction Id is:
              <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                {" "}
                {selectedRecordDetails?.id}
              </span>
            </p>
            <p>
              The Virtual Account Number Created for this Repayment is :
              <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                {selectedRecordDetails?.vaNumber}{" "}
              </span>
            </p>
            <p>
              Remitter Name :{" "}
              <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                {selectedRecordDetails?.remitterName}
              </span>
            </p>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "50vh",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "400px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  disabled={btnenable}
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "#4A3AFF",
                    color: "white",
                    border: "none",
                    cursor: "pointer",
                    height: "fit-content",
                    boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                    margin: "5px",
                    opacity: !btnenable ? "1" : "0.5",
                  }}
                  onClick={() => {
                    ActionButtonResponse(selectedRecordDetails?.id);
                  }}
                >
                  Proceed
                </Button>
              </div>
            )}
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              {" "}
              {showdata === true ? (
                <>
                  <h5>Updated Data after Reconcile Repayment is successful</h5>
                  <table style={{ marginTop: "10px" }}>
                    <thead>
                      <tr>
                        <th>Repayment ID</th>
                        <th> Repayment Status</th>
                        <th>Amount to be repayed</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{data?.repayment?.id}</td>
                        <td>{data?.repayment?.repaymentStatus}</td>
                        <td>{data?.repayment?.amtToBeRepayed}</td>
                      </tr>
                    </tbody>
                  </table>
                </>
              ) : (
                <>
                  <p></p>
                </>
              )}
            </>
          )}

          <Snackbar
            open={openSnackbar}
            //autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={alertSeverity}
              sx={{ width: "100%" }}
            >
              {apiResultMessage}
            </Alert>
          </Snackbar>
        </div>
        <button
          style={{
            borderRadius: "10px",
            backgroundColor: "#4A3AFF",
            color: "white",
            border: "none",
            cursor: "pointer",
            height: "fit-content",
            boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
            margin: "5px",
            opacity: btnenable ? "1" : "0.5",
            padding: "15px 30px",
            fontSize: "15px",
          }}
          onClick={NextButton}
          className="button"
          disabled={!btnenable}
        >
          Next
        </button>
      </div>
    </StepContainer>
  );
};

export default Step2;

const StepContainer = styled.div`
  .step-content {
    max-width: 800px;
    margin: 0px 100px;
  }

  .button {
    padding: 10px 20px;
    margin: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }

  .button:hover {
    background-color: #0056b3;
  }
`;
