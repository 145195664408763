import React from "react";
import { styled } from "styled-components";
import Footer from "../Common/Footer";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from "@mui/material";

function TradePartnerSuppliers() {
  const navigate = useNavigate();
  const data = [
    {
      supplierName: "Akash Enterprises",
      supplierGST: "36AAAAA0000A1Z5",
      historicalTransactions: "2,000,000",
      engagementMonths: "15",
      contactPersonName: "Ramesh Rao",
      contactPersonNumber: "9765654321",
      statusByProfintech: "Not onboarded",
    },
    {
      supplierName: "Shetty Enterprise",
      supplierGST: "36AAAAA0000A1Q2",
      historicalTransactions: "2,40,000",
      engagementMonths: "18",
      contactPersonName: "Raghu ram",
      contactPersonNumber: "6139457820",
      statusByProfintech: "Onboarded",
    },
    {
      supplierName: "Rain Enterprises",
      supplierGST: "36AAABB0000A1Z7",
      historicalTransactions: "3,000,000",
      engagementMonths: "20",
      contactPersonName: "Vikas ",
      contactPersonNumber: "9018273546",
      statusByProfintech: "Onboarded",
    },
  ];

  return (
    <>
      <Finance1>
        <div>
          <div
            role="presentation"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              color: "#4A3AFF",
              marginTop: "50px",
            }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="rgba(74, 58, 255, 0.5)"
                // href="/financesdashboard"
                onClick={() => {
                  navigate("/creditbazaar/buyer-dashboard");
                }}
                className="custom-link"
              >
                Dashboard
              </Link>
              <Typography color="#4A3AFF">Supplier Onboarding</Typography>
            </Breadcrumbs>

            <div className="header">
              <h3 style={{ margin: "0px" }} className="section-heading">
                Supplier Onboarding
              </h3>
              {/* <Typography>
                Review finance requests tied to your invoices and purchase
                orders awaiting validation.
              </Typography> */}
            </div>
          </div>
        </div>
      </Finance1>
      <div
        style={{
          // marginTop: "1%",
          textAlign: "end",
          // marginRight: "5%",
        }}
      >
        <button
          style={{
            backgroundColor: "#4A3AFF",
            color: "white",
            border: "none",
            padding: "10px 10px",
            cursor: "pointer",
            borderRadius: "5px",
            height: "fit-content",
            marginRight: "10px",
            fontSize: "11px",
          }}
          // onClick={() => setCreditScoreReportCheck(true)}
        >
          Bulk upload of new suppliers
        </button>
        <button
          style={{
            backgroundColor: "#4A3AFF",
            color: "white",
            border: "none",
            padding: "10px 10px",
            cursor: "pointer",
            borderRadius: "5px",
            height: "fit-content",
            marginRight: "10px",
            fontSize: "11px",
          }}
          // onClick={() => setCreditScoreReportCheck(true)}
        >
          Add new suppliers
        </button>
      </div>
      <Dash className="body-text">
        <div className="main-content">
          <div className="record-details">
            <table>
              <tbody>
                <tr>
                  <th>Supplier name</th>
                  <th>Supplier GST</th>
                  <th>Value of historical transactions (INR)</th>
                  <th>Number of engagement months</th>
                  <th>Contact person name</th>
                  <th>Contact person number</th>
                  <th>Status of onboarding by ProFinTech</th>
                </tr>

                {data?.map((val, ind) => {
                  return (
                    <tr key={ind}>
                      <td>{val.supplierName}</td>
                      <td>{val.supplierGST}</td>
                      <td>{val.historicalTransactions}</td>
                      <td>{val.engagementMonths}</td>
                      <td>{val.contactPersonName}</td>
                      <td>{val.contactPersonNumber}</td>
                      <td>{val.statusByProfintech}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Dash>
      <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div>
    </>
  );
}

export default TradePartnerSuppliers;

const Finance1 = styled.div`
  .custom-link:hover {
    color: #4a3aff;
  }
  .btn {
    color: white;
    background-color: #007bff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-left: 20px;
    font-size: 20px;
    // margin-top: 20vh;
  }
  .btn1 {
    color: white;
    background-color: orange;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 20px;
    border-radius: 5px;
    display: inline-block;
    margin-left: 20px;
  }
  .btn:hover {
    background-color: #0056b3;
  }

  .content {
    margin-left: 20px;
    margin-top: 20px;
    display: flex;
  }
  .offer-card {
    border: 2px solid black;
    // width: 170%;
    margin-bottom: 20px;
  }
  .lender-details {
    margin-left: 40px;
  }

  .header {
    font-size: 18px;
    margin-top: 15px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    // width: 300%;
    border-collapse: collapse;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
  }
  th {
    background-color: #f8f9fa;
    font-weight: bold;
  }
  .search-bar {
    display: flex;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .search-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #dddddd;
    border-radius: 4px 0 0 4px;
    font-size: 14px;
    align-items: center;
  }
  .search-button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 8px;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .search-button:hover {
    background-color: #0056b3;
  }
  .popup {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  .popup .popuptext {
    width: 700px;
    visibility: hidden;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    left: 50%;
    margin-left: -80px;
    overflow-y: auto;
    border-width: 3px;
    border-style: solid;
    border-color: #000;
  }
  .popup .popuptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  .popup .show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  }
  .popup .popuptext .btn {
    visibility: hidden;
  }
  .btn5 {
    color: white;
    background-color: #007bff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-left: 65vh;
    margin-bottom: 15px;
  }
  .btn5:hover {
    background-color: #0056b3;
  }
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Dash = styled.div`
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }

  .content {
    display: flex;
    margin-top: 20px;
  }

  .main-content {
    display: flex;
    padding: 20px 0px 10px 0px;
    flex-direction: column;
  }
  .table-container {
    margin-top: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    // border-left: 1px solid rgba(0, 0, 128, 0.2);
    // border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 5px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    background: rgba(74, 58, 255, 0.1);
  }
  .record-details {
  }
`;
