import styled from "styled-components";
import React from "react";

const data = {
  name: "Sruthi",
  company: "Renova Hospitals",
  persona: "Trade Partner",
  country: "India",
  phone: "9849333256",
  email: "enquiry@Example.com",
  bankname: "ICICI Bank ",
  account: "123456789045",
  branch: "Gachibowli",
  ifsc: "ICIC0001114",
  receipient: "Renova Hospitals",
};

function TradeProfileTabOne() {
  return (
    <Finance>
      <div className="tab-content pt-2">
        <div
          className="tab-pane fade profile-overview active show"
          id="profile-overview"
          role="tabpanel"
        >
          <h5 className="card-title">Profile Details</h5>
          <div className="row">
            <div className="col-lg-3 col-md-4 label ">Full Name</div>
            <div className="col-lg-9 col-md-8">{data.name}</div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 label">Company</div>
            <div className="col-lg-9 col-md-8">{data.company}</div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 label">Persona</div>
            <div className="col-lg-9 col-md-8">{data.persona}</div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 label">Country</div>
            <div className="col-lg-9 col-md-8">{data.country}</div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 label">Address</div>
            <div className="col-lg-9 col-md-8">
              C/o.Renova Balaji Hospitals Plot No.34, NCL South,Opp.Byrraju
              Foundation,Pet 
              <br />
              Basheerabad ,Medchal Road,Secunderabad-55
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 label">Phone</div>
            <div className="col-lg-9 col-md-8">{data.phone}</div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 label">Email</div>
            <div className="col-lg-9 col-md-8">{data.email}</div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 label">Bank Name</div>
            <div className="col-lg-9 col-md-8">{data.bankname}</div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 label">Bank Account</div>
            <div className="col-lg-9 col-md-8">{data.account}</div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 label">Branch</div>
            <div className="col-lg-9 col-md-8">{data.branch}</div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 label">IFSC Code</div>
            <div className="col-lg-9 col-md-8">{data.ifsc}</div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 label">Receipient</div>
            <div className="col-lg-9 col-md-8">{data.receipient}</div>
          </div>
        </div>
      </div>
    </Finance>
  );
}

export default TradeProfileTabOne;

const Finance = styled.div`
  .tab-content {
    padding-top: 2rem;
  }

  .label {
    font-weight: 600;
    font-size: 22px;
    color: rgba(1, 41, 112, 0.6);
    width: 300px;
  }

  .row {
    display: flex;
    margin-bottom: 10px;
    padding-left: 30px;
  }
  .card-title {
    font-weight: bold;
    font-size: 24px;
  }
`;
