import React, { useState } from "react";
import {
  Container,
  Typography,
  MenuItem,
  Select,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  TableContainer,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import Page2 from "./page2";

const CustomButton = styled(Button)({
  borderRadius: "5px",
  backgroundColor: "#4A3AFF",
  color: "white",
  border: "none",
  "&:hover": {
    backgroundColor: "#00A6A6",
  },
  borderRadius: "8px",
});

const Page1 = ({ setSelectedFiles }) => {
  // Demo data for anchor traders, S3 paths, and files
  const demoAnchorTraders = [
    { id: 1, name: "Prime textiles" },
    { id: 2, name: "V1 Facilities" },
    { id: 3, name: "Appolo Hospitals" },
  ];

  const demoS3Paths = [
    { id: 1, path: "s3://bucketA/path1" },
    { id: 2, path: "s3://bucketB/path2" },
    { id: 3, path: "s3://bucketC/path3" },
  ];

  const demoFiles = [
    {
      id: 1,
      name: "PrimeTradeDocument.pdf",
      size: 150,
      uploadDate: "2024-11-01",
    },
    { id: 2, name: "V1trades.pdf", size: 300, uploadDate: "2024-11-02" },
    { id: 3, name: "TextilesTrade.pdf", size: 450, uploadDate: "2024-11-03" },
  ];

  const [anchorTraders] = useState(demoAnchorTraders);
  const [selectedAnchorTrader, setSelectedAnchorTrader] = useState("");
  const [s3Paths, setS3Paths] = useState([]);
  const [selectedS3Path, setSelectedS3Path] = useState("");
  const [files, setFiles] = useState([]);
  const [showNextWizard, setShowNextWizard] = useState(false);
  const [selectedFiles, setSelectedFilesState] = useState([]);
  const [showpageTwo, setshowpageTwo] = useState(false);
  const navigate = useNavigate();

  //   useEffect(() => {
  //     // Fetch Anchor Traders
  //     axios
  //       .get("/api/anchor-traders")
  //       .then((response) => setAnchorTraders(response.data));
  //   }, []);

  const handleAnchorTraderChange = (event) => {
    const trader = event.target.value;
    setSelectedAnchorTrader(trader);
    // Set demo S3 paths for the selected Anchor Trader
    setS3Paths(demoS3Paths);
  };

  const handleSearchFiles = () => {
    // Set demo files for the selected S3 path
    setFiles(demoFiles);
  };

  const handleFileSelection = (file) => {
    setSelectedFilesState((prev) => {
      const updatedFiles = prev.includes(file)
        ? prev.filter((f) => f !== file)
        : [...prev, file];

      // Set showNextWizard to true if there are selected files, false if none
      setShowNextWizard(updatedFiles.length > 0);

      return updatedFiles;
    });
  };

  const handleNext = () => {
    setshowpageTwo(true);
    setSelectedFiles(selectedFiles);
    //navigate("/TradeDocReaderpage2");
  };

  return (
    <>
      {showpageTwo === false ? (
        <Container maxWidth="sm" sx={{ textAlign: "center", mt: 4 }}>
          <Typography variant="h4" sx={{ color: "#333", mb: 4 }}>
            Select Anchor Trader and S3 Path
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
            <Select
              value={selectedAnchorTrader}
              onChange={handleAnchorTraderChange}
              displayEmpty
              sx={{
                flex: 1,
                mr: 1, // margin right for spacing between selects
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
              }}
            >
              <MenuItem value="" disabled>
                Select Anchor Trader
              </MenuItem>
              {anchorTraders.map((trader) => (
                <MenuItem key={trader.id} value={trader.name}>
                  {trader.name}
                </MenuItem>
              ))}
            </Select>

            <Select
              value={selectedS3Path}
              onChange={(e) => setSelectedS3Path(e.target.value)}
              displayEmpty
              sx={{
                flex: 1,
                ml: 1, // margin left for spacing between selects
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
              }}
            >
              <MenuItem value="" disabled>
                Select S3 Path
              </MenuItem>
              {s3Paths.map((path) => (
                <MenuItem key={path.id} value={path.path}>
                  {path.path}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <CustomButton
            onClick={handleSearchFiles}
            variant="contained"
            sx={{ mb: 4 }}
          >
            Search
          </CustomButton>

          {files?.length > 0 ? (
            <TableContainer sx={{ maxHeight: 300, overflowY: "auto" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>File Name</TableCell>
                    <TableCell>Size (KB)</TableCell>
                    <TableCell>Upload Date</TableCell>
                    <TableCell align="right">Select</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files.map((file) => (
                    <TableRow key={file.id}>
                      <TableCell>{file.name}</TableCell>
                      <TableCell>{file.size}</TableCell>
                      <TableCell>{file.uploadDate}</TableCell>
                      <TableCell align="right">
                        <Checkbox
                          checked={selectedFiles.includes(file)}
                          onChange={() => handleFileSelection(file)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body1" align="center">
              No files available.
            </Typography>
          )}

          {showNextWizard && (
            <CustomButton
              onClick={handleNext}
              variant="contained"
              style={{ marginTop: "30px" }}
            >
              Next
            </CustomButton>
          )}
        </Container>
      ) : (
        <Page2 selectedFiles={selectedFiles} setshowpageTwo={setshowpageTwo} />
      )}
    </>
  );
};

export default Page1;
