import axios from "axios";

export const getToken = async () => {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_URL}api/authenticate`,
    headers: {},
    data: {
      username: "admin",
      password: "admin",
      rememberMe: true,
    },
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};
