import React, { useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import styled from "styled-components";
import { Stepper, StepLabel, Step } from "@mui/material";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import StepDetails from "./WizardDetails";

const Wizard = ({
  selectedRecordDetails,
  setshowWiz,
  WizardId,
  wizardInstanceId,
}) => {
  const [step, setStep] = useState(1);
  const [step1Detailsoutput, setstep1Detailsoutput] = useState();
  const [step1output, setstep1output] = useState();
  const [step2output, setstep2output] = useState();
  const [step3output, setstep3output] = useState();
  const [step4output, setstep4output] = useState();
  const [step5output, setstep5output] = useState();
  const [step6output, setstep6output] = useState();
  const [wizardRecordId, setWizardRecordId] = useState(WizardId);
  const [wizardRecordInstanceId, setWizardRecordInstanceId] =
    useState(wizardInstanceId);
  const [actionButtonId, setActionButtonId] = useState(1);
  const [NextButtonId, setNextButtonId] = useState(1);
  const [wizardPageId, setWizardPageId] = useState(1);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    contactNumber: "",
    email: "",
    country: "",
    district: "",
    city: "",
    landmark: "",
    postal: "",
  });
  console.log("presentdetails", selectedRecordDetails);
  const steps = [
    "Disbursment Details",
    "Reconcile Disbursement",
    "Disbursment Preparation",
    "Bene Validation",
    "Funds Transfer ",
    "Disbursement Completion",
    "Repayment Creation",
  ];

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleChange = (input, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [input]: value,
    }));
  };

  const handleSubmit = () => {
    console.log(formData);
    // You can add the API call here using fetch or axios
    // Example using fetch:
    // fetch('https://your-api-url.com/submit', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify(formData)
    // })
    // .then(response => response.json())
    // .then(data => console.log(data))
    // .catch(error => console.error('Error:', error));
  };
  console.log("step1 output is in wizard :", step1output);
  console.log("step2 output is in wizard :", step2output);
  console.log("Benevalidation output in wizard is ", step3output);
  console.log("step4 output is:", step4output);
  console.log("step 5 from wizard is :", step5output);
  console.log("step 6 output from wizard is :", step6output);
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <StepDetails
            selectedRecordDetails={selectedRecordDetails}
            nextStep={nextStep}
            handleChange={handleChange}
            formData={formData}
            setstep1Detailsoutput={setstep1Detailsoutput}
            setshowWiz={setshowWiz}
            wizardRecordId={wizardRecordId}
            wizardRecordInstanceId={wizardRecordInstanceId}
            setWizardRecordInstanceId={setWizardRecordInstanceId}
            wizardPageId={wizardPageId}
            setWizardPageId={setWizardPageId}
            NextButtonId={NextButtonId}
            setNextButtonId={setNextButtonId}
          />
        );

      case 2:
        return (
          <Step1
            selectedRecordDetails={selectedRecordDetails}
            nextStep={nextStep}
            handleChange={handleChange}
            formData={formData}
            setstep1output={setstep1output}
            wizardRecordId={wizardRecordId}
            wizardRecordInstanceId={wizardRecordInstanceId}
            setWizardRecordInstanceId={setWizardRecordInstanceId}
            wizardPageId={wizardPageId}
            setWizardPageId={setWizardPageId}
            actionButtonId={actionButtonId}
            setActionButtonId={setActionButtonId}
            NextButtonId={NextButtonId}
            setNextButtonId={setNextButtonId}
          />
        );
      case 3:
        return (
          <Step2
            step1output={step1output}
            nextStep={nextStep}
            handleChange={handleChange}
            formData={formData}
            setstep2output={setstep2output}
            wizardRecordId={wizardRecordId}
            wizardRecordInstanceId={wizardRecordInstanceId}
            setWizardRecordInstanceId={setWizardRecordInstanceId}
            wizardPageId={wizardPageId}
            setWizardPageId={setWizardPageId}
            actionButtonId={actionButtonId}
            setActionButtonId={setActionButtonId}
            NextButtonId={NextButtonId}
            setNextButtonId={setNextButtonId}
          />
        );
      case 4:
        return (
          <Step3
            step2output={step2output}
            handleChange={handleChange}
            formData={formData}
            nextStep={nextStep}
            setstep3output={setstep3output}
            wizardRecordId={wizardRecordId}
            wizardRecordInstanceId={wizardRecordInstanceId}
            setWizardRecordInstanceId={setWizardRecordInstanceId}
            wizardPageId={wizardPageId}
            setWizardPageId={setWizardPageId}
            actionButtonId={actionButtonId}
            setActionButtonId={setActionButtonId}
            NextButtonId={NextButtonId}
            setNextButtonId={setNextButtonId}
          />
        );
      case 5:
        return (
          <Step4
            step3output={step3output}
            step2output={step2output}
            nextStep={nextStep}
            handleChange={handleChange}
            formData={formData}
            setstep4output={setstep4output}
            wizardRecordId={wizardRecordId}
            wizardRecordInstanceId={wizardRecordInstanceId}
            setWizardRecordInstanceId={setWizardRecordInstanceId}
            wizardPageId={wizardPageId}
            setWizardPageId={setWizardPageId}
            actionButtonId={actionButtonId}
            setActionButtonId={setActionButtonId}
            NextButtonId={NextButtonId}
            setNextButtonId={setNextButtonId}
          />
        );
      case 6:
        return (
          <Step5
            step2output={step2output}
            nextStep={nextStep}
            handleChange={handleChange}
            formData={formData}
            setstep5output={setstep5output}
            wizardRecordId={wizardRecordId}
            wizardRecordInstanceId={wizardRecordInstanceId}
            setWizardRecordInstanceId={setWizardRecordInstanceId}
            wizardPageId={wizardPageId}
            setWizardPageId={setWizardPageId}
            actionButtonId={actionButtonId}
            setActionButtonId={setActionButtonId}
            NextButtonId={NextButtonId}
            setNextButtonId={setNextButtonId}
          />
        );
      case 7:
        return (
          <Step6
            step1output={step1output}
            step5output={step5output}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            formData={formData}
            setstep6output={setstep6output}
            wizardRecordId={wizardRecordId}
            wizardRecordInstanceId={wizardRecordInstanceId}
            setWizardRecordInstanceId={setWizardRecordInstanceId}
            wizardPageId={wizardPageId}
            setWizardPageId={setWizardPageId}
            actionButtonId={actionButtonId}
            setActionButtonId={setActionButtonId}
            // NextButtonId={NextButtonId}
            // setNextButtonId={setNextButtonId}
          />
        );
      default:
        return null;
    }
  };

  return (
    <WizardUi>
      <div className="wizard">
        <div className="Stepper">
          <Stepper activeStep={step - 1} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={index}>
                <div
                  style={{
                    background: "linear-gradient(to left,  #8BBEF1 , #D0D8E0 )",
                    borderTopLeftRadius: "50px",
                    borderBottomLeftRadius: "50px",
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <StepLabel className="StepLabel">{label}</StepLabel>
                </div>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className="main-content">{renderStep()}</div>
      </div>
    </WizardUi>
  );
};

export default Wizard;

const WizardUi = styled.div`
  .Stepper {
    padding-top: 50px;
    padding-left: 50px;
    border-right: 1px solid #d0d8e0;
    background: linear-gradient(to left, #dee4ea, white);
    color: white;
  }
  .wizard {
    padding: 10px;
    border: 1px solid #77aaff;
    box-shadow: -1px 1px grey, -2px 2px grey, -3px 3px grey, -4px 4px grey,
      -5px 5px grey;
    display: flex;
    height: 100vh;
    justify-content: center;
    border: 0.1px solid;
    border-radius: 30px;
    margin-top: 50px;
  }

  .main-content {
    flex: 1;
    background-color: white;
    box-sizing: border-box;
  }

  .StepLabel {
    font-size: 1.2rem; // Adjust the font size as needed
    color: white; // Set the color to white
  }

  .MuiStepIcon-completed,
  .MuiStepIcon-active {
    color: #1434a4 !important; // Set the color for the completed and active tick marks
  }
`;
