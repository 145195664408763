import React, { useState } from "react";
import styled from "styled-components";
import {
  Button,
  TextField,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import {
  contextObjforapicallNextBtnWizard,
  contextObjforNextWizardReconcile,
  objectforOPSReconciledisb,
  objectforOPSsetdisb,
} from "../../Common/Constants";
import { controllerAPI, opscontroller } from "../../API/FP/Actions";
import { useAuth } from "../../../context/auth";
import { useNavigate } from "react-router-dom";

const StepDetails = ({
  selectedRecordDetails,
  nextStep,
  handleChange,
  formData,
  setstep1output,
  setshowWiz,
  wizardRecordId,
  wizardRecordInstanceId,
  setWizardRecordInstanceId,
  wizardPageId,
  setWizardPageId,
  NextButtonId,
  setNextButtonId,
}) => {
  const navigate = useNavigate();
  const [btnenable, setbtnenable] = useState(true);
  const [auth, setAuth] = useAuth();
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [showtable, setshowtable] = useState(false);
  const [data, setTableData] = useState([]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };
  const nextStepWizardApi = async () => {
    setLoading(true);
    try {
      setLoading(true);
      const constructObj = contextObjforNextWizardReconcile(
        authContextObj,
        wizardRecordId,
        wizardRecordInstanceId,
        wizardPageId,
        NextButtonId
      );
      // Updated API call with pagination parameters
      const ATTradesresponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        `reconcile-disbursement-wizard`
      );

      if (ATTradesresponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        navigate("/creditbazaar/");
      }

      if (ATTradesresponse.res.status === 200) {
        console.log("sucess");
        setWizardPageId(wizardPageId + 1);
        setNextButtonId(NextButtonId + 1);
        setWizardRecordInstanceId(
          ATTradesresponse?.res?.data?.data?.reconcileDisbursementWizardStepData
            ?.id
        );
        console.log(
          "To test the wizard instance id travel correctly",
          ATTradesresponse?.res?.data?.data?.reconcileDisbursementWizardStepData
            ?.id
        );
        nextStep();
      }
    } catch (err) {
      console.log("Error fetching data:", err);
    } finally {
      setLoading(false);
    }
  };
  console.log("selectedRecordDetails is ", selectedRecordDetails);
  return (
    <StepContainer>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="step-content">
          <h2>STEP 1 :Disbursement Details </h2>
          <h4>
            Check the details below and click on Next to perform reconcile
            disbursment.
          </h4>
          <h4>
            {" "}
            Reconcile Disbursement of Id:
            {selectedRecordDetails?.id}
          </h4>

          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "100px" }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {" "}
                  <h4>Customer Name: </h4>
                  <p>{selectedRecordDetails?.customerName}</p>
                  <h4>Remitter Name:</h4>
                  <p>{selectedRecordDetails?.remitterName}</p>
                  <h4>IFSC Code:</h4>
                  <p>{selectedRecordDetails?.ifscCode}</p>
                </div>
                <div
                  className="Second"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <h4>Remitter Account Number:</h4>
                  <p>{selectedRecordDetails?.remitterAccountNumber}</p>
                  <h4>Product Code:</h4>
                  <p>{selectedRecordDetails?.productCode}</p>
                  <h4>UTR No:</h4>
                  <p>{selectedRecordDetails?.utrNo}</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "400px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></div>
            </>
          )}

          <Snackbar
            open={openSnackbar}
            //autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={alertSeverity}
              sx={{ width: "100%" }}
            >
              {apiResultMessage}
            </Alert>
          </Snackbar>
        </div>
        <button
          style={{
            margin: "10px",
            color: "black",
            height: "45px",
            width: "130px",
            backgroundColor: "white",
            border: " 1px #404040 solid",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px 36px",
            borderRadius: "2px",
            cursor: "pointer",
            alignSelf: "flexStart",
          }}
          onClick={() => {
            setshowWiz(false);
          }}
          className="button"
          disabled={!btnenable}
        >
          ← Back
        </button>
        <button
          style={{
            borderRadius: "10px",
            backgroundColor: "#4A3AFF",
            color: "white",
            border: "none",
            cursor: "pointer",
            height: "fit-content",
            boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
            margin: "5px",
            opacity: btnenable ? "1" : "0.5",
            padding: "15px 30px",
            fontSize: "15px",
          }}
          onClick={nextStepWizardApi}
          className="button"
          disabled={!btnenable}
        >
          Next
        </button>
      </div>
    </StepContainer>
  );
};

export default StepDetails;

const StepContainer = styled.div`
  .step-content {
    max-width: 600px;
    margin: auto;
  }
  table {
    // width: 300%;
    margin: 10px;
    border-collapse: collapse;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
  }
  tr {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
  }
  th {
    background-color: #f8f9fa;
    font-weight: bold;
  }
  .button {
    padding: 10px 20px;
    margin: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }

  .button:hover {
    background-color: #0056b3;
  }
`;
