import styled from "styled-components";
import {
  contextObjforapicallActionWizard,
  contextObjforapicallCloseWizard,
  objectforOPSPrepareDisb,
} from "../../Common/Constants";
import React, { useState } from "react";
import {
  Button,
  TextField,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { controllerAPI, opscontroller } from "../../API/FP/Actions";
import { useAuth } from "../../../context/auth";
import { fontWeight } from "@mui/system";
import { useNavigate } from "react-router-dom";

const Step2 = ({
  dataFromAcceptedOffers,
  step1output,
  nextStep,
  handleChange,
  wizardId,
  formData,
  setstep2output,
  wizardPageId,
  setWizardPageId,
  nextButtonId,
  actionButtonId,
  setActionButtonId,
  wizardStepInstanceIdWizard,
  setWizardStepInstanceIdWizard,
}) => {
  const navigate = useNavigate();
  const [btnenable, setbtnenable] = useState(false);
  const [auth, setAuth] = useAuth();
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [showdata, setshowdata] = useState(false);
  const [closeId, setCloseId] = useState(1);
  const [val, setVal] = useState();
  const [wizardRecordInstanceIdStep1, setwizardRecordInstanceIdStep1] =
    useState(wizardStepInstanceIdWizard);
  const [disableAction, setDisableAction] = useState(false);
  const [data, setdata] = useState([]);
  console.log("wizardPageId from step 2 is ", wizardPageId);
  console.log("nextButtonId from step 2 is ", nextButtonId);
  console.log("actionButtonId from step 2 is ", actionButtonId);
  console.log("dataFromAcceptedOffers", dataFromAcceptedOffers?.id);
  console.log(
    "wizardStepInstanceIdWizard is this from srikar:",
    wizardStepInstanceIdWizard
  );
  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };
  const actionStepWizardApi = async () => {
    let acceptedOfferId = dataFromAcceptedOffers?.id;
    setLoading(true);
    try {
      setLoading(true);
      const constructObj = contextObjforapicallActionWizard(
        authContextObj,
        wizardId,
        wizardStepInstanceIdWizard,
        wizardPageId,
        actionButtonId,
        acceptedOfferId
      );
      // Updated API call with pagination parameters
      const ATTradesresponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        `disbursement-wizard`
      );

      if (ATTradesresponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        navigate("/creditbazaar/");
      }
      if (ATTradesresponse?.response?.status === 400) {
        setOpenSnackbar(true);
        setApiResultMessage(ATTradesresponse?.response?.data?.error);
        setAlertSeverity("error");
      }
      if (ATTradesresponse?.res?.status === 200) {
        console.log("sucess");
        setWizardPageId(wizardPageId + 1);
        setDisableAction(true);
        setWizardStepInstanceIdWizard(
          ATTradesresponse?.res?.data?.data?.setDisbursementWizardStepData?.id
        );
        setVal(
          ATTradesresponse?.res?.data?.data?.setDisbursementWizardStepData?.id
        );
        setwizardRecordInstanceIdStep1(
          ATTradesresponse?.res?.data?.data?.reconcileDisbursementWizardStepData
            ?.id
        );

        setshowdata(true);
        setOpenSnackbar(true);
        setApiResultMessage("Set Disbursment successful!!");
        setAlertSeverity("success");
      }
    } catch (err) {
      console.log("Error fetching data:", err);
      setOpenSnackbar(true);
      setApiResultMessage("Set Disbursment Failed !!");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const CloseStepWizardApi = async () => {
    setLoading(true);
    try {
      setLoading(true);
      const constructObj = contextObjforapicallCloseWizard(
        authContextObj,
        wizardId,
        val,
        closeId
      );
      // Updated API call with pagination parameters
      const ATTradesresponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        `disbursement-wizard`
      );

      if (ATTradesresponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        navigate("/creditbazaar/");
      }

      if (ATTradesresponse.res.status === 200) {
        console.log("sucess from the close Wizard button api call");
        window.location.reload();
      }
    } catch (err) {
      console.log("Error fetching data:", err);
    } finally {
      setLoading(false);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <StepContainer>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="step-content">
          <h2>STEP 2: Set Disbursment</h2>
          <p>
            Accepted Offer Id is:
            <span style={{ fontSize: "16px", fontWeight: "bold" }}>
              {dataFromAcceptedOffers?.id}
            </span>
          </p>
          <p>
            If you want to set disbursement click on proceed below :
            <span style={{ fontSize: "16px", fontWeight: "bold" }}>
              {step1output?.escrowTransactionDetails?.vaNumber}{" "}
            </span>
          </p>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "400px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  disabled={disableAction === true}
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "#4A3AFF",
                    color: "white",
                    border: "none",
                    cursor: "pointer",
                    height: "fit-content",
                    boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                    margin: "5px",
                    opacity: disableAction === true ? "0.5" : "1",
                  }}
                  onClick={() => {
                    actionStepWizardApi();
                  }}
                >
                  Proceed
                </Button>
              </div>
              <>
                {showdata === true ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "400px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <Button
                        disabled={btnenable}
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          height: "45px",
                          width: "130px",
                          backgroundColor: "white",
                          border: " 1px #404040 solid",
                          cursor: "pointer",
                          height: "fit-content",
                          boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                          margin: "5px",
                          opacity: !btnenable ? "1" : "0.5",
                        }}
                        onClick={() => {
                          CloseStepWizardApi();
                        }}
                      >
                        Close
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <p></p>
                  </>
                )}
              </>
            </>
          )}
          <Snackbar
            open={openSnackbar}
            //autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={alertSeverity}
              sx={{ width: "100%" }}
            >
              {apiResultMessage}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </StepContainer>
  );
};

export default Step2;

const StepContainer = styled.div`
  .step-content {
    max-width: 600px;
    margin: auto;
  }

  .button {
    padding: 10px 20px;
    margin: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }

  .button:hover {
    background-color: #0056b3;
  }
`;
