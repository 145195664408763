import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Wizard from "./SettlementWizard";
import styled from "styled-components";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../../../context/auth";
import { opscontroller } from "../../API/FP/Actions";
import Pagenotfound from "../../Common/pagenotfound";
import PtSt from "./ParticipantSettlement";

const IncomingRepayment = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [settlementdetails, setsettlementdetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useAuth();
  const [showWiz, setshowWiz] = useState(false);
  const [presentDetails, setpresentDetails] = useState();
  const [partcicipantDetails, setParticipantDetails] = useState();
  const [showParticipant, setShowParticipant] = useState(false);
  const [popupbtnopen, setpopupbtnopen] = useState(false);
  const [popupbtnopeneditaccess, setpopupbtnopeneditaccess] = useState(false);

  const data = [
    {
      ulid: "123456",
      batchAmt: "$1,000",
      batchAmtCcd: "001",
      creditDate: "2024-08-01",
      vaNumber: "VA123456",
      remitterName: "John Doe",
      remitterAccountNumber: "123456789",
      ifscCode: "ABCD01234",
    },
    // Add more rows as needed
  ];

  useEffect(() => {
    getIncomingset();
  }, [auth?.token]);

  const getIncomingset = async () => {
    setLoading(true);
    try {
      const response = await opscontroller(
        "post",
        auth?.token,
        null,
        "get-settlements"
      );
      if (response?.response?.status === 401) {
        localStorage.removeItem("auth");
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        navigate("/creditbazaar/");
      }
      if (response.res.status === 200) {
        setTableData(
          response?.res?.data?.data?.adminFetchSettlementsResponseList
        );
        console.log(
          "the settlement details are :",
          response?.res?.data?.data?.adminFetchSettlementsResponseList
        );
      }
    } catch (error) {
      console.error("Error fetching accepted offer details:", error);
    } finally {
      setLoading(false);
    }
  };
  console.log("partcicipantDetails are :", partcicipantDetails);
  console.log("this is the table data from settlement ", tableData);
  tableData?.map((row, index) => {
    console.log("the settlement details are :", row?.settlement);
  });

  console.log("toomany of me :", tableData?.settlementdetails);
  return (
    <Acceptedoff>
      <div>
        {" "}
        <div>
          <h2> Settlement Details</h2>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              {showParticipant === true ? (
                <>
                  <PtSt
                    partcicipantDetails={partcicipantDetails}
                    setShowParticipant={setShowParticipant}
                    selectedRecordDetails={presentDetails}
                  />
                </>
              ) : (
                <>
                  {tableData?.length > 0 ? (
                    <>
                      <table
                        style={{ width: "100%", borderCollapse: "collapse" }}
                      >
                        <thead>
                          <tr>
                            <th>ID </th>
                            <th>Settlement Ulid Id</th>
                            <th>Settlement Ref No</th>
                            <th>Currency</th>
                            <th>Settlement Status</th>
                            <th>Acceptedoffer</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData?.map((row, index) => (
                            <tr key={index}>
                              <td>{row?.settlement?.id}</td>
                              <td>{row?.settlement?.settlementUlidId}</td>
                              <td>{row?.settlement?.settlementRefNo}</td>
                              <td>{row?.settlement?.currency}</td>
                              <td>{row?.settlement?.settlementStatus}</td>
                              <td>
                                {row?.settlement?.acceptedoffer?.id || "-"}
                              </td>
                              <td>
                                <Button
                                  style={{
                                    borderRadius: "5px",
                                    backgroundColor: "#4A3AFF",
                                    color: "white",
                                    border: "none",
                                    cursor: "pointer",
                                    height: "fit-content",
                                    boxShadow:
                                      "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                                    margin: "5px",
                                  }}
                                  onClick={() => {
                                    // setshowWiz(true);
                                    setpopupbtnopeneditaccess(true);
                                    setpresentDetails(row);
                                    setParticipantDetails(
                                      row?.participantSettlementList
                                    );
                                    setShowParticipant(true);
                                  }}
                                >
                                  Proceed
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <Pagenotfound />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </Acceptedoff>
  );
};

export default IncomingRepayment;

const Acceptedoff = styled.div`
  table {
    // width: 300%;
    border-collapse: collapse;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
  }
  th {
    background-color: #f8f9fa;
    font-weight: bold;
  }
`;
