import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/auth";
import {
  controllerAPI,
  opscontroller,
  setdisbursmentOPS,
} from "../../API/FP/Actions";
import Pagenotfound from "../../Common/pagenotfound";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  contextObjforapicall,
  contextObjforapicallStartWizard,
  CreateRequestOffer,
  objectforOPSsetdisb,
} from "../../Common/Constants";
import styled from "styled-components";

//get-finance-requests
const RequestOfferWizaard = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };
  const [selectedFR, setSelectedFR] = useState("");
  const [financeReqList, setFinanceReqList] = useState([]);
  const [interestPercentage, setinterestPercentage] = useState();
  const [term, setterm] = useState();
  useEffect(() => {
    getRequestOfferData();
  }, [auth?.token]);

  const handleCreateRequestOffer = async (
    financeRequestId,
    interestPercentage,
    term
  ) => {
    if (
      !financeRequestId ||
      !interestPercentage ||
      !term ||
      interestPercentage <= 0 ||
      interestPercentage >= 100 ||
      term <= 0
    ) {
      setApiResultMessage(
        "All fields are required and must have valid values."
      );
      setAlertSeverity("error");
      setOpenSnackbar(true);
      return;
    }
    // disabled={term == null || interestPercentage == null}
    setLoading(true);
    try {
      const constructObj = CreateRequestOffer(
        financeRequestId,
        interestPercentage,
        term
      );
      const response = await opscontroller(
        "post",
        auth?.token,
        constructObj,
        "admin-process"
      );

      if (response?.response?.status === 401) {
        localStorage.removeItem("auth");
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        navigate("/creditbazaar/");
      }
      if (response.res.status === 200) {
        console.log(
          "the admin process is :",
          response?.res?.data?.data?.requestOffer?.id
        );
        setLoading(false);
        setOpenSnackbar(true);
        setApiResultMessage(
          `For Finance Request ID: ${financeRequestId} a Request Offer has been created successfully! with the ID:${response?.res?.data?.data?.requestOffer?.id}`
        );
        setAlertSeverity("success");
      }
    } catch (error) {
      console.error("Error fetching accepted offer details:", error);
      setLoading(false);
      setOpenSnackbar(true);
      setApiResultMessage("error creating request offer ");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };
  const getRequestOfferData = async () => {
    setLoading(true);
    try {
      const response = await opscontroller(
        "post",
        auth?.token,
        null,
        "get-finance-requests"
      );

      if (response?.response?.status === 401) {
        localStorage.removeItem("auth");
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        navigate("/creditbazaar/");
      }
      if (response.res.status === 200) {
        console.log("ids ", response?.res?.data?.data);
        setFinanceReqList(response.res.data.data);
      }
    } catch (error) {
      console.error("Error fetching accepted offer details:", error);
    } finally {
      setLoading(false);
    }
  };

  console.log("financeReqList", financeReqList);
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {" "}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
              fontFamily: "Arial, sans-serif",
              padding: "20px",
              maxWidth: "600px",
              margin: "auto",
              border: "1px solid #ddd",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#f9f9f9",
            }}
          >
            <h1 style={{ color: "#333" }}>Finance Request Form</h1>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                width: "100%",
              }}
            >
              <label
                style={{ fontSize: "16px", color: "#555", fontWeight: "500" }}
              >
                Select Finance Request ID:
              </label>
              <select
                style={{
                  width: "100%",
                  height: "45px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  padding: "0 10px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  fontWeight: "bold",
                }}
                onChange={(e) => setSelectedFR(e.target.value)}
              >
                <option value="">Select Finance Request</option>
                {financeReqList?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.id}
                  </option>
                ))}
              </select>
            </div>

            {selectedFR && (
              <form
                style={{ width: "100%" }}
                onSubmit={(e) => {
                  e.preventDefault(); // Correct usage
                  handleCreateRequestOffer(
                    selectedFR,
                    interestPercentage,
                    term
                  );
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    width: "100%",
                  }}
                >
                  <label
                    style={{
                      fontSize: "16px",
                      color: "#555",
                      fontWeight: "500",
                    }}
                  >
                    Interest Percentage:
                  </label>
                  <input
                    type="number"
                    placeholder="Enter Interest Percentage"
                    style={{
                      width: "100%",
                      height: "45px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      padding: "0 10px",
                      fontSize: "16px",
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }}
                    onChange={(e) => {
                      setinterestPercentage(e.target.value);
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    width: "100%",
                  }}
                >
                  <label
                    style={{
                      fontSize: "16px",
                      color: "#555",
                      fontWeight: "500",
                    }}
                  >
                    Term (Months):
                  </label>
                  <input
                    type="number"
                    placeholder="Enter Term in Months"
                    style={{
                      width: "100%",
                      height: "45px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      padding: "0 10px",
                      fontSize: "16px",
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }}
                    onChange={(e) => {
                      setterm(e.target.value);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    type="submit"
                    style={{
                      marginTop: "5px",
                      fontSize: "15px",
                      backgroundColor: "#297fff",
                      fontFamily: "Open Sans",
                      fontWeight: "400",
                      color: "white",
                      height: "50px",
                      width: "180px",
                      border: "0px",
                      borderRadius: "3px",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    Create Request Offer{" "}
                  </button>
                </div>
              </form>
            )}
            <Snackbar
              open={openSnackbar}
              //autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity={alertSeverity}
                sx={{ width: "100%" }}
              >
                {apiResultMessage}
              </Alert>
            </Snackbar>
          </div>
        </>
      )}
    </>
  );
};

export default RequestOfferWizaard;

const Acceptedoff = styled.div`
  table {
    // width: 300%;
    border-collapse: collapse;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
  }
  th {
    background-color: #f8f9fa;
    font-weight: bold;
  }
`;
