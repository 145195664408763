import "../App.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import Signin from "../components/auth/Signin";
import Signup from "../components/auth/Signup";
import UserProfile from "../components/auth/UserProfile";
import { styled } from "styled-components";
import { useAuth } from "../context/auth";
import ForgotPassword from "../components/auth/ForgotPassword";
import Reset from "../components/auth/Reset";
import Profile from "../components/Profile";
import FinanceRequestInformation from "../components/finance/FinanceRequestInformation";
import FinanceRequestIndividual from "./finance/FinanceRequestIndividual";
import FinanceProfileOverview from "./financeProfile/FinanceProfileOverview";
import TradeProfileIndividual from "./tradepartnerProfile/TradeProfileIndividual";
import SellerProfileIndividual from "./sellerProfile/SellerProfileIndividual";
import RequestLogs from "./finance/RequestLogs";
import RequestLogIndividual from "./finance/RequestLogIndividual";
import TradePartnerRequestInformation from "./TradePartner/TradePartnerRequestInformation";
import TradePartnerRequestIndividual from "./TradePartner/TradePartnerRequestIndividual";
import TradePartnerRequestLogs from "./TradePartner/TradePartnerRequestLogs";
import TradePartnerRequestLogIndividual from "./TradePartner/TradePartnerRequestLogIndividual";
import AnchorTraderRequestInformation from "./AnchorTrader/AnchorTraderRequestInformation";
import PlacedRequests from "./AnchorTrader/PlacedRequests";
import PlacedRequestIndividual from "./AnchorTrader/PlacedRequestIndividual";
import ProfileIndividual from "./AnchorTrader/Profile/ProfileIndividual";
import ComingSoon from "./API/Common/ComingSoon";
import TradePartnerSuppliers from "./TradePartner/TradePartnerSuppliers";
import TpProfileIndividual from "./TradePartner/Profile/TpProfileIndividual";
import FinanceRequest from "./AnchorTrader/FinanceRequest";
import ATTrades from "./AnchorTrader/ATTrades";
import IncomingDisbursment from "./WizardUi/DisbursmentWizard/IncomingDisbursment";
import IncomingRepayment from "./WizardUi/RepaymentWizard/Incomingrepayment";
import Incomingsettlement from "./WizardUi/SettlementWizard/Incomingsettlement";
import Wizard from "./WizardUi/DisbursmentWizard/Wizard";
import AcceptedOffers from "./WizardUi/AcceptedOfferWizard/AcceptedOffers";
import Page1 from "./WizardUi/TradeDocReader/page1";
import Page2 from "./WizardUi/TradeDocReader/page2";
function OpsHome() {
  const [auth, setAuth] = useAuth();

  return (
    <App1>
      <Routes>
        <Route path="/creditbazaar/" element={<FinanceRequestInformation />} />
        ////////////////////// Ops Routes//////////////////////////////
        <Route
          path="/creditbazaar/RepaymentWizard/IncomingRepayment"
          element={<IncomingRepayment />}
        />
        <Route
          path="/creditbazaar/AcceptedOffer"
          element={<AcceptedOffers />}
        />
        <Route
          path="/creditbazaar/SettlemetWizard/Incomingsettlement"
          element={<Incomingsettlement />}
        />
        <Route
          path="/creditbazaar/DisbursmentWizard/IncomingDisbursement"
          element={<IncomingDisbursment />}
        />
        <Route
          path="/creditbazaar/DisbursmentWizard/Wizard"
          element={<Wizard />}
        />
        <Route
          path="/TradeDocReaderpage1"
          // element={<Page1 setSelectedFiles={setSelectedFiles} />}
          element={<Page1 />}
        />
        <Route
          path="/TradeDocReaderpage2"
          // element={<Page2 selectedFiles={selectedFiles} />}
          element={<Page2 />}
        />
        ////////////////////////////// End ops Routes
        ///////////////////////////
        <Route path="/creditbazaar/workflow" element={<ComingSoon />} />
        <Route path="/creditbazaar/faq" element={<ComingSoon />} />
        <Route path="/creditbazaar/support" element={<ComingSoon />} />
      </Routes>
    </App1>
  );
}

export default OpsHome;

const App1 = styled.div`
  .topcomp {
    //  position:fixed;
    display: flex;
    height: 60px;
    margin-left: 50px;
    width: 300px;
    justify-content: center;
    margin-top: -80px;
  }
  .leftcontent {
    // position:fixed;
    margin-top: 10px;
    font-size: 25px;
  }
`;
