import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  CircularProgress,
  Divider,
  Link,
  Typography,
} from "@mui/material";
import CircleCustom from "../../Common/CircleCustom";
import {
  callAPI,
  getRepaymentData,
  getTransactionData,
} from "../../API/FP/Actions";
import { contextObj, dateFormat } from "../../Common/Constants";
import { NumericFormat } from "react-number-format";
import { useAuth } from "../../../context/auth";
import Pagenotfound from "../../Common/pagenotfound";

function RequestRepayment({
  creditScoreReportCheck,
  setCreditScoreReportCheck,
}) {
  const { state } = useLocation();
  let navigate = useNavigate();
  const location = useLocation();
  const requestData = location.state?.requestData;
  const token = location.state?.authToken;
  const [repaymentDetails, setRepaymentDetails] = useState([]);
  const [escrow, setEscrowDetails] = useState([]);
  const [fundsdetails, setfundsDetails] = useState([]);
  const [creditAccountDetails, setCreditAccountDetails] = useState([]);
  const [financeRequest, setfinanceRequest] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [utrNumbers, setUtrNumber] = useState("");
  const [auth, setAuth] = useAuth();
  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
    clientCode: 1501,
  };

  useEffect(() => {
    const fetchRepaymentData = async () => {
      setIsLoading(true);
      try {
        const constructObj = contextObj(authContextObj, state.id);
        const repaymentResponse = await callAPI(
          auth?.token,
          constructObj,
          "get-repayment-details"
        );
        if (repaymentResponse?.response?.status === 401) {
          setAuth({
            ...auth,
            user: null,
            token: "",
            userDisplayName: "",
            userDetails: null,
          });
          localStorage.removeItem("auth");
          navigate("/creditbazaar/");
        }
        if (repaymentResponse.res.status === 200) {
          const filteredData1 = repaymentResponse.res.data.data.repaymentList;
          const filteredData2 =
            repaymentResponse.res.data.data.escrowTransactionDetailsList;
          const filteredData3 =
            repaymentResponse.res.data.data.fundsTransferTransactionDetailsList;
          const filteredData4 = repaymentResponse.res.data.data.financeRequest;
          const filteredData5 =
            repaymentResponse.res.data.data.creditAccountDetailsList;
          setRepaymentDetails(filteredData1);
          setEscrowDetails(filteredData2);
          setfundsDetails(filteredData3);
          setfinanceRequest(filteredData4);
          setCreditAccountDetails(filteredData5);
        }
      } catch (err) {
        console.log("Error fetching data:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRepaymentData();
  }, [requestData, auth?.token]); // Added state to the dependency array

  if (isLoading) {
    return (
      <div>
        <CircularProgress
          style={{ position: "absolute", left: "50%", top: "50%" }}
        />
      </div>
    );
  }

  const combinedArray = repaymentDetails?.map((repaymentItem, index) => ({
    ...repaymentItem,
    escrowDetails: escrow[index],
    financeRequestt: financeRequest,
    fundsDetails: fundsdetails[index],
    creditAccountDetails: creditAccountDetails[index],
  }));
  console.log("The repayment combined array", combinedArray); // if (!repaymentDetails || repaymentDetails.length === 0) {
  //   return <div>No repayment data available</div>;
  // }

  return (
    <>
      <div>
        <RequestInformation className="body-text">
          <div className="main-content">
            {combinedArray.length == 0 && !isLoading ? (
              <Pagenotfound />
            ) : (
              <>
                <div className="record-details">
                  <table>
                    <tbody>
                      {combinedArray?.map((item, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <th>Finance request ID</th>
                            <td>{item.financeRequestt?.financeRequestRefNo}</td>
                          </tr>
                          <tr>
                            <th>Repayment Due Date</th>
                            <td>{item.repaymentDueDate}</td>
                          </tr>
                          <tr>
                            <th>Deposit Account Name</th>
                            <td>{item?.creditAccountDetails?.accName} </td>
                          </tr>
                          <tr>
                            <th>Deposit Account Number</th>
                            <td>{item?.creditAccountDetails?.accNumber} </td>
                          </tr>
                          <tr>
                            <th>IFSC code</th>
                            <td>{item?.creditAccountDetails?.ifscCode} </td>
                          </tr>
                          <tr>
                            <th>Total Repayment Amount</th>
                            <td>{item.totalRepaymentAmount}</td>
                          </tr>
                          <tr>
                            <th>Amount Repaid So far</th>
                            <td>{item.repaymentAmtReceived}</td>
                          </tr>
                          <tr>
                            <th>Amount to be RePaid</th>
                            <td>{item.amtToBeRepayed}</td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
                <br />
                <div className="record-details">
                  <table>
                    <tbody>
                      {combinedArray?.map((item, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <th>Repayment Date</th>
                            <th>Amount Repaid</th>
                            <th>Status</th>
                            <th>UTR</th>
                            <th>Reference Number</th>
                          </tr>
                          <tr>
                            <td>{item.repaymentDate}</td>
                            <td>{item.amountRepayed}</td>
                            <td>{item.repaymentStatus}</td>
                            <td> {item.escrowDetails?.utrNo}</td>
                            <td>{item.repaymentRefNo}</td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </RequestInformation>
      </div>
    </>
  );
}

export default RequestRepayment;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 80% !important;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    // width: 55%;
  }
  .main-content {
    margin-top: 30px;
  }
`;
