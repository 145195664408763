import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  CircularProgress,
  Divider,
  Link,
  Typography,
} from "@mui/material";
import CircleCustom from "../../Common/CircleCustom";
import {
  callAPI,
  controllerAPI,
  getRepaymentData,
  getTransactionData,
} from "../../API/FP/Actions";
import {
  contextObj,
  contextObjforapicall,
  dateFormat,
  requestStates,
} from "../../Common/Constants";
import { NumericFormat } from "react-number-format";
import { useAuth } from "../../../context/auth";
import Pagenotfound from "../../Common/pagenotfound";

function RequestRepayment({
  creditScoreReportCheck,
  setCreditScoreReportCheck,
  fid,
}) {
  const location = useLocation();
  const { state } = location; // Moved here
  const navigate = useNavigate();
  const requestData = state?.requestData;
  const token = state?.authToken;
  const [escrow, setEscrowDetails] = useState([]);
  const [fundsdetails, setfundsDetails] = useState([]);
  const [repayment, setRepaymentDetails] = useState([]);
  const [financeRequest, setfinanceRequest] = useState([]);
  const [escrowAccountDetails, setEscrowAccountDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [utrNumbers, setUtrNumber] = useState("");
  const [auth, setAuth] = useAuth();
  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };

  useEffect(() => {
    const fetchRepaymentData = async () => {
      setIsLoading(true);
      try {
        const constructObj = contextObjforapicall(authContextObj, state.fid);
        const repaymentResponse = await controllerAPI(
          "post",
          auth?.token,
          constructObj,
          "get-repayment-details"
        );
        if (repaymentResponse?.response?.status === 401) {
          setAuth({
            ...auth,
            user: null,
            token: "",
            userDisplayName: "",
            userDetails: null,
          });
          localStorage.removeItem("auth");
          navigate("/creditbazaar/");
        }
        if (repaymentResponse.res.status === 200) {
          const filteredData1 = repaymentResponse.res.data.data.repaymentList;
          const filteredData2 =
            repaymentResponse.res.data.data.escrowTransactionDetailsList;
          const filteredData3 =
            repaymentResponse.res.data.data.fundsTransferTransactionDetailsList;
          const filteredData4 = repaymentResponse.res.data.data.financeRequest;
          const filteredData5 =
            repaymentResponse.res.data.data.escrowAccountDetailsList;
          setRepaymentDetails(filteredData1);
          setEscrowDetails(filteredData2);
          setfundsDetails(filteredData3);
          setfinanceRequest(filteredData4);
          setEscrowAccountDetails(filteredData5);

          console.log("Fid received from the repayment :", fid);
        }
      } catch (err) {
        console.log("Error fetching data:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRepaymentData();
  }, [requestData, auth?.token, state]); // Added state to the dependency array

  if (isLoading) {
    return (
      <div>
        {" "}
        <CircularProgress
          style={{ position: "absolute", left: "50%", top: "50%" }}
        />
      </div>
    );
  }

  const combinedArray = repayment?.map((repaymentItem, index) => ({
    ...repaymentItem,
    escrowDetails: escrow[index],
    financeRequestt: financeRequest,
    fundsDetails: fundsdetails[index],
    escrowAccountDetails: escrowAccountDetails[index],
  }));
  // console.log("the combined array", combinedArray);
  return (
    <>
      <div>
        <RequestInformation className="body-text">
          <div className="main-content">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3
                style={{
                  marginBottom: "5px",
                  color: "#4A3AFF",
                  marginTop: "0px",
                }}
                className="section-heading"
              >
                {" "}
                Escrow details
              </h3>
            </div>
            <div className="record-details">
              {combinedArray.length == 0 && !isLoading ? (
                <Pagenotfound />
              ) : (
                <table>
                  <tbody>
                    {combinedArray?.map((item, index) =>
                      index === 0 ? (
                        <React.Fragment key={index}>
                          <tr>
                            <th>Finance request ID</th>
                            <td>{item.financeRequestt?.financeRequestRefNo}</td>
                          </tr>
                          <tr>
                            <th>Amount to be Repaid (INR)</th>
                            <td>{item.amtToBeRepayed}</td>
                          </tr>
                          <tr>
                            <th>Current status</th>
                            <td> {requestStates(item.repaymentStatus)}</td>
                          </tr>
                          <tr>
                            <th>Repayment due date</th>
                            <td>{item.repaymentDueDate}</td>
                          </tr>
                          {item.repaymentStatus !== "REQUEST_CREATED" && (
                            <React.Fragment>
                              <tr>
                                <th>Destination Account Number</th>
                                <td>
                                  {item?.escrowAccountDetails?.virtualAccNumber}
                                </td>
                              </tr>
                              <tr>
                                <th>Destination Account Name</th>
                                <td>{item?.escrowAccountDetails?.accName}</td>
                              </tr>
                              <tr>
                                <th>Destination account IFSC</th>
                                <td>{item?.escrowAccountDetails?.ifscCode}</td>
                              </tr>
                              <tr>
                                <th>UTR Number</th>
                                <td>{item?.escrowDetails?.utrNo}</td>
                              </tr>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ) : null
                    )}
                  </tbody>
                </table>
              )}
            </div>

            <br />
          </div>
        </RequestInformation>
      </div>
    </>
  );
}

export default RequestRepayment;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 80% !important;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 7px !important;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    width: 55%;
  }
  .main-content {
    margin-top: 30px;
  }
`;
