import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/auth";
import {
  controllerAPI,
  opscontroller,
  setdisbursmentOPS,
} from "../../API/FP/Actions";
import Pagenotfound from "../../Common/pagenotfound";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  contextObjforapicall,
  contextObjforapicallStartWizard,
  objectforOPSsetdisb,
} from "../../Common/Constants";
import styled from "styled-components";
import Wizard from "./Wizard";

const AcceptedOffers = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [popupbtnopen, setpopupbtnopen] = useState(false);
  const [AnchorTraderID, setAnchorTraderID] = useState();
  const [showWiz, setshowWiz] = useState(false);
  const [wizardId, setWizardId] = useState();
  const [wizardStepInstanceId, setWizardStepInstanceId] = useState();
  const [dataFromAcceptedOffers, setDataFromAcceptedOffers] = useState();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };
  useEffect(() => {
    getAcceptedOfferDetails();
  }, [auth?.token]);

  const startWizard = () => {
    startWizardApi();
  };

  const startWizardApi = async () => {
    setLoading(true);
    try {
      setLoading(true);
      const constructObj = contextObjforapicallStartWizard(authContextObj);
      // Updated API call with pagination parameters
      const ATTradesresponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        `disbursement-wizard`
      );

      if (ATTradesresponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        navigate("/creditbazaar/");
      }

      if (ATTradesresponse.res.status === 200) {
        console.log(
          "Startwizard response is :",
          ATTradesresponse?.res?.data?.data
        );
        setWizardId(
          ATTradesresponse?.res?.data?.data?.setDisbursementWizard?.id
        );
        setWizardStepInstanceId(
          ATTradesresponse?.res?.data?.data?.setDisbursementWizardStepData?.id
        ); // send for step1 next btn , for action button for step 1 get this from next button response
        setshowWiz(true);
      }
    } catch (err) {
      console.log("Error fetching data:", err);
    } finally {
      setLoading(false);
    }
  };

  const getAcceptedOfferDetails = async () => {
    setLoading(true);
    try {
      const response = await opscontroller(
        "post",
        auth?.token,
        null,
        "get-accepted-offers"
      );
      //
      if (response?.response?.status === 401) {
        localStorage.removeItem("auth");
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        navigate("/creditbazaar/");
      }
      if (response.res.status === 200) {
        setTableData(response.res.data.data);
      }
    } catch (error) {
      console.error("Error fetching accepted offer details:", error);
    } finally {
      setLoading(false);
    }
  };

  console.log("1111 response", tableData);

  return (
    <Acceptedoff>
      <div>
        <h2> Accepted Offers</h2>

        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </div>
        ) : tableData.length > 0 ? (
          <>
            {!showWiz && (
              <>
                <table>
                  <thead>
                    <tr>
                      <th
                        style={{
                          padding: "8px",
                          justifycontent: "center",
                          alignitems: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        ID
                      </th>
                      <th
                        style={{
                          padding: "8px",
                          justifycontent: "center",
                          alignitems: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        Accepted Offer Ulid Id
                      </th>
                      <th
                        style={{
                          padding: "8px",
                          justifycontent: "center",
                          alignitems: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        Accepted Offer Ref No
                      </th>

                      <th
                        style={{
                          padding: "8px",
                          justifycontent: "center",
                          alignitems: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        Interest Ptg
                      </th>
                      <th
                        style={{
                          padding: "8px",
                          justifycontent: "center",
                          alignitems: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        Term
                      </th>

                      <th
                        style={{
                          padding: "8px",
                          justifycontent: "center",
                          alignitems: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        Net Amount
                      </th>
                      <th
                        style={{
                          padding: "8px",
                          justifycontent: "center",
                          alignitems: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        Status
                      </th>
                      <th
                        style={{
                          padding: "8px",
                          justifycontent: "center",
                          alignitems: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        Offer Date
                      </th>
                      <th
                        style={{
                          padding: "8px",
                          justifycontent: "center",
                          alignitems: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        Offer Accepted Date
                      </th>
                      <th
                        style={{
                          padding: "8px",
                          justifycontent: "center",
                          alignitems: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        Anchortrader
                      </th>
                      <th
                        style={{
                          padding: "8px",
                          justifycontent: "center",
                          alignitems: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        Financepartner
                      </th>
                      <th
                        style={{
                          padding: "8px",
                          justifycontent: "center",
                          alignitems: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((item, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            padding: "8px",
                            justifycontent: "center",
                            alignitems: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {item.id}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            justifycontent: "center",
                            alignitems: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {item.acceptedOfferUlidId}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            justifycontent: "center",
                            alignitems: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {item.acceptedOfferRefNo}
                        </td>

                        <td
                          style={{
                            padding: "8px",
                            justifycontent: "center",
                            alignitems: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {item.interestPtg}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            justifycontent: "center",
                            alignitems: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {item.term}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            justifycontent: "center",
                            alignitems: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {item.netAmount}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            justifycontent: "center",
                            alignitems: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {item.status}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            justifycontent: "center",
                            alignitems: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {" "}
                          {item.offerDate}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            justifycontent: "center",
                            alignitems: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {item.offerAcceptedDate}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            justifycontent: "center",
                            alignItems: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {item.anchortrader?.id}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            justifycontent: "center",
                            alignitems: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {item.financepartner?.id}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            justifycontent: "center",
                            alignitems: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          <Button
                            style={{
                              borderRadius: "5px",
                              backgroundColor: "#4A3AFF",
                              color: "white",
                              border: "none",
                              cursor: "pointer",
                              height: "fit-content",
                              boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                            }}
                            onClick={() => (
                              startWizard(), setDataFromAcceptedOffers(item)
                            )}
                          >
                            Set Disbursement
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
            {showWiz && (
              <Wizard //selectedRecordDetails={presentDetails}
                dataFromAcceptedOffers={dataFromAcceptedOffers}
                setshowWiz={setshowWiz}
                wizardId={wizardId}
                wizardStepInstanceId={wizardStepInstanceId}
              />
            )}
          </>
        ) : (
          <Pagenotfound />
        )}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity={alertSeverity}
            sx={{ width: "100%" }}
          >
            {apiResultMessage}
          </Alert>
        </Snackbar>
      </div>
    </Acceptedoff>
  );
};

export default AcceptedOffers;

const Acceptedoff = styled.div`
  table {
    // width: 300%;
    border-collapse: collapse;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
  }
  th {
    background-color: #f8f9fa;
    font-weight: bold;
  }
`;
