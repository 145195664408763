import React, { useState } from "react";
import "../PoolingAccount/PoolingAccountWizard.css";

const PoolingAccountWizard = () => {
  const initialFormData = {
    selectedPoolingAccount: "",
    valDt: "",
    pstDt: "",
    pstTm: "",
    desc: "",
    amt: "",
    amtInd: "",
    bal: "",
    balInd: "",
    extndNarr: "",
    uuidNb: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const poolingAccounts = [
    { poolingAccountNumber: "123456", fp: "10.5" },
    { poolingAccountNumber: "234567", fp: "20.8" },
    { poolingAccountNumber: "345678", fp: "15.3" },
  ];

  const transactionTypeOptions = ["DBIT", "CRDT"];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Transaction Details Submitted: ", formData);
  };

  const handleReset = (e) => {
    e.preventDefault();
    setFormData(initialFormData);
  };

  return (
    <div className="parent">
      <div className="content">
        <p>Record Pooling Account Transaction</p>
        <p>Enter details for direct transactions to the pooling account.</p>
      </div>
      <form onSubmit={handleSubmit}>
        <p className="background">Pooling account information</p>
        <p className="labelText">
          Pooling account number:
          <br></br>
          <select
            className="styled-input"
            name="selectedPoolingAccount"
            value={formData.selectedPoolingAccount}
            onChange={handleChange}
          >
            <option value="" disabled>
              Select Pooling Account Number
            </option>
            {poolingAccounts.map((account) => (
              <option
                key={account.poolingAccountNumber}
                value={account.poolingAccountNumber}
              >
                {account.poolingAccountNumber} - FP: {account.fp}
              </option>
            ))}
          </select>
        </p>
        <p className="background">Transaction details</p>
        <div className="flex">
          <p className="labelText">
            Value Date
            <input
              className="styled-input"
              type="date"
              name="valDt"
              value={formData.valDt}
              onChange={handleChange}
            />
          </p>
          <p className="labelText">
            Posting Date
            <input
              className="styled-input"
              type="date"
              name="pstDt"
              value={formData.pstDt}
              onChange={handleChange}
            />
          </p>
          <p className="labelText">
            Posting Time
            <input
              className="styled-input"
              type="time"
              name="pstTm"
              value={formData.pstTm}
              onChange={handleChange}
            />
          </p>
        </div>
        <p className="background">Financial details</p>
        <div className="flex">
          <p className="labelText">
            Amount
            <input
              placeholder="Enter Amount"
              className="styled-input"
              type="number"
              step="0.01"
              name="amt"
              value={formData.amt}
              onChange={handleChange}
            />
          </p>
          <p className="labelText">
            Amount Indicator
            <select
              className="styled-input"
              name="amtInd"
              value={formData.amtInd}
              onChange={handleChange}
            >
              <option value="" disabled>
                Select Indicator
              </option>
              {transactionTypeOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </p>
          <p className="labelText">
            Balance
            <input
              placeholder="Enter Balance"
              className="styled-input"
              type="number"
              step="0.01"
              name="bal"
              value={formData.bal}
              onChange={handleChange}
            />
          </p>
          <p className="labelText">
            Balance Indicator
            <select
              className="styled-input"
              name="balInd"
              value={formData.balInd}
              onChange={handleChange}
            >
              <option value="" disabled>
                Select Indicator
              </option>
              {transactionTypeOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </p>
        </div>
        <p className="labelText">
          Description
          <br></br>
          <input
            placeholder="Enter Description"
            className="styled-textbox"
            type="text"
            name="desc"
            value={formData.desc}
            onChange={handleChange}
          />
        </p>
        <p className="background">Extended Narrative</p>
        <div className="flex">
          <p className="labelText">
            UUID Number
            <input
              className="styled-input"
              type="text"
              placeholder="Enter UUID Number"
              name="uuidNb"
              value={formData.uuidNb}
              onChange={handleChange}
            />
          </p>
          <p className="labelText">
            Description
            <input
              type="text"
              placeholder="Enter Description"
              className="styled-input-large"
              name="extndNarr"
              value={formData.extndNarr}
              onChange={handleChange}
            />
          </p>
        </div>
        <div className="btn-container">
          <button type="submit" className="submit-btn">
            Submit
          </button>
          <button className="reset-btn" onClick={handleReset}>
            Reset
          </button>
        </div>
      </form>
    </div>
  );
};

export default PoolingAccountWizard;
