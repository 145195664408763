import React from "react";

const Onboarding = ({ fill = "#0C0C0C" }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_565_1839)">
      <path
        d="M8.5 5C9.879 5 11 3.879 11 2.5C11 1.121 9.879 0 8.5 0C7.121 0 6 1.121 6 2.5C6 3.879 7.121 5 8.5 5ZM8.5 1C9.327 1 10 1.673 10 2.5C10 3.327 9.327 4 8.5 4C7.673 4 7 3.327 7 2.5C7 1.673 7.673 1 8.5 1ZM5 10H4V8.5C4 7.121 5.121 6 6.5 6H10.5C11.879 6 13 7.121 13 8.5V10H12V8.5C12 7.673 11.327 7 10.5 7H6.5C5.673 7 5 7.673 5 8.5V10ZM23.181 9.654C22.684 9.203 22.035 8.971 21.372 9.003C20.702 9.034 20.084 9.324 19.633 9.82L15.901 13.923C15.587 12.815 14.566 12 13.358 12H2.5C1.121 12 0 13.121 0 14.5V21.5C0 22.879 1.121 24 2.5 24H13.725L23.363 13.183C24.275 12.161 24.194 10.577 23.181 9.654ZM22.617 12.516L13.276 22.999H2.5C1.673 22.999 1 22.326 1 21.499V14.499C1 13.672 1.673 12.999 2.5 12.999H13.357C14.262 12.999 15 13.736 15 14.642C15 15.454 14.395 16.153 13.597 16.266L7.936 17.003L8.065 17.995L13.732 17.257C14.753 17.111 15.577 16.379 15.878 15.434L20.374 10.494C20.645 10.195 21.017 10.02 21.42 10.002C21.825 9.983 22.209 10.122 22.508 10.394C23.117 10.95 23.166 11.902 22.617 12.517V12.516Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_565_1839">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Onboarding;
