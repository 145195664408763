import React, { useCallback, useEffect, useState } from "react";
import { styled } from "styled-components";
import Footer from "../Common/Footer";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs, CircularProgress, Link, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Pagenotfound from "../Common/pagenotfound";
import {
  callAPI,
  getOpenRequests,
  getPFTCreditRecommendationScores,
} from "../API/FP/Actions";
import { getToken } from "../API/Common/Actions";
import FinanceRequestIndividual from "./FinanceRequestIndividual";
import { NumericFormat } from "react-number-format";
import { useAuth } from "../../context/auth";
import { contextObj } from "../Common/Constants";

function FinanceRequestInformation({ setActiveIndex }) {
  setActiveIndex(0);
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [auth, setAuth] = useAuth();

  const [financePartnerID, setFinancePartnerID] = useState(
    auth?.userDetails?.personaIds["FINANCE_PARTNER-3"]
  );
  const [openRequestsApiData, setOpenRequestsApiData] = useState([]);
  const [showSingleRequestDetails, setShowSingleRequestDetails] =
    useState(false);
  const [currentRequestDetails, setCurrentRequestDetails] = useState({});
  const [creditScoreReportCheck, setCreditScoreReportCheck] =
    React.useState(false);
  const [presentIndividualAssessment, setPresentIndividualAssessment] =
    useState([]);

  //pagenation constants
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const lastindex = currentPage * pageSize;
  const firstindex = lastindex - pageSize;
  const recordss = openRequestsApiData.slice(firstindex, lastindex);
  const numbers = [...Array(totalPages + 1).keys()].slice(1);

  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 3,
    clientCode: 1501,
  };

  const creditScoreColor = (score) => {
    if (score > 70) {
      return "#00692A";
    } else {
      return "#D20319";
    }
  };

  const fetchToken = useCallback(async () => {
    setIsLoading(true);
    try {
      const constructObj = contextObj(authContextObj, financePartnerID);
      const openOffersResponse = await callAPI(
        auth?.token,
        constructObj,
        `get-request-offers?page=${
          currentPage - 1
        }&size=${pageSize}&sort=id,desc`
      );

      if (openOffersResponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        navigate("/creditbazaar/");
      }

      if (openOffersResponse.res.status === 200) {
        setOpenRequestsApiData(openOffersResponse.res.data.data);
        setTotalPages(
          Math.ceil(openOffersResponse?.res?.data?.totalElements / pageSize)
        );
      }
    } catch (err) {
      console.log("Error", err);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage]);

  useEffect(() => {
    fetchToken();
  }, [currentPage]);

  function addDays(date) {
    let days = 0;
    let dayOfWeek = date.getUTCDay();
    if (dayOfWeek === 5) {
      days = 4;
    } else if (dayOfWeek === 6) {
      days = 3; //if it is raised on Saturday It should expire on Tuesday
    } else if (dayOfWeek === 4) {
      days = 4; //T+2 with T on thursday will be POEX on monday
    } else {
      days = 2;
    }
    date.setDate(date.getDate() + days);
    let month = date.toLocaleString("default", { month: "short" });
    let day = date.getUTCDate();
    let year = date.getUTCFullYear();
    return month + " " + day + ", " + year;
  }
  const getCreditScore = (gst) => {
    let t = presentIndividualAssessment.filter(
      (item) => item.tradePartnerGST === gst
    );
    return t[0]?.creditScore;
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const ChangePage = (pageNumber) => () => setCurrentPage(pageNumber);

  return (
    <>
      {isLoading && (
        <CircularProgress
          style={{ position: "absolute", left: "50%", top: "50%" }}
        />
      )}
      <Finance1>
        <div>
          <div
            role="presentation"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              color: "#4A3AFF",
              marginTop: "30px",
            }}
          >
            {!creditScoreReportCheck && (
              <Breadcrumbs aria-label="breadcrumb">
                {/* <Link
                  underline="hover"
                  color="rgba(74, 58, 255, 0.5)"
                  // href="/financesdashboard"
                  onClick={() => {
                    navigate("/creditbazaar/financesdashboard");
                  }}
                  className="custom-link clickable"
                >
                  Dashboard
                </Link> */}
                {!showSingleRequestDetails ? (
                  <Typography color="#4A3AFF"></Typography>
                ) : (
                  <Link
                    underline="hover"
                    color="rgba(74, 58, 255, 0.5)"
                    // href="/offer_information"
                    onClick={() => {
                      // navigate("/offer_information");
                      setShowSingleRequestDetails(false);
                    }}
                    className="custom-link clickable"
                  >
                    Open Requests
                  </Link>
                )}
                {showSingleRequestDetails && (
                  <Typography color="#4A3AFF">
                    {currentRequestDetails?.requestOffer?.reqOfferRefNo ?? ""}
                  </Typography>
                )}
              </Breadcrumbs>
            )}

            {!creditScoreReportCheck && (
              <div className="header">
                <h3 style={{ margin: "0px" }} className="section-heading">
                  {showSingleRequestDetails
                    ? "Place an offer"
                    : "Open Requests"}
                </h3>
                {showSingleRequestDetails && (
                  <Typography style={{ fontSize: "15px" }}>
                    To participate in this request, make an offer below.
                  </Typography>
                )}
              </div>
            )}
          </div>
        </div>
      </Finance1>
      {!showSingleRequestDetails ? (
        <Dash className="body-text">
          <div className="main-content">
            <div className="record-details">
              {openRequestsApiData.length == 0 && !isLoading ? (
                <>
                  <table>
                    <tbody>
                      <tr>
                        <th>Request ID </th>
                        <th>Credit Score</th>
                        <th>Trade Value (INR)</th>
                        <th>Anchor Trader</th>
                        <th>Trade Partner</th>
                        <th>Action By Date</th>
                        <th>Details</th>
                      </tr>
                    </tbody>
                  </table>
                  <Pagenotfound />
                </>
              ) : (
                <table>
                  <tbody>
                    <tr>
                      <th>Request ID</th>
                      <th>Credit Score</th>
                      <th>Trade Value (INR)</th>
                      <th>Anchor Trader</th>
                      <th>Trade Partner</th>
                      <th>Action By Date</th>
                      <th>Details</th>
                    </tr>
                    <>
                      {openRequestsApiData
                        // .map((item) => item.requestOffer)
                        .filter((item) => item.status !== "OFPD")
                        .sort((a, b) => b.requestOffer.id - a.requestOffer.id)
                        .map((val, ind) => {
                          return (
                            <tr key={ind}>
                              <td>{val?.requestOffer?.reqOfferRefNo}</td>
                              <td>
                                <span
                                  style={{
                                    fontWeight: "700",
                                  }}
                                >
                                  {val?.cbcreProcess?.cumilativeTradeScore}
                                  /100
                                </span>
                              </td>
                              <td>
                                <NumericFormat
                                  displayType="text"
                                  value={val?.requestOffer?.tradeValue}
                                  thousandsGroupStyle="lakh"
                                  thousandSeparator=","
                                />
                              </td>
                              <td>
                                {val?.requestOffer?.anchorTraderName?.length >
                                16 ? (
                                  <Tooltip
                                    title={`${val?.requestOffer?.anchorTraderName}`}
                                    placement="top"
                                  >
                                    <div
                                      className={
                                        `${val?.requestOffer?.anchorTraderName}`
                                          .length > 16 && `text-ellipse`
                                      }
                                    >
                                      {val?.requestOffer?.anchorTraderName}
                                    </div>
                                  </Tooltip>
                                ) : (
                                  <div
                                    className={
                                      `${val?.requestOffer?.anchorTraderName}`
                                        .length > 16 && `text-ellipse`
                                    }
                                  >
                                    {val?.requestOffer?.anchorTraderName}
                                  </div>
                                )}
                              </td>
                              <td>
                                {val?.requestOffer?.tradePartnerName?.length >
                                16 ? (
                                  <Tooltip
                                    title={`${val?.requestOffer?.tradePartnerName}`}
                                    placement="top"
                                  >
                                    <div
                                      className={
                                        `${val?.requestOffer?.tradePartnerName}`
                                          .length > 16 && `text-ellipse`
                                      }
                                    >
                                      {val?.requestOffer?.tradePartnerName}
                                    </div>
                                  </Tooltip>
                                ) : (
                                  <div
                                    className={
                                      `${val?.requestOffer?.tradePartnerName}`
                                        .length > 16 && `text-ellipse`
                                    }
                                  >
                                    {val?.requestOffer?.tradePartnerName}
                                  </div>
                                )}
                              </td>
                              <td>
                                {addDays(
                                  new Date(
                                    val?.requestOffer?.financeRequestDate
                                  )
                                )}
                              </td>
                              <td>
                                <button
                                  style={{
                                    borderRadius: "2px",
                                    backgroundColor: "#4A3AFF",
                                    color: "white",
                                    border: "none",
                                    padding: "5px 5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowSingleRequestDetails(true);
                                    setCurrentRequestDetails(val);
                                  }}
                                >
                                  View Request
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  </tbody>
                </table>
              )}
              {openRequestsApiData.length !== 0 && (
                <>
                  <nav
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      width: "100%",
                    }}
                  >
                    <ul
                      style={{
                        display: "flex",
                        listStyleType: "none",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <li style={{ marginRight: "8px" }}>
                        <p
                          style={{
                            padding: "8px 12px",
                            borderRadius: "4px",
                            cursor:
                              currentPage === 1 ? "not-allowed" : "pointer",
                            backgroundColor:
                              currentPage === 1 ? "#f0f0f0" : "white",
                          }}
                          onClick={handlePrevPage}
                        >
                          {"<"}
                        </p>
                      </li>
                      {/* Showing current page and total pages information */}
                      <li
                        style={{
                          marginRight: "8px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "14px",
                        }}
                      >
                        Showing {currentPage} of {totalPages}
                      </li>
                      <li style={{ marginRight: "8px" }}>
                        <p
                          style={{
                            padding: "8px 12px",
                            borderRadius: "4px",
                            cursor:
                              currentPage === totalPages
                                ? "not-allowed"
                                : "pointer",
                            backgroundColor:
                              currentPage === totalPages ? "#f0f0f0" : "white",
                          }}
                          onClick={handleNextPage}
                        >
                          {">"}
                        </p>
                      </li>
                    </ul>
                  </nav>
                </>
              )}
            </div>
          </div>
        </Dash>
      ) : (
        <FinanceRequestIndividual
          data={currentRequestDetails}
          actionDateFunction={addDays}
          creditScoreColor={creditScoreColor}
          creditScoreReportCheck={creditScoreReportCheck}
          setCreditScoreReportCheck={setCreditScoreReportCheck}
          getCreditScore={getCreditScore}
          authToken={authToken}
        />
      )}

      <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div>
    </>
  );
}

export default FinanceRequestInformation;

const Finance1 = styled.div`
  .custom-link:hover {
    color: #4a3aff;
  }
  .btn {
    color: white;
    background-color: #007bff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-left: 20px;
    font-size: 20px;
    // margin-top: 20vh;
  }
  .btn1 {
    color: white;
    background-color: orange;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 20px;
    border-radius: 5px;
    display: inline-block;
    margin-left: 20px;
  }
  .btn:hover {
    background-color: #0056b3;
  }

  .content {
    margin-left: 20px;
    margin-top: 20px;
    display: flex;
  }
  .offer-card {
    border: 2px solid black;
    // width: 170%;
    margin-bottom: 20px;
  }
  .lender-details {
    margin-left: 40px;
  }

  .header {
    font-size: 18px;
    margin-top: 15px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    // width: 300%;
    border-collapse: collapse;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
  }
  th {
    background-color: #f8f9fa;
    font-weight: bold;
  }
  .search-bar {
    display: flex;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .search-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #dddddd;
    border-radius: 4px 0 0 4px;
    font-size: 14px;
    align-items: center;
  }
  .search-button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 8px;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .search-button:hover {
    background-color: #0056b3;
  }
  .popup {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  .popup .popuptext {
    width: 700px;
    visibility: hidden;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    left: 50%;
    margin-left: -80px;
    overflow-y: auto;
    border-width: 3px;
    border-style: solid;
    border-color: #000;
  }
  .popup .popuptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  .popup .show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  }
  .popup .popuptext .btn {
    visibility: hidden;
  }
  .btn5 {
    color: white;
    background-color: #007bff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-left: 65vh;
    margin-bottom: 15px;
  }
  .btn5:hover {
    background-color: #0056b3;
  }
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Dash = styled.div`
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }

  .content {
    display: flex;
    margin-top: 20px;
  }

  .main-content {
    display: flex;
    padding: 20px 0px 10px 0px;
    flex-direction: column;
  }
  .table-container {
    margin-top: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    // border-left: 1px solid rgba(0, 0, 128, 0.2);
    // border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 5px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    background: rgba(74, 58, 255, 0.1);
  }
  .record-details {
  }
`;
