import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
// import Footer from "../pages/Footer";
import { useLocation, useNavigate } from "react-router-dom";
// import CreditRecommendationReport from "./CreditRecommendationReport";
import { callAPI, getOfferData } from "../../API/FP/Actions";

import { contextObj, dateFormat } from "../../Common/Constants";
import { NumericFormat } from "react-number-format";
import { useAuth } from "../../../context/auth";
import {
  Breadcrumbs,
  CircularProgress,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
function RequestOffer() {
  // const [creditScoreReportCheck, setCreditScoreReportCheck] =
  //   React.useState(false);
  const { state } = useLocation();
  let navigate = useNavigate();
  const location = useLocation();
  const offerDetails = location.state?.requestData;
  const token = location.state?.authToken;
  const [offerDetailss, setOfferDetails] = useState([offerDetails]);
  const [auth, setAuth] = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: "1",
  };

  console.log("hello to check :", offerDetails);

  useEffect(() => {
    const fetchOfferData = async () => {
      setIsLoading(true);

      try {
        const constructObj = contextObj(authContextObj, state.id);
        const offersResponse = await callAPI(
          auth?.token,
          constructObj,
          "get-accepted-offer"
        );

        if (offersResponse?.response?.status === 401) {
          setAuth({
            ...auth,
            user: null,
            token: "",
            userDisplayName: "",
            userDetails: null,
          });
          localStorage.removeItem("auth");
          navigate("/creditbazaar/");
        }

        if (offersResponse.res.status === 200) {
          const filteredData = offersResponse.res.data.data;
          setOfferDetails([filteredData]);
        }
      } catch (err) {
        console.log("Error fetching data:", err);
      } finally {
        setIsLoading(false);
      }
    };

    //fetchOfferData();
  }, []);

  return (
    <>
      {isLoading && (
        <CircularProgress
          style={{ position: "absolute", left: "50%", top: "50%" }}
        />
      )}
      <div>
        <RequestInformation className="body-text">
          <div className="main-content">
            <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
              Request Details
            </h3>
            <div className="record-details">
              <table>
                <tbody>
                  <tr>
                    <th>Date of Request</th>
                    <td>
                      {offerDetailss.length > 0
                        ? dateFormat(new Date(offerDetailss[0].offerDate), 0)
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <th>Total trade value (INR)</th>
                    <td>
                      {offerDetailss.length > 0 ? (
                        <NumericFormat
                          displayType="text"
                          value={offerDetailss[0].value}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>{" "}
                  </tr>
                  <tr>
                    <th>Tenure (Days)</th>
                    <td>
                      {offerDetailss.length > 0 ? offerDetailss[0].term : "N/A"}
                    </td>{" "}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="main-content">
            <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
              Final Offer Details
            </h3>
            <div className="record-details">
              <table>
                <tbody>
                  <tr>
                    <th> Financing Margin (%)</th>
                    <td>
                      {offerDetailss.length > 0
                        ? offerDetailss[0].marginPtg
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <th>Margin value (INR)</th>
                    <td>
                      {offerDetailss.length > 0 ? (
                        <NumericFormat
                          displayType="text"
                          value={offerDetailss[0].marginValue}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Amount after margin (INR)</th>
                    <td>
                      {offerDetailss.length > 0 ? (
                        <NumericFormat
                          displayType="text"
                          value={offerDetailss[0].amountAftMargin}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Annualized Interest rate (%)</th>
                    <td>
                      {offerDetailss.length > 0
                        ? offerDetailss[0].interestPtg
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <th>Interest value (INR)</th>
                    <td>
                      {offerDetailss.length > 0 ? (
                        <NumericFormat
                          displayType="text"
                          value={offerDetailss[0].interestValue}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Amount to be disbursed (INR)</th>
                    <td>
                      {offerDetailss.length > 0 ? (
                        <NumericFormat
                          displayType="text"
                          value={offerDetailss[0].netAmount}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="main-content">
            <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
              Repayment Details
            </h3>
            <div className="record-details">
              <table>
                <tbody>
                  <tr>
                    <th>Total Repayment amount (INR)</th>
                    <td>
                      {offerDetailss.length > 0 ? (
                        <NumericFormat
                          displayType="text"
                          value={offerDetailss[0].amountAftMargin}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </RequestInformation>
      </div>
      {/* {creditScoreReportCheck && (
        <CreditRecommendationReport
          creditScoreReportCheck={creditScoreReportCheck}
          setCreditScoreReportCheck={setCreditScoreReportCheck}
        />
      )} */}
      {/* <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div> */}
    </>
  );
}

export default RequestOffer;

const Finance1 = styled.div`
  .header {
    font-size: 18px;
    margin-top: 15px;
  }
  .custom-link:hover {
    color: #4a3aff;
  }
`;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 80% !important;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 5px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    width: 55%;
  }
  table input {
    width: 100%;
    padding: 10px 0px;
  }
  input,
  textarea {
    border: 1px solid rgba(0, 0, 128, 0.2);
    background-color: rgba(229, 234, 252, 0.3);
  }
`;
