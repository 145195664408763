import React from "react";

function CircleCustom({ score, text }) {
  const barColor = (value) => {
    // if (type === "accidental") {
    //   if (value >= 0 && value < 100) {
    //     return "#EF6060";
    //   } else if (value >= 100 && value < 150) {
    //     return "#EDA824";
    //   } else {
    //     return "#71AD42";
    //   }
    // }
    if (value >= 0 && value < 20) {
      return "#EF6060";
    } else if (value >= 20 && value < 35) {
      return "#EDA824";
    } else {
      return "#71AD42";
    }
  };
  const textColor = () => {
    if (tempValue >= 0 && tempValue <= 20) {
      return "#EF6060";
    } else if (tempValue >= 20 && tempValue < 35) {
      return "#EDA824";
    } else {
      return "#71AD42";
    }
  };
  let tempValue = score ? score : 90;
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // marginTop: "18%",
          paddingTop: "5%",
          position: "relative",
        }}
      >
        <div
          className="pie animate"
          style={{
            "--p": `${tempValue}`,
            "--c": `${barColor(tempValue)}`,
            "--b": "10px",
            zIndex: "10",
            // top: "80px",
            position: "relative",
            cursor: "pointer",
          }}
        >
          {" "}
          <div
            style={{
              position: "absolute",
              top: "-40px",
              right: "25px",
              display: "flex",
              flexDirection: "column",
            }}
          ></div>
        </div>
        <div
          className="pie animate"
          style={{
            "--p": `${100}`,
            "--c": "#D9D9D9",
            "--b": "10px",
            zIndex: "9",
            // top: "80px",
          }}
        ></div>

        <div
          style={{
            marginTop: "62px",
            position: "absolute",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* <p style={{ fontSize: "13px", margin: "0px 0px" }}>{text}</p> */}
            <p style={{ margin: "-5px 0px 0px 0px", fontWeight: "bold" }}>
              <span
                style={{
                  fontSize: "15px",
                  //   color: `${textColor()}`,
                }}
              >
                {/* {Math.ceil(totalScoreValue.toFixed(2))} */}
                {tempValue}
                {"/"}
              </span>
              <span style={{ fontSize: "15px" }}>100</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CircleCustom;
