import React, { useState } from "react";
import { styled } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumbs, Divider, Link, Typography } from "@mui/material";
import CircleCustom from "../../Common/CircleCustom";

function RequestRepayment({
  creditScoreReportCheck,
  setCreditScoreReportCheck,
}) {
  const { state } = useLocation();
  let navigate = useNavigate();

  let tableHeaderNames = [
    "Finance request ID",
    "Amount to be disbursed",
    "Creditors Account Name",
    "Virtual Account number",
    "IFSC code",
  ];
  let tableData = [
    {
      financeRequestId: "REQ-2023-123456",
      amountDisbursed: "8,80,000",
      creditorsAccountName: "Paisabuddy Escrow account",
      virtualAccountNumber: "PBD110000000001",
      ifscCode: "IDFB0080202",
    },
  ];

  return (
    <>
      <div>
        <RequestInformation>
          <div className="main-content">
            <div className="record-details">
              <table>
                <tbody>
                  <tr>
                    <th>Finance request ID</th>
                    <td>REQ-2023-123456</td>
                  </tr>
                  <tr>
                    <th>Amount to be disbursed</th>
                    <td>8,80,000</td>
                  </tr>
                  <tr>
                    <th>Creditors Account Name</th>
                    <td>Prime Textiles Escrow account</td>
                  </tr>
                  <tr>
                    <th>Virtual Account number</th>
                    <td>PBD110000000001</td>
                  </tr>
                  <tr>
                    <th>IFSC code</th>
                    <td>IDFB0080202</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h4 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
              {" "}
              Please make the payment as per the above details and confirm to
              us.
            </h4>
          </div>
          {/* <div style={{ marginTop: "15px", textAlign: "center" }}>
            <button
              style={{
                borderRadius: "2px",
                backgroundColor: "#219916",
                color: "white",
                border: "none",
                padding: "10px 20px",
                cursor: "pointer",
                borderRadius: "2px",
                height: "fit-content",
              }}
              onClick={() => navigate("/request_log")}
            >
              Confirm Re-Payment
            </button>
          </div> */}
        </RequestInformation>
      </div>
      {/* <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div> */}
    </>
  );
}

export default RequestRepayment;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    // width: 55%;
  }
  .main-content {
    margin-top: 30px;
  }
`;
