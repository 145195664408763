import React from "react";

const RequestForFinance = ({ fill = "#0C0C0C" }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1293_19)">
      <path
        d="M21.5 3.5H5.5C4.12 3.5 3 4.62 3 6V18.5H24V6C24 4.62 22.88 3.5 21.5 3.5ZM23 17.5H4V6C4 5.17 4.67 4.5 5.5 4.5H21.5C22.33 4.5 23 5.17 23 6V17.5ZM1 20.5H21V21.5H0V9C0 8.19 0.4 7.47 1 7.01V20.5ZM13.5 7.5C11.57 7.5 10 9.07 10 11C10 12.93 11.57 14.5 13.5 14.5C15.43 14.5 17 12.93 17 11C17 9.07 15.43 7.5 13.5 7.5ZM13.5 13.5C12.12 13.5 11 12.38 11 11C11 9.62 12.12 8.5 13.5 8.5C14.88 8.5 16 9.62 16 11C16 12.38 14.88 13.5 13.5 13.5ZM8 7.5C8 8.05 7.55 8.5 7 8.5C6.45 8.5 6 8.05 6 7.5C6 6.95 6.45 6.5 7 6.5C7.55 6.5 8 6.95 8 7.5ZM8 14.5C8 15.05 7.55 15.5 7 15.5C6.45 15.5 6 15.05 6 14.5C6 13.95 6.45 13.5 7 13.5C7.55 13.5 8 13.95 8 14.5ZM21 7.5C21 8.05 20.55 8.5 20 8.5C19.45 8.5 19 8.05 19 7.5C19 6.95 19.45 6.5 20 6.5C20.55 6.5 21 6.95 21 7.5ZM21 14.5C21 15.05 20.55 15.5 20 15.5C19.45 15.5 19 15.05 19 14.5C19 13.95 19.45 13.5 20 13.5C20.55 13.5 21 13.95 21 14.5Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_1293_19">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default RequestForFinance;
