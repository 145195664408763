import React from "react";
import { styled } from "styled-components";
// import Footer from "../pages/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from "@mui/material";
// import CreditRecommendationReport from "./CreditRecommendationReport";

function RequestSettlement() {
  const [creditScoreReportCheck, setCreditScoreReportCheck] =
    React.useState(false);
  const { state } = useLocation();
  let navigate = useNavigate();

  return (
    <>
      <div>
        <RequestInformation>
          <div className="main-content">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* <h3
                style={{
                  marginBottom: "5px",
                  color: "#4A3AFF",
                  marginTop: "0px",
                }}
              >
                {" "}
                Request Details
              </h3> */}
            </div>
            <div className="record-details">
              <table>
                <tbody>
                  {/* <tr>
                    <th>Settlement amount (INR)</th>
                    <td>8,80,000</td>
                  </tr>
                  <tr>
                    <th>Repayment date</th>
                    <td>Feb 19, 2024</td>
                  </tr>
                  <tr>
                    <th>Payment loyalty for Trade partner (INR)</th>
                    <td> 1,125</td>
                  </tr> */}
                  <tr>
                    <th>ProFinTech Charges (INR)</th>
                    <td>------</td>
                  </tr>
                  {/* <tr>
                    <th>Payment to Anchor Trader (INR)</th>
                    <td>4,285</td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
          <div className="main-content">
            {/* <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
              {" "}
              Final Offer Details
            </h3> */}
            {/* <div className="record-details">
              <table>
                <tbody>
                  <tr>
                    <th>Settlement amount (INR)</th>
                    <td>4,2585</td>
                  </tr>
                  <tr>
                    <th>Creditors account name</th>
                    <td>Paisabuddy Escrow account</td>
                  </tr>
                  <tr>
                    <th>Virtual account number</th>
                    <td>PBD110000002</td>
                  </tr>
                  <tr>
                    <th> IFSC code</th>
                    <td>IDFB0080202</td>
                  </tr>
                </tbody>
              </table>
            </div> */}
          </div>
          {/* <div style={{ marginTop: "15px", textAlign: "center" }}>
            <button
              style={{
                borderRadius: "2px",
                backgroundColor: "#219916",
                color: "white",
                border: "none",
                padding: "10px 20px",
                cursor: "pointer",
                borderRadius: "2px",
                height: "fit-content",
              }}
              onClick={() => navigate("/request_log")}
            >
              Confirm Settlement
            </button>
          </div> */}
        </RequestInformation>
      </div>
      {/* {creditScoreReportCheck && (
        <CreditRecommendationReport
          creditScoreReportCheck={creditScoreReportCheck}
          setCreditScoreReportCheck={setCreditScoreReportCheck}
        />
      )} */}
      {/* <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div> */}
    </>
  );
}

export default RequestSettlement;

const Finance1 = styled.div`
  .header {
    font-size: 18px;
    margin-top: 15px;
  }
  .custom-link:hover {
    color: #4a3aff;
  }
`;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 5px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    width: 55%;
  }
  table input {
    width: 100%;
    padding: 10px 0px;
  }
  input,
  textarea {
    border: 1px solid rgba(0, 0, 128, 0.2);
    background-color: rgba(229, 234, 252, 0.3);
  }
`;
