import React from "react";

function Component2({
  nextStep,
  handleCheckboxChange,
  files,
  selectedFilesName,
  isctive,
  prevStep,
  setIsLoading,
  component1Output,
}) {
  return (
    <div
      className="secondComponent"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "50px 50px 0px",
      }}
    >
      <h3
        style={{
          color: "black",
          fontSize: "18px",
          fontWeight: "600",
          lineHeight: "2",
          wordWrap: "break-word",
        }}
      >
        Review and select the files from the list below to proceed
      </h3>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          margin: "20px 0",
          fontSize: "18px",
          textAlign: "left",
          border: "2px solid #ddd",
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                padding: "8px",
                background: "rgba(0, 123, 255, 0.10)",
                color: "#615E83",
              }}
            >
              Filename
            </th>
            <th
              style={{
                padding: "8px",
                background: "rgba(0, 123, 255, 0.10)",
                color: "#615E83",
              }}
            >
              Filesize
            </th>
            <th
              style={{
                padding: "8px",
                background: "rgba(0, 123, 255, 0.10)",
                color: "#615E83",
              }}
            >
              Last Modified Date
            </th>
            <th
              style={{
                padding: "8px",
                background: "rgba(0, 123, 255, 0.10)",
                color: "#615E83",
              }}
            >
              Select
            </th>
          </tr>
        </thead>
        <tbody>
          {files?.map((file, index) => (
            <tr key={index}>
              <td style={{ padding: "10px" }}>{file.fileName}</td>
              <td>{file.fileSize}</td>
              <td>{file.lastModified}</td>
              <td>
                <input
                  type="checkbox"
                  checked={selectedFilesName.some(
                    (f) => f.fileName === file.fileName
                  )}
                  onChange={() => handleCheckboxChange(file)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        className="ButtonsPlacement"
        style={{
          marginTop: "60px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center ",
          gap: "20px",
          width: " 100%",
        }}
      >
        <button
          className="Backbtn"
          style={{
            color: "black",
            height: "45px",
            width: "120px",
            backgroundColor: "white",
            border: "1px solid",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px 36px",
            borderRadius: "3px",
            cursor: "pointer ",
            // alignSelf: "flexStart",
          }}
          onClick={() => prevStep()}
        >
          Back
        </button>
        <button
          type="button"
          disabled={!isctive}
          style={{
            opacity: isctive ? "1" : "0.5",
            fontSize: "18px",
            backgroundColor: "#297fff",
            fontFamily: "Open Sans",
            fontWeight: "400",
            color: "white",
            height: "45px",
            width: "120px",
            border: "0px",
            borderRadius: "3px",
            cursor: "pointer",
            // alignSelf: "flex-end",
          }}
          onClick={nextStep}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default Component2;
