import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  CircularProgress,
  Divider,
  Link,
  Typography,
} from "@mui/material";
import CircleCustom from "../../Common/CircleCustom";
import { callAPI, getDisbursementData } from "../../API/FP/Actions";
import { contextObj, dateFormat } from "../../Common/Constants";
import { NumericFormat } from "react-number-format";
import { useAuth } from "../../../context/auth";
import Pagenotfound from "../../Common/pagenotfound";

function RequestDisbursement({
  creditScoreReportCheck,
  setCreditScoreReportCheck,
}) {
  // const { state } = useLocation();ifsc
  let navigate = useNavigate();
  const location = useLocation();
  const requestData = location.state?.requestData;
  const token = location.state?.authToken;
  const { state } = useLocation();
  const [disbursementDetails, setDisbursementDetails] = useState([]);
  const [escrow, setEscrowDetails] = useState([]);
  const [escrowAccountDetails, setEscrowAccountDetails] = useState([]);
  const [financeRequest, setfinanceRequest] = useState([]);
  const [fundsdetails, setfundsDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [auth, setAuth] = useAuth();

  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
    clientCode: 1501,
  };

  useEffect(() => {
    const fetchDisbursementData = async () => {
      setIsLoading(true);
      try {
        const constructObj = contextObj(authContextObj, state.id);
        const disbursementResponse = await callAPI(
          auth?.token,
          constructObj,
          "get-disbursement-details"
        );

        if (disbursementResponse?.response?.status === 401) {
          setAuth({
            ...auth,
            user: null,
            token: "",
            userDisplayName: "",
            userDetails: null,
          });
          localStorage.removeItem("auth");
          navigate("/creditbazaar/");
        }

        if (disbursementResponse.res.status === 200) {
          const filteredData1 =
            disbursementResponse.res.data.data.disbursementList;
          const filteredData2 =
            disbursementResponse.res.data.data.escrowTransactionDetails;
          const filteredData3 =
            disbursementResponse.res.data.data.fundsTransferTransactionDetails;
          const filteredData4 =
            disbursementResponse.res.data.data.financeRequest;
          const filteredData5 =
            disbursementResponse.res.data.data.escrowAccountDetailsList;
          setEscrowAccountDetails(filteredData5);
          setEscrowDetails(filteredData2);
          setfundsDetails(filteredData3);
          setDisbursementDetails(filteredData1);
          setfinanceRequest(filteredData4);
          setIsLoading(false);
        }
      } catch (err) {
        console.log("Error fetching data:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDisbursementData();
  }, [requestData, auth?.token]);

  const combinedArray = disbursementDetails?.map((disbitem, index) => ({
    ...disbitem,
    escrowDetails: escrow[index],
    financeRequestt: financeRequest,
    fundsDetails: fundsdetails[index],
    escrowAccountDetails: escrowAccountDetails[index],
  }));

  console.log("the total combined array is :", combinedArray);
  // if (disbursementDetails.length === 0) {
  //   return <div>No data available</div>;
  // }

  // const disbursement = disbursementDetails[0];

  let tableHeaderNames = [
    "Finance request ID",
    "Amount to be disbursed",
    "Creditors Account Name",
    "Virtual Account number",
    "IFSC code",
    "Status",
  ];
  let tableData = [
    {
      financeRequestId: "REQ-2023-123456",
      amountDisbursed: "8,80,000",
      creditorsAccountName: "Paisabuddy Escrow account",
      virtualAccountNumber: "PBD110000000001",
      ifscCode: "IDFB0080202",
    },
  ];

  return (
    <>
      {isLoading && (
        <CircularProgress
          style={{ position: "absolute", left: "50%", top: "50%" }}
        />
      )}
      <div>
        <RequestInformation className="body-text">
          <div className="main-content">
            <div className="record-details">
              {combinedArray.length == 0 && !isLoading ? (
                <>
                  <Pagenotfound />
                </>
              ) : (
                <>
                  <table>
                    <tbody>
                      {combinedArray?.map((item, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <th>Finance request ID</th>
                            <td>{item.financeRequestt?.financeRequestRefNo}</td>
                          </tr>
                          <tr>
                            <th>Amount to be disbursed</th>
                            <td>{item?.amountToBeDisbursed}</td>
                          </tr>
                          <tr>
                            <th>Destination Account Name</th>
                            <td>{item?.escrowAccountDetails?.accName}</td>
                          </tr>
                          <tr>
                            <th>Destination Account number</th>
                            <td>
                              {item?.escrowAccountDetails?.virtualAccNumber}
                            </td>
                          </tr>
                          <tr>
                            <th>IFSC code</th>
                            <td>{item?.escrowAccountDetails?.ifscCode}</td>
                          </tr>
                          <tr>
                            <th>Action By Date</th>
                            <td>
                              {disbursementDetails.length > 0 &&
                              disbursementDetails[0].dbmtDueDate
                                ? dateFormat(
                                    new Date(
                                      disbursementDetails[0].dbmtDueDate
                                    ),
                                    0
                                  )
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <th>Disbursed Date </th>
                            <td>
                              {disbursementDetails.length > 0 &&
                              disbursementDetails[0].dbmtDateTime
                                ? dateFormat(
                                    new Date(
                                      disbursementDetails[0].dbmtDateTime
                                    ),
                                    0
                                  )
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <th>UTR</th>
                            <td>{item.escrowDetails?.utrNo}</td>
                          </tr>
                          <tr>
                            <th>Status</th>
                            <td>{item?.dbmtStatus}</td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                  <h4 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
                    {" "}
                    Please make the disbursement as per the above details. The
                    status will be updated here after 1-2 days once the
                    disbursement is done.
                  </h4>
                </>
              )}
            </div>
          </div>

          {/* <div className="main-content">
            <div style={{ display: "flex" }}>
              <div
                style={{
                  border: "1px solid rgba(0, 0, 128, 0.20)",
                  width: "40%",
                  borderRadius: "2px",
                  paddingBottom: "16px",
                }}
              >
                <p
                  style={{ color: "rgba(97, 94, 131, 1)", textAlign: "center" }}
                >
                  GST Compliance Score
                </p>
                <Divider variant="middle" />
                <CircleCustom score="90" text="CRE Score" />
              </div>
              <div
                style={{
                  border: "1px solid rgba(0, 0, 128, 0.20)",
                  width: "40%",
                  borderRadius: "2px",
                  paddingBottom: "16px",
                  marginLeft: "10%",
                }}
              >
                <p
                  style={{ color: "rgba(97, 94, 131, 1)", textAlign: "center" }}
                >
                  ERP peer score
                </p>
                <Divider variant="middle" />
                <CircleCustom score="70" text="ERP score" />
              </div>
            </div>
          </div> */}
          {/* <div style={{ marginTop: "15px", textAlign: "center" }}>
            <button
              style={{
                borderRadius: "2px",
                backgroundColor: "#219916",
                color: "white",
                border: "none",
                padding: "10px 20px",
                cursor: "pointer",
                borderRadius: "2px",
                height: "fit-content",
              }}
              onClick={() => navigate("/request_log")}
            >
              Confirm Disbursement
            </button>
          </div> */}
        </RequestInformation>
      </div>
      {/* <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div> */}
    </>
  );
}

export default RequestDisbursement;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 80% !important;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    // width: 55%;
  }
  .main-content {
    margin-top: 30px;
  }
`;
