import React, { useState } from "react";
import { controllerAPI } from "../../API/FP/Actions";
import { searchFilesControllerObj } from "../../Common/Constants";
import { useAuth } from "../../../context/auth";
import { Navigate } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";

function Component1({
  nextStep,
  setShowTable,
  selectedAnchorTrader,
  list,
  setIsLoading,
  setComponent1OutPut,
  setFiles,
}) {
  const [selectedAT, setSelectedAT] = useState();
  const [auth, setAuth] = useAuth();
  const [seletionValue, setSelectionS3pathvalue] = useState();
  const [S3PathByAT, setS3PathByAT] = useState(
    list.filter((item) => item?.AT == selectedAT).map((item) => item?.S3)
  );
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const [showSearchBtn, setShowSearchBtn] = useState(false);
  console.log("list is ", list);
  console.log(
    " channelPartnerName is :",
    list.map((item) => item?.AT)
  );
  console.log(" selected at is :", selectedAT);
  console.log(
    "component 1 for s3 paths :",
    list.filter((item) => item?.AT == selectedAT).map((item) => item?.S3)
  );

  var seByAT = list
    .filter((item) => item?.AT == selectedAT)
    .map((item) => item?.S3);

  const searchFilesList = async (path) => {
    setIsLoading(true);

    try {
      setIsLoading(true);
      const constructObj = searchFilesControllerObj(
        // "gs://testtdc-uat/dest_erp"
        path
      );
      // Updated API call with pagination parameters
      const ATTradesresponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        `list_files_in_gcs_path`
      );

      if (ATTradesresponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        Navigate("/creditbazaar/");
      }

      if (ATTradesresponse.res.status === 200) {
        console.log("Success", ATTradesresponse?.res?.data?.files);
        //nextStep();
        setIsLoading(false);
        setFiles(ATTradesresponse?.res?.data?.files);
      }
      if (ATTradesresponse?.response?.status === 400) {
      }
    } catch (error) {
      console.error("Error fetching  details:", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error in Fetching !");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="firstComponent"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "50px 50px 0px",
      }}
    >
      <h1>Select the S3 path</h1>
      <h3
        style={{
          color: "black",
          fontSize: "18px",
          fontWeight: "600",
          lineHeight: "2",
          wordWrap: "break-word",
        }}
      >
        Pick your Anchor Trader and choose your trade path.
      </h3>
      {/* Dropdown for Anchor Trader */}
      <div
        className="content1"
        style={{
          display: "flex",
          gap: "50px",
          alignItems: "center",
        }}
      >
        <p
          style={{
            color: "#333333",
            fontSize: "16",
            fontWeight: "400",
            wordWrap: "break-word",
          }}
        >
          {" "}
          Select Anchor Trader:
        </p>
        <select
          style={{
            width: "350px",
            height: "45px",
            fontStyle: "italic",
            opacity: "0.5",
            fontWeight: "bold",
          }}
          id="AT_selection"
          onChange={(e) => setSelectedAT(e.target.value)}
        >
          <option value="">Select anchor trades </option>
          {selectedAnchorTrader?.map((item, index) => {
            return <option value={item}>{item}</option>;
          })}
        </select>
      </div>

      {/* Dropdown for S3 Path */}
      <div
        className="content2"
        style={{
          display: "flex",
          gap: "95px",
          alignItems: "center",
        }}
      >
        <p
          style={{
            color: "#333333",
            fontSize: "16",
            fontWeight: "400",
            wordWrap: "break-word",
          }}
        >
          Select S3 Path:
        </p>
        {console.log("S3PathByAT  is :", seByAT)}
        <select
          style={{
            width: "350px",
            height: "45px",
            fontStyle: "italic",
            opacity: "0.5",
            fontWeight: "bold",
          }}
          id="s3path_selection"
          onChange={(e) => {
            console.log("Selected S3 Path:", e.target.value);
            setSelectionS3pathvalue(e.target.value);
            setShowSearchBtn(true);
          }}
          defaultValue=""
        >
          <option value="">Select associated s3 path</option>
          {seByAT.map((item) => {
            return <option value={item}>{item}</option>;
          })}
        </select>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          marginTop: "100px",
        }}
      >
        <button
          style={{
            color: "white",
            fontSize: "18",
            fontWeight: "500",
            backgroundColor: "#297FFF",
            border: "none",
            borderRadius: "4px",
            width: "130px",
            height: "50px",
            opacity: showSearchBtn ? "1" : "0.5",
          }}
          disabled={!showSearchBtn}
          onClick={() => {
            //setShowTable(true);
            nextStep();
            searchFilesList(seletionValue);
            setComponent1OutPut(seletionValue);
          }}
        >
          Search
        </button>
      </div>
      <Snackbar
        open={openSnackbar}
        //autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {apiResultMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Component1;
