import React, { useState } from "react";
import "../DirectParticipantTransaction/DirectParticipantTransactionWizard.css";

const DirectParticipantTransactionWizard = () => {
  const initialFormData = {
    sourceAccountName: "",
    sourceAccountNumber: "",
    sourceAccountIFSC: "",
    destinationAccountName: "",
    destinationAccountNumber: "",
    destinationAccountIFSC: "",
    transactionDateTime: "",
    transactionAmount: "",
    transactionDescription: "",
    transactionID: "",
    transactionReferenceNumber: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Transaction Details Submitted: ", formData);
  };

  const handleReset = (e) => {
    e.preventDefault();
    setFormData(initialFormData);
  };

  return (
    <div className="parent">
      <div className="content">
        <p>Direct Participant Transaction Details</p>
        <p>Enter details for direct transactions to the pooling account.</p>
      </div>
      <form onSubmit={handleSubmit}>
        <p className="background">Source account information</p>
        <div className="flex">
          <p className="labelText">
            Source Account Name
            <input
              placeholder="Enter Account Name"
              className="styled-input-medium"
              type="text"
              name="sourceAccountName"
              value={formData.sourceAccountName}
              onChange={handleChange}
            />
          </p>
          <p className="labelText">
            Source Account Number
            <input
              placeholder="Enter Account Number"
              type="text"
              className="styled-input-medium"
              name="sourceAccountNumber"
              value={formData.sourceAccountNumber}
              onChange={handleChange}
            />
          </p>
          <p className="labelText">
            Source Account IFSC Code
            <input
              placeholder="Enter IFSC Code"
              type="text"
              className="styled-input-medium"
              name="sourceAccountIFSC"
              value={formData.sourceAccountIFSC}
              onChange={handleChange}
            />
          </p>
        </div>
        <p className="background">Destination account information</p>
        <div className="flex">
          <p className="labelText">
            Destination Account Name
            <input
              placeholder="Enter Account Name"
              type="text"
              className="styled-input-medium"
              name="destinationAccountName"
              value={formData.destinationAccountName}
              onChange={handleChange}
            />
          </p>
          <p className="labelText">
            Destination Account Number
            <input
              placeholder="Enter Account Number"
              type="text"
              className="styled-input-medium"
              name="destinationAccountNumber"
              value={formData.destinationAccountNumber}
              onChange={handleChange}
            />
          </p>
          <p className="labelText">
            Destination Account IFSC Code
            <input
              placeholder="Enter IFSC Code"
              type="text"
              className="styled-input-medium"
              name="destinationAccountIFSC"
              value={formData.destinationAccountIFSC}
              onChange={handleChange}
            />
          </p>
        </div>
        <p className="background">Transaction information</p>
        <div className="flex">
          <p className="labelText">
            Transaction ID
            <input
              placeholder="Enter Transaction ID"
              type="text"
              className="styled-input-medium"
              name="transactionID"
              value={formData.transactionID}
              onChange={handleChange}
            />
          </p>
          <p className="labelText">
            Transaction Amount
            <input
              placeholder="Enter Transaction Amount"
              type="number"
              className="styled-input-medium"
              step="0.01"
              name="transactionAmount"
              value={formData.transactionAmount}
              onChange={handleChange}
            />
          </p>
          <p className="labelText">
            Transaction DateTime
            <input
              placeholder="Select Transaction Date and Time"
              type="datetime-local"
              className="styled-input-medium"
              name="transactionDateTime"
              value={formData.transactionDateTime}
              onChange={handleChange}
            />
          </p>
        </div>
        <div className="flex">
          <p className="labelText">
            Transaction Description
            <br></br>
            <input
              type="text"
              placeholder="Enter Description"
              className="styled-input-medium"
              name="transactionDescription"
              value={formData.transactionDescription}
              onChange={handleChange}
            />
          </p>
          <p className="labelText">
            Transaction Reference Number
            <input
              placeholder="Enter Transaction Reference Number"
              className="styled-input-large"
              type="text"
              name="transactionReferenceNumber"
              value={formData.transactionReferenceNumber}
              onChange={handleChange}
            />
          </p>
        </div>
        <div className="btn-container">
          <button type="submit" className="submit-btn">
            Submit
          </button>
          <button className="reset-btn" onClick={handleReset}>
            Reset
          </button>
        </div>
      </form>
    </div>
  );
};

export default DirectParticipantTransactionWizard;
