import React, { useCallback, useEffect, useState } from "react";
import { styled } from "styled-components";
import {
  Breadcrumbs,
  CircularProgress,
  Divider,
  Link,
  Typography,
} from "@mui/material";
import CircleCustom from "../../Common/CircleCustom";
import { callAPI } from "../../API/FP/Actions";
import { useAuth } from "../../../context/auth";
import { contextObj } from "../../Common/Constants";
import { useNavigate } from "react-router-dom";

function AnchorTraderReport({ authToken, financeRequestData }) {
  const [anchorTraderDetails, setAnchorTraderDetails] = useState([
    {
      anchorTraderName: "",
      location: "",
      anchorTraderGST: "",
      anchorTraderSector: "",
      anchorTraderPAN: "",
      kycCompleted: "",
      bankDetails: "",
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();

  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
    clientCode: 1501,
  };

  const fetchAnchorTraderDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const constructObj = contextObj(
        authContextObj,
        financeRequestData?.requestOffer?.reqOffUlidId
      );
      const anchorTraderResponse = await callAPI(
        auth?.token,
        constructObj,
        "get-anchor-trader-report"
      );

      if (anchorTraderResponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        navigate("/creditbazaar/");
      }

      if (anchorTraderResponse.res.status === 200) {
        setAnchorTraderDetails([anchorTraderResponse.res.data.data]);
      }
    } catch (err) {
      console.log("Error", err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAnchorTraderDetails();
  }, []);
  // console.log(
  //   "11111 anchorTraderDetails",
  //   anchorTraderDetails,
  //   typeof financeRequestData?.requestOffer?.anchorTraderGstComplianceScore
  // );

  const returnValue = (val) => {
    let v1 = parseInt(val);
    if (v1 > 0 && v1 <= 100) {
      return val;
    } else {
      return "0";
    }
  };
  return (
    <>
      {isLoading && (
        <CircularProgress
          style={{ position: "absolute", left: "50%", top: "50%" }}
        />
      )}
      <div>
        <RequestInformation style={{ display: "flex" }}>
          <div className="main-content" style={{ width: "60%" }}>
            <div className="record-details">
              <table>
                <tbody>
                  <tr>
                    <th>Anchor Trader name</th>
                    <td>{anchorTraderDetails[0]?.anchorTraderName}</td>
                  </tr>
                  <tr>
                    <th>Location</th>
                    <td>{anchorTraderDetails[0]?.location}</td>
                  </tr>
                  <tr>
                    <th>Anchor Trader GST</th>
                    <td>{anchorTraderDetails[0]?.anchorTraderGST}</td>
                  </tr>
                  <tr>
                    <th>Anchor Trader sector</th>
                    <td>{anchorTraderDetails[0]?.anchorTraderSector}</td>
                  </tr>
                  <tr>
                    <th>Anchor Trader PAN</th>
                    <td>{anchorTraderDetails[0]?.anchorTraderPAN}</td>
                  </tr>
                  <tr>
                    <th>KYC completed</th>
                    <td>
                      {anchorTraderDetails[0]?.kycCompleted == true
                        ? "True"
                        : "False"}
                    </td>
                  </tr>
                  <tr>
                    <th>Bank details</th>
                    <td>{anchorTraderDetails[0]?.bankDetails}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="main-content" style={{ width: "40%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "5%",
              }}
            >
              <div
                style={{
                  border: "1px solid rgba(0, 0, 128, 0.20)",
                  // width: "40%",
                  borderRadius: "2px",
                  // paddingBottom: "5px",
                  // marginBottom: "30px",
                  // marginLeft: "5%",
                }}
              >
                <p
                  style={{ color: "rgba(97, 94, 131, 1)", textAlign: "center" }}
                >
                  GST Compliance Score
                </p>
                <Divider variant="middle" />
                <CircleCustom
                  score={returnValue(
                    financeRequestData?.requestOffer
                      ?.anchorTraderGSTComplianceScore
                  )}
                  text="CRE Score"
                />
                <div className="bullet-points-list">
                  <ul
                    style={{ textAlign: "center", listStylePosition: "inside" }}
                  >
                    <li>{"< 70 - Ineligible Cut-Off "}</li>
                    <li>{"> 70 - Eligible Cut-Off "}</li>
                  </ul>
                </div>
              </div>
              {/* <div
                style={{
                  border: "1px solid rgba(0, 0, 128, 0.20)",
                  width: "40%",
                  borderRadius: "2px",
                  marginBottom: "30px",
                  marginLeft: "5%",
                }}
              >
                <p
                  style={{ color: "rgba(97, 94, 131, 1)", textAlign: "center" }}
                >
                  Business Performance Index
                </p>
                <Divider variant="middle" />
                <CircleCustom
                  score={returnValue(
                    financeRequestData?.requestOffer
                      ?.sellerTradePerformanceIndex
                  )}
                  text="ERP score"
                />
                <div className="bullet-points-list">
                  <ul
                    style={{ textAlign: "center", listStylePosition: "inside" }}
                  >
                    <li>{"> 80      - High"}</li>
                    <li>{"50 to 80 - Medium"}</li>
                    <li>{"< 50      - Low"}</li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </RequestInformation>
      </div>
      {/* <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div> */}
    </>
  );
}

export default AnchorTraderReport;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    // width: 100%;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    // width: 55%;
  }
  .main-content {
    margin-top: 30px;
  }
`;
