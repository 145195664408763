import { Tooltip } from "@mui/material";
import React, { useState } from "react";
import { NumericFormat } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { dateFormat } from "../../Common/Constants";

function TradeReport({ invoicesData, financeRequestData }) {
  const { state } = useLocation();
  let navigate = useNavigate();

  let tableHeaderNames = [
    "Invoice Date",
    "Invoice Number",
    "Anchor Trader",
    "Trade Partner ",
    "Trade Amount (INR)",
    "Term (Days)",
  ];

  // Pagination  variables
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const totalPages = Math.ceil(invoicesData.length / pageSize);
  const lastindex = currentPage * pageSize;
  const firstindex = lastindex - pageSize;
  const recordss = invoicesData.slice(firstindex, lastindex);
  const numbers = [...Array(totalPages + 1).keys()].slice(1);
  const [customtradeamnt, setcustomtradeamnt] = useState();
  let tableData = [
    {
      date: "Nov 18, 2023",
      invoiceNumber: "INV569829",
      anchorTrader: "Prime Textiles",
      tradePartnerName: "Apollo Hospitals",
      tradeAmount: "4,50,000",
      term: 80,
    },
    // {
    //   date: "Nov 19, 2023",
    //   invoiceNumber: "INV403441",
    //   tradePartnerName: "Renova Hospitals",
    //   tradeAmount: "3,00,000",
    //   term: 70,
    // },
    // {
    //   date: "Nov 19, 2023",
    //   invoiceNumber: "INV457608",
    //   tradePartnerName: "Renova Hospitals",
    //   tradeAmount: "3,50,000",
    //   term: 70,
    // },
  ];
  const customtradeAmount = (e) => {
    const Customtradeindividual = e.target.value;
    setcustomtradeamnt(Customtradeindividual);
  };
  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const ChangePage = (pageNumber) => () => setCurrentPage(pageNumber);
  console.log("999999999", recordss);

  return (
    <>
      <div>
        <RequestInformation>
          <div className="main-content">
            <div className="record-details">
              <table>
                <tbody>
                  <tr>
                    {tableHeaderNames?.map((x) => {
                      return <th>{x}</th>;
                    })}
                  </tr>

                  {recordss?.map((item) => {
                    return (
                      <tr>
                        <td>
                          {dateFormat(
                            new Date(item?.trademaster?.tradeDate),
                            0
                          )}
                        </td>
                        <td>{item?.trademaster?.tradeNo}</td>
                        <td>
                          {item?.trademaster?.sourceName?.length > 20 ? (
                            <Tooltip
                              title={`${item?.trademaster?.sourceName}`}
                              placement="top"
                            >
                              <div
                                className={
                                  `${item?.trademaster?.sourceName}`?.length >
                                    20 && `text-ellipse`
                                }
                                style={{ width: "120px" }}
                              >
                                {item?.trademaster?.sourceName}
                              </div>
                            </Tooltip>
                          ) : (
                            <div
                              className={
                                `${item?.trademaster?.sourceName}`?.length >
                                  20 && `text-ellipse`
                              }
                              style={{ width: "120px" }}
                            >
                              {item?.trademaster?.sourceName}
                            </div>
                          )}
                        </td>
                        <td>
                          {item?.trademaster?.targetName?.length > 20 ? (
                            <Tooltip
                              title={`${item?.trademaster?.targetName}`}
                              placement="top"
                            >
                              <div
                                className={
                                  `${item?.trademaster?.targetName}`?.length >
                                    20 && `text-ellipse`
                                }
                                style={{ width: "120px" }}
                              >
                                {item?.trademaster?.targetName}
                              </div>
                            </Tooltip>
                          ) : (
                            <div
                              className={
                                `${item?.trademaster?.targetName}`?.length >
                                  20 && `text-ellipse`
                              }
                              style={{ width: "120px" }}
                            >
                              {item?.trademaster?.targetName}
                            </div>
                          )}
                        </td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={item?.trademaster?.tradeAmount}
                            thousandsGroupStyle="lakh"
                            thousandSeparator=","
                          />
                        </td>
                        <td>{item?.trademaster?.tradeTerms}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {invoicesData.length !== 0 && (
                <>
                  {" "}
                  <nav
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      width: "100%",
                    }}
                  >
                    <ul
                      style={{
                        display: "flex",
                        listStyleType: "none",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <li style={{ marginRight: "8px" }}>
                        <p
                          style={{
                            padding: "8px 12px",
                            borderRadius: "4px",
                            cursor:
                              currentPage === 1 ? "not-allowed" : "pointer",
                            backgroundColor:
                              currentPage === 1 ? "#f0f0f0" : "white",
                          }}
                          onClick={handlePrevPage}
                        >
                          {"<"}
                        </p>
                      </li>
                      {/* Showing current page and total pages information */}
                      <li
                        style={{
                          marginRight: "8px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "14px",
                        }}
                      >
                        Showing {currentPage} of {totalPages}
                      </li>
                      <li style={{ marginRight: "8px" }}>
                        <p
                          style={{
                            padding: "8px 12px",
                            borderRadius: "4px",
                            cursor:
                              currentPage === totalPages
                                ? "not-allowed"
                                : "pointer",
                            backgroundColor:
                              currentPage === totalPages ? "#f0f0f0" : "white",
                          }}
                          onClick={handleNextPage}
                        >
                          {">"}
                        </p>
                      </li>
                    </ul>
                  </nav>
                </>
              )}
            </div>
          </div>
        </RequestInformation>
      </div>
      {/* <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div> */}
    </>
  );
}

export default TradeReport;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 90%;
    border-collapse: collapse;
    // border-left: 1px solid rgba(0, 0, 128, 0.2);
    // border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    // box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    // width: 55%;
    background: rgba(74, 58, 255, 0.1);
  }
  .main-content {
    margin-top: 30px;
  }
`;
