import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  CircularProgress,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { callAPI } from "../../API/FP/Actions";
import { contextObj, dateFormat, requestStates } from "../../Common/Constants";
import { NumericFormat } from "react-number-format";
import { useAuth } from "../../../context/auth";
import Pagenotfound from "../../Common/pagenotfound";

function RequestDetails({ creditScoreReportCheck, setCreditScoreReportCheck }) {
  const location = useLocation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const requestData = location.state?.requestData || {};
  const token = location.state?.authToken;
  const [tradeDetails, setTradeDetails] = useState([]);
  const [auth, setAuth] = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
    clientCode: 1501,
  };

  // Pagination  variables
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const lastindex = currentPage * pageSize;
  const firstindex = lastindex - pageSize;
  const recordss = tradeDetails.slice(firstindex, lastindex);
  const numbers = [...Array(totalPages + 1).keys()].slice(1);

  console.log("Request Data", tradeDetails);

  useEffect(() => {
    const fetchTradeData = async () => {
      setIsLoading(true);
      try {
        const constructObj = contextObj(authContextObj, state.id);
        const tradesResponse = await callAPI(
          auth?.token,
          constructObj,
          `get-at-trades?page=${currentPage - 1}&size=${pageSize}&sort=id,desc`
        );

        if (tradesResponse?.response?.status === 401) {
          setAuth({
            ...auth,
            user: null,
            token: "",
            userDisplayName: "",
            userDetails: null,
          });
          localStorage.removeItem("auth");
          navigate("/creditbazaar/");
        }

        if (tradesResponse.res.status === 200) {
          const filteredData = tradesResponse?.res?.data?.data;
          setTradeDetails(filteredData);
          setTotalPages(
            Math.ceil(tradesResponse?.res?.data?.totalElements / pageSize)
          );
        }
      } catch (err) {
        console.log("Error fetching data:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTradeData();
  }, [requestData, auth?.token, currentPage]);

  let tableHeaderNames = [
    "Invoice Date",
    "Invoice Number",
    "Trade Partner",
    "Anchor Trader",
    // "Trade Amount (INR)",
    "Term (Days)",
  ];

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const ChangePage = (pageNumber) => () => setCurrentPage(pageNumber);

  return (
    <>
      {isLoading && (
        <CircularProgress
          style={{ position: "absolute", left: "50%", top: "50%" }}
        />
      )}
      <div>
        <Dash className="body-text">
          <div className="main-content">
            <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
              {" "}
              Trade Details
            </h3>
            <div className="record-details">
              {tradeDetails?.length == 0 && !isLoading ? (
                <>
                  <table>
                    <tbody>
                      <tr>
                        {tableHeaderNames?.map((x) => {
                          return <th>{x}</th>;
                        })}
                      </tr>
                    </tbody>
                  </table>
                  <Pagenotfound />
                </>
              ) : (
                <table>
                  <tbody>
                    <tr>
                      {tableHeaderNames?.map((x) => {
                        return <th>{x}</th>;
                      })}
                    </tr>

                    {tradeDetails?.map((item) => {
                      return (
                        <tr>
                          <td>{dateFormat(new Date(item.tradeDate), 0)}</td>
                          <td>{item.tradeNo}</td>
                          <td>
                            {item?.targetName?.length > 18 ? (
                              <Tooltip
                                title={`${item?.targetName}`}
                                placement="top"
                              >
                                <div
                                  className={
                                    `${item?.targetName}`?.length > 18 &&
                                    `text-ellipse`
                                  }
                                  style={{ width: "120px" }}
                                >
                                  {item?.targetName}
                                </div>
                              </Tooltip>
                            ) : (
                              <div
                                className={
                                  `${item?.targetName}`?.length > 18 &&
                                  `text-ellipse`
                                }
                                style={{ width: "120px" }}
                              >
                                {item?.targetName}
                              </div>
                            )}
                          </td>
                          <td>
                            {item?.sourceName?.length > 18 ? (
                              <Tooltip
                                title={`${item?.sourceName}`}
                                placement="top"
                              >
                                <div
                                  className={
                                    `${item?.sourceName}`?.length > 18 &&
                                    `text-ellipse`
                                  }
                                  style={{ width: "120px" }}
                                >
                                  {item?.sourceName}
                                </div>
                              </Tooltip>
                            ) : (
                              <div
                                className={
                                  `${item?.sourceName}`?.length > 18 &&
                                  `text-ellipse`
                                }
                                style={{ width: "120px" }}
                              >
                                {item?.sourceName}
                              </div>
                            )}
                          </td>
                          <td>{item.tradeTerms}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}{" "}
              {tradeDetails.length !== 0 && (
                <>
                  {" "}
                  <nav
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      width: "100%",
                    }}
                  >
                    <ul
                      style={{
                        display: "flex",
                        listStyleType: "none",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <li style={{ marginRight: "8px" }}>
                        <p
                          style={{
                            padding: "8px 12px",
                            borderRadius: "4px",
                            cursor:
                              currentPage === 1 ? "not-allowed" : "pointer",
                            backgroundColor:
                              currentPage === 1 ? "#f0f0f0" : "white",
                          }}
                          onClick={handlePrevPage}
                        >
                          {"<"}
                        </p>
                      </li>
                      {/* Showing current page and total pages information */}
                      <li
                        style={{
                          marginRight: "8px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "14px",
                        }}
                      >
                        Showing {currentPage} of {totalPages}
                      </li>
                      <li style={{ marginRight: "8px" }}>
                        <p
                          style={{
                            padding: "8px 12px",
                            borderRadius: "4px",
                            cursor:
                              currentPage === totalPages
                                ? "not-allowed"
                                : "pointer",
                            backgroundColor:
                              currentPage === totalPages ? "#f0f0f0" : "white",
                          }}
                          onClick={handleNextPage}
                        >
                          {">"}
                        </p>
                      </li>
                    </ul>
                  </nav>
                </>
              )}
            </div>
          </div>
        </Dash>
      </div>
      {/* <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div> */}
    </>
  );
}

export default RequestDetails;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 40% !important;
    border-collapse: collapse;
    // border-left: 1px solid rgba(0, 0, 128, 0.2);
    // border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    // border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    // width: 55%;
  }
  .main-content {
    margin-top: 30px;
  }
`;

const Dash = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 90% !important;
    border-collapse: collapse;
    // border-left: 1px solid rgba(0, 0, 128, 0.2);
    // border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    // box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    // border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    // width: 55%;
    background: rgba(74, 58, 255, 0.1);
  }
  .main-content {
    margin-top: 30px;
  }
`;
