import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RepaymentWizard from "./RepaymentWizard";
import styled from "styled-components";
import { Button, CircularProgress } from "@mui/material";
import { useAuth } from "../../../context/auth";
import { controllerAPI, opscontroller } from "../../API/FP/Actions";
import Pagenotfound from "../../Common/pagenotfound";
import {
  contextObjforStartWizardReconcile,
  contextObjforStartWizardRepayment,
} from "../../Common/Constants";

const IncomingRepayment = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useAuth();
  const [showWiz, setshowWiz] = useState(false);
  const [presentDetails, setpresentDetails] = useState();
  const [WizardId, setWizardId] = useState();
  const [wizardInstanceId, setwizardInstanceId] = useState();
  const data = [
    {
      ulid: "123456",
      batchAmt: "$1,000",
      batchAmtCcd: "001",
      creditDate: "2024-08-01",
      vaNumber: "VA123456",
      remitterName: "John Doe",
      remitterAccountNumber: "123456789",
      ifscCode: "ABCD01234",
    },
    // Add more rows as needed
  ];

  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };

  const StartWizardApiCall = async () => {
    setLoading(true);
    try {
      const constructObj = contextObjforStartWizardRepayment(authContextObj);
      const response = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "reconcile-repayment-wizard"
      );
      if (response?.response?.status === 401) {
        localStorage.removeItem("auth");
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        navigate("/creditbazaar/");
      }
      if (response.res.status === 200) {
        setshowWiz(true);
        console.log(
          "the response of the start wizard api is :",
          response?.res?.data?.data
        );
        setWizardId(response?.res?.data?.data?.reconcileRepaymentWizard?.id);
        setwizardInstanceId(
          response?.res?.data?.data?.reconcileRepaymentWizardStepData?.id
        );
      }
    } catch (error) {
      console.error("Error fetching accepted offer details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getIncomingRep();
  }, [auth?.token]);

  const getIncomingRep = async () => {
    setLoading(true);
    try {
      const response = await opscontroller(
        "post",
        auth?.token,
        null,
        "incoming-repayments"
      );
      if (response?.response?.status === 401) {
        localStorage.removeItem("auth");
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        navigate("/creditbazaar/");
      }

      if (response.res.status === 200) {
        setTableData(response.res.data.data.escrowTransactionDetailsList);
      }
    } catch (error) {
      console.error("Error fetching accepted offer details:", error);
    } finally {
      setLoading(false);
    }
  };

  console.log("this is the table data", tableData);
  return (
    <Acceptedoff>
      <div>
        {" "}
        {showWiz === false && (
          <div>
            <h2> Repayment Details</h2>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100vh",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                {tableData?.length > 0 ? (
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                      <tr>
                        <th>Escrow Trnx Details Ulid Id</th>
                        <th>Batch Amt</th>
                        <th>Batch Amt Ccd</th>
                        <th>Credit Date</th>
                        <th>Va Number</th>
                        <th>Remitter Name</th>
                        <th>Remitter Account Number</th>
                        <th>Ifsc Code</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((row, index) => (
                        <tr key={index}>
                          <td>{row?.escrowTrnxDetailsUlidId}</td>
                          <td>{row?.batchAmt}</td>
                          <td>{row?.batchAmtCcd}</td>
                          <td>{row?.creditDate}</td>
                          <td>{row?.vaNumber}</td>
                          <td>{row?.remitterName}</td>
                          <td>{row?.remitterAccountNumber}</td>
                          <td>{row?.ifscCode}</td>
                          <td>
                            <Button
                              style={{
                                borderRadius: "5px",
                                backgroundColor: "#4A3AFF",
                                color: "white",
                                border: "none",
                                cursor: "pointer",
                                height: "fit-content",
                                boxShadow:
                                  "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                                margin: "5px",
                              }}
                              onClick={() => {
                                StartWizardApiCall();
                                setpresentDetails(row);
                              }}
                            >
                              Proceed
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <Pagenotfound />
                )}
              </>
            )}
          </div>
        )}
        {showWiz && (
          <RepaymentWizard
            selectedRecordDetails={presentDetails}
            setshowWiz={setshowWiz}
            WizardId={WizardId}
            setWizardId={setWizardId}
            wizardInstanceId={wizardInstanceId}
            setwizardInstanceId={setwizardInstanceId}
          />
        )}
      </div>
    </Acceptedoff>
  );
};

export default IncomingRepayment;

const Acceptedoff = styled.div`
  table {
    // width: 300%;
    border-collapse: collapse;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
  }
  th {
    background-color: #f8f9fa;
    font-weight: bold;
  }
`;
