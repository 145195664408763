import React, { useEffect, useState } from "react";
import {
  controllerAPI,
  controllerFileUploadForTradeDoc,
} from "../../API/FP/Actions";
import {
  fileUploadTradeDoc,
  searchFilesControllerObj,
} from "../../Common/Constants";
import { useAuth } from "../../../context/auth";
import { Navigate } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

function Componentpage2({
  nextStep,
  setShowTable,
  selectedAnchorTrader,
  list,
  setComponent1OutPut,
  setFiles,
}) {
  const [selectedAT, setSelectedAT] = useState();
  const [file, setFile] = useState(null);
  const [showSearchBtnn, setShowSearchBtnn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [auth, setAuth] = useAuth();
  const [seletionValue, setSelectionS3pathvalue] = useState();
  const [S3PathByAT, setS3PathByAT] = useState(
    list.filter((item) => item?.AT == selectedAT).map((item) => item?.S3)
  );
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true); // Simulate success or failure
  const [apiMessage, setApiMessage] = useState("");
  const [apiresponse, setApiResponse] = useState();
  const [timer, setTimer] = useState(30);

  const simulateApiResponse = (success) => {
    setIsSuccess(success);
    setApiMessage(
      success
        ? "File upload successful! Fetching files and navigating to the next step in 30 seconds..."
        : "Failed to upload the file. Please try again."
    );
    setPopupOpen(true);

    if (success) {
      // Start countdown for success scenario
      let countdown = 3;
      const interval = setInterval(() => {
        countdown -= 1;
        setTimer(countdown);
        if (countdown === 0) {
          clearInterval(interval);
          setPopupOpen(false);
          console.log("Navigating to next step...");
        }
      }, 1000);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const [showSearchBtn, setShowSearchBtn] = useState(false);
  console.log("list is ", list);
  console.log(
    " channelPartnerName is :",
    list.map((item) => item?.AT)
  );
  console.log(" selected at is :", selectedAT);
  console.log(
    "component 1 for s3 paths :",
    list.filter((item) => item?.AT == selectedAT).map((item) => item?.S3)
  );

  var seByAT = list
    .filter((item) => item?.AT == selectedAT)
    .map((item) => item?.S3);

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
    console.log("uploaded file is ", event.target.files[0]);
    setShowSearchBtn(!!uploadedFile);
  };

  const handleUpload = async (path) => {
    console.log("file uploading is ", file);
    setLoading(true);
    try {
      const appendBucketName = path.replace("gs://", "");

      const ATTradesresponse = await controllerFileUploadForTradeDoc(
        "post",
        auth?.token,
        file,
        `upload/${appendBucketName}`
      );

      if (ATTradesresponse?.res?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        Navigate("/creditbazaar/");
      }

      if (ATTradesresponse?.res?.status === 200) {
        setApiResultMessage(
          "File Uploaded Successfully searching for files.... !!"
        );
        setOpenSnackbar(true);
        setAlertSeverity("success");
        setTimeout(() => {
          searchFilesList(path);
        }, 3000);
        setFiles(ATTradesresponse?.res?.data?.files);
        simulateApiResponse(true);
      }
      if (ATTradesresponse?.res?.status === 400) {
        setOpenSnackbar(true);
        setApiResultMessage("Error in uploading! ");
        setAlertSeverity("error");
        simulateApiResponse(false);
      }
    } catch (error) {
      console.error("Error fetching  details:", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error in uploading! ");
      setAlertSeverity("error");
      simulateApiResponse(false);
    } finally {
      setLoading(false);
    }
  };

  const searchFilesList = async (path) => {
    setLoading(true);

    try {
      setLoading(true);
      const constructObj = searchFilesControllerObj(
        // "gs://testtdc-uat/dest_erp"
        path
      );
      // Updated API call with pagination parameters
      const ATTradesresponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        `list_files_in_gcs_path`
      );

      if (ATTradesresponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        Navigate("/creditbazaar/");
      }

      if (ATTradesresponse.res.status === 200) {
        console.log("Success", ATTradesresponse?.res?.data?.files);
        nextStep();
        setLoading(false);
        setFiles(ATTradesresponse?.res?.data?.files);
      }
      if (ATTradesresponse?.response?.status === 400) {
      }
    } catch (error) {
      console.error("Error fetching  details:", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error in fetching!");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <CircularProgress
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            zIndex: "1100",
          }}
        />
      ) : (
        <>
          <div
            className="firstComponent"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              margin: "50px 50px 0px",
            }}
          >
            <h1>Select and upload Trade Document.</h1>
            <h3
              style={{
                color: "black",
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "2",
                wordWrap: "break-word",
              }}
            >
              Pick your Anchor Trader and choose your trade path.
            </h3>
            {/* Dropdown for Anchor Trader */}
            <div
              className="content1"
              style={{
                display: "flex",
                gap: "50px",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  color: "#333333",
                  fontSize: "16",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                {" "}
                Select Anchor Trader:
              </p>
              <select
                style={{
                  width: "350px",
                  height: "45px",
                  fontStyle: "italic",
                  opacity: "0.5",
                  fontWeight: "bold",
                }}
                id="At Selection"
                onChange={(e) => {
                  setSelectedAT(e.target.value);
                }}
              >
                <option value="">Select anchor trades </option>
                {selectedAnchorTrader?.map((item, index) => {
                  return <option value={item}>{item}</option>;
                })}
              </select>
            </div>

            {/* Dropdown for S3 Path */}
            <div
              className="content2"
              style={{
                display: "flex",
                gap: "95px",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  color: "#333333",
                  fontSize: "16",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                Select S3 Path:
              </p>
              {console.log("S3PathByAT  is :", seByAT)}
              <select
                style={{
                  width: "350px",
                  height: "45px",
                  fontStyle: "italic",
                  opacity: "0.5",
                  fontWeight: "bold",
                }}
                id="s3 Selection"
                onChange={(e) => {
                  console.log("Selected S3 Path:", e.target.value);
                  setSelectionS3pathvalue(e.target.value);
                  setShowSearchBtnn(true);
                }}
                defaultValue=""
              >
                <option value="">Select associated cloud path</option>
                {seByAT.map((item) => {
                  return <option value={item}>{item}</option>;
                })}
              </select>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "100px",
              }}
            >
              {/* File Input */}
              <input
                type="file"
                onChange={handleFileChange}
                style={{
                  marginBottom: "20px",
                  opacity: showSearchBtnn ? "1" : "0.5",
                }}
                disabled={!showSearchBtnn}
                id="uploaded File"
              />

              <button
                style={{
                  color: "white",
                  fontSize: "18px",
                  fontWeight: "500",
                  backgroundColor: "#297FFF",
                  border: "none",
                  borderRadius: "4px",
                  width: "130px",
                  height: "50px",
                  opacity: showSearchBtn ? "1" : "0.5",
                }}
                disabled={!showSearchBtn}
                onClick={() => {
                  handleUpload(seletionValue);
                  setComponent1OutPut(seletionValue);
                }}
              >
                Upload
              </button>
            </div>
            <div>
              {/* Buttons to simulate API responses */}
              {/* <Button
                variant="contained"
                color="success"
                onClick={() => simulateApiResponse(true)}
              >
                Simulate Success
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => simulateApiResponse(false)}
              >
                Simulate Failure
              </Button> */}

              {/* Dialog for Popup */}
              <Dialog
                open={popupOpen}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    borderRadius: 15,
                    padding: "20px",
                    maxWidth: "400px",
                  },
                }}
              >
                <DialogContent>
                  <Stack
                    alignItems="center"
                    spacing={2}
                    justifyContent="center"
                    textAlign="center"
                  >
                    {/* Icon */}
                    {isSuccess ? (
                      <CheckCircleIcon sx={{ fontSize: 80, color: "green" }} />
                    ) : (
                      <ErrorIcon sx={{ fontSize: 80, color: "red" }} />
                    )}

                    {/* Message */}
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      color={isSuccess ? "green" : "red"}
                    >
                      {isSuccess ? "Success!" : "Failure"}
                    </Typography>
                    <Typography variant="body1">{apiMessage}</Typography>

                    {/* Countdown Timer */}
                    {isSuccess && (
                      <Typography variant="body2" color="text.secondary">
                        Redirecting in {timer} seconds...
                      </Typography>
                    )}

                    {/* Circular Progress */}
                    {isSuccess && <CircularProgress color="success" />}
                  </Stack>
                </DialogContent>

                {/* Close Button */}
                {!isSuccess && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClose}
                    fullWidth
                    sx={{ marginTop: 2 }}
                  >
                    Close
                  </Button>
                )}
              </Dialog>
            </div>
            <Snackbar
              open={openSnackbar}
              //autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity={alertSeverity}
                sx={{ width: "100%" }}
              >
                {apiResultMessage}
              </Alert>
            </Snackbar>
          </div>
        </>
      )}
    </>
  );
}

export default Componentpage2;
