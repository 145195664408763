import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../context/auth";
import Footer from "../Common/Footer";
import signUpLogo from "../images/newSignupLogo .png";
import { Alert, CircularProgress, Snackbar, Tooltip } from "@mui/material";
import { multigstController, signUp } from "../Common/Constants";
import { controllerAPI } from "../API/FP/Actions";
import signUpCOmpletion from "../images/SignupCompletion.png";
import iSymbol from "../images/Isymbol.png";
import Signup2 from "./Signup2";

function Signup3() {
  const [auth, setAuth] = useAuth();
  const [spinnerLoading, setIsspinnerLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [noneState, setNoneState] = useState(false);

  const [gstNumber, setGstNumber] = useState("");
  const [gstUsername, setGstUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [navigate, setNavigate] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent form from reloading the page

    // Log the collected values
    console.log("GST Number:", gstNumber);
    console.log("GST Username:", gstUsername);
    console.log("Email Address:", email);
    console.log("Phone Number:", phoneNumber);

    setNavigate(true);
  };

  return (
    <>
      {!navigate ? (
        <>
          {spinnerLoading ? (
            <>
              <CircularProgress
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                }}
              />
            </>
          ) : (
            <>
              <Sign1>
                <div className="mainContent">
                  <div className="firstSection">
                    {" "}
                    <div
                      style={{
                        width: "35px",
                        height: "100%",
                        background: "rgba(255, 255, 255, 0.20)",
                        margin: "0px 3.5px",
                      }}
                    />
                    <div
                      style={{
                        width: "35px",
                        height: "100%",
                        background: "rgba(255, 255, 255, 0.20)",
                        margin: "0px 3.5px",
                      }}
                    />
                    <div
                      style={{
                        width: "35px",
                        height: "100%",
                        background: "rgba(255, 255, 255, 0.20)",
                        marginLeft: "3.5px",
                      }}
                    />
                    <img
                      src={signUpCOmpletion}
                      style={{ width: "50%", height: "40%" }}
                    />
                  </div>
                  <div className="secondSection">
                    <form className="Form" onSubmit={handleSubmit}>
                      <p
                        style={{
                          width: "100%",
                          color: "black",
                          fontSize: "20px",
                          fontWeight: "700",
                          wordWrap: "break-word",
                        }}
                      >
                        Account Successfully Created and Verified!
                      </p>
                      <p
                        style={{
                          width: "100%",
                          color: "black",
                          fontSize: "17px",
                          fontWeight: "400",
                          wordWrap: "break-word",
                        }}
                      >
                        Your company details have been verified, and your
                        <br /> account has been successfully created
                      </p>

                      {/* GST Number Input */}
                      <div className="firstInput">
                        <div
                          className="DivForInfoButton"
                          style={{
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <label htmlFor="gstNumber">
                            Legal name of business
                          </label>
                          <Tooltip
                            title="Legal name of business"
                            placement="right"
                          >
                            <img src={iSymbol} alt="Info" />
                          </Tooltip>
                        </div>
                        <input
                          type="text"
                          id="gstNumber"
                          name="gstNumber"
                          placeholder="Legal name of business"
                          value={gstNumber}
                          onChange={(e) => setGstNumber(e.target.value)} // Update state
                          required
                        />
                      </div>

                      {/* GST Username Input */}
                      <div className="secondInput">
                        <div
                          className="DivForInfoButton"
                          style={{
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <label htmlFor="gstUsername">Trade name</label>
                          <Tooltip title="Trade name" placement="right">
                            <img src={iSymbol} alt="Info" />
                          </Tooltip>
                        </div>
                        <input
                          type="text"
                          id="gstUsername"
                          name="gstUsername"
                          placeholder="Trade name"
                          value={gstUsername}
                          onChange={(e) => setGstUsername(e.target.value)} // Update state
                          required
                        />
                      </div>

                      {/* Email Address Input */}
                      <div className="thirdInput">
                        <div
                          className="DivForInfoButton"
                          style={{
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <label htmlFor="email">
                            Constitution of business
                          </label>
                          <Tooltip
                            title="Constitution of business"
                            placement="right"
                          >
                            <img src={iSymbol} alt="Info" />
                          </Tooltip>
                        </div>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Constitution of business"
                          style={{
                            height: "30px",
                            width: "350px",
                            marginBottom: "20px",
                            padding: "5px",
                          }}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)} // Update state
                          required
                        />
                      </div>

                      {/* Phone Number Input */}
                      <div className="fourthInput">
                        <div
                          className="DivForInfoButton"
                          style={{
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <label htmlFor="phoneNumber">
                            Administrative office
                          </label>
                          <Tooltip
                            title="Administrative office"
                            placement="right"
                          >
                            <img src={iSymbol} alt="Info" />
                          </Tooltip>
                        </div>
                        <input
                          type="text"
                          id="phoneNumber"
                          name="phoneNumber"
                          placeholder="Administrative office Location"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)} // Update state
                          required
                        />
                      </div>

                      {/* Submit Button */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          flexDirection: "column",
                        }}
                      >
                        <p
                          style={{
                            color: "black",
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "20px",
                            wordWrap: "break-word",
                          }}
                        >
                          We’ve sent your account credentials to the provided
                          <br />
                          email and phone number. You're now ready to continue.
                        </p>
                      </div>
                    </form>

                    {/* <div style={{ width: "100%", textAlign: "center" }}>
                      <p>
                        <Link
                          to="/"
                          style={{
                            textDecoration: "underline",
                            color: "black",
                            fontWeight: "500",
                          }}
                        >
                          Go Back
                        </Link>
                      </p>
                    </div> */}
                  </div>
                </div>
              </Sign1>
            </>
          )}
        </>
      ) : (
        <>
          <Signup2 phoneNumber={phoneNumber} />
        </>
      )}
    </>
  );
}

export default Signup3;

const Sign1 = styled.div`
  * {
    font-family: "Poppins";
  }
  .mainContent {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
  }
  .firstSection {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #297fff;
  }
  .secondSection {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }

  .DivForInfoButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  input[type="number"],
  input[type="text"],
  input [type="email"] input[type="password"] {
    height: 30px;
    width: 350px;
    margin-bottom: 20px;
    padding: 5px;
  }

  button[type="submit"] {
    font-size: 16px;
    color: white;
    height: 40px;
    width: 60%;
    margin-bottom: 20px;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .login-button {
    font-size: 16px;
    height: 40px;
    width: 50%;
    margin-bottom: 20px;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 600;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
`;
