import styled from "styled-components";
import React from "react";

function TradeProfileTabFour() {
  return (
    <Finance>
      <div
        className="tab-pane fade pt-3 active show"
        id="profile-KYC"
        role="tabpanel"
      >
        <h5 className="card-title" align="center">
          KYC Details
        </h5>
        <div className="row">
          <div className="col-lg-3 col-md-4 label">
            *Company Identification Number (CIN)
          </div>
          <div className="col-lg-9 col-md-8">
            <input type="file" id="fileInput" accept="/*" />
            <button id="fileuploadbtn" onclick="uploadFile(1)" className="btn">
              Upload
            </button>
            <p id="notification-1" style={{ color: "green" }}></p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-4 label">
            Permanent Account Number (PAN)
          </div>
          <div className="col-lg-9 col-md-8">
            <input type="file" id="fileInput" accept="/*" />
            <button id="fileuploadbtn" onclick="uploadFile(1)" className="btn">
              Upload
            </button>
            <p id="notification-1" style={{ color: "green" }}></p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-4 label">
            *Goods and Services Tax Identification Number (GSTIN)
          </div>
          <div className="col-lg-9 col-md-8">
            <input type="file" id="fileInput" accept="/*" />
            <button id="fileuploadbtn" onclick="uploadFile(1)" className="btn">
              Upload
            </button>
            <p id="notification-1" style={{ color: "green" }}></p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-4 label">
            Udyam Registration Number
          </div>
          <div className="col-lg-9 col-md-8">
            <input type="file" id="fileInput" accept="/*" />
            <button id="fileuploadbtn" onclick="uploadFile(1)" className="btn">
              Upload
            </button>
            <p id="notification-1" style={{ color: "green" }}></p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-4 label">Aadhaar Number</div>
          <div className="col-lg-9 col-md-8">
            <input type="file" id="fileInput" accept="/*" />
            <button id="fileuploadbtn" onclick="uploadFile(1)" className="btn">
              Upload
            </button>
            <p id="notification-1" style={{ color: "green" }}></p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-4 label">Passport Number</div>
          <div className="col-lg-9 col-md-8">
            <input type="file" id="fileInput" accept="/*" />
            <button id="fileuploadbtn" onclick="uploadFile(1)" className="btn">
              Upload
            </button>
            <p id="notification-1" style={{ color: "green" }}></p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-4 label">Voter ID</div>
          <div className="col-lg-9 col-md-8">
            <input type="file" id="fileInput" accept="/*" />
            <button id="fileuploadbtn" onclick="uploadFile(1)" className="btn">
              Upload
            </button>
            <p id="notification-1" style={{ color: "green" }}></p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-4 label">Driving License</div>
          <div className="col-lg-9 col-md-8">
            <input type="file" id="fileInput" accept="/*" />
            <button id="fileuploadbtn" onclick="uploadFile(1)" className="btn">
              Upload
            </button>
            <p id="notification-1" style={{ color: "green" }}></p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-4 label">License Number</div>
          <div className="col-lg-9 col-md-8">
            <input type="file" id="fileInput" accept="/*" />
            <button id="fileuploadbtn" onclick="uploadFile(1)" className="btn">
              Upload
            </button>
            <p id="notification-1" style={{ color: "green" }}></p>
          </div>
        </div>
        <div className="text-center">
          <button
            type="submit"
            className="btn btn-primary"
            style={{ marginTop: "30px", marginLeft: "48%" }}
          >
            Edit KYC
          </button>
        </div>
        {/* Add more KYC details here as needed */}
      </div>
    </Finance>
  );
}

export default TradeProfileTabFour;

const Finance = styled.div`
  .label {
    font-weight: 600;
    font-size: 22px;
    color: rgba(1, 41, 112, 0.6);
    width: 200px;
  }
  .col-lg-9 {
    font-size: 20px;
    width: 640px;
  }

  .row {
    display: flex;
    margin-bottom: 10px;
    padding-left: 30px;
  }
  .card-title {
    font-weight: bold;
    font-size: 24px;
  }
  .btn {
    color: white;
    background-color: #007bff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-left: 90px;
  }

  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
`;
