import { useAuth } from "../../context/auth";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Signin from "./Signin";
import Signup from "./Signup";
import SetPassword from "./SetPassword";
import Signup1 from "./Signup1";
import Signup2 from "./Signup2";
import Signup3 from "./Signup3";

const SignInLayout = () => {
  const [auth, setAuth] = useAuth();

  return (
    <Routes>
      <Route path="/creditbazaar/signup" element={<Signup />} />
      <Route path="/creditbazaar/signup1" element={<Signup1 />} />
      <Route path="/creditbazaar/signup2" element={<Signup2 />} />
      <Route path="/creditbazaar/signup3" element={<Signup3 />} />
      <Route path="/creditbazaar/passwordsetup" element={<SetPassword />} />
      <Route path="*" element={<Signin />} />
    </Routes>
  );
};

export default SignInLayout;
