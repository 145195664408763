import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Wizard from "./Wizard";
import { useAuth } from "../../../context/auth";
import { controllerAPI, opscontroller } from "../../API/FP/Actions";
import { Button, CircularProgress } from "@mui/material";
import styled from "styled-components";
import Pagenotfound from "../../Common/pagenotfound";
import {
  contextObjforapicallStartWizard,
  contextObjforStartWizardReconcile,
} from "../../Common/Constants";

const IncomingDisbursement = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [popupbtnopen, setpopupbtnopen] = useState(false);
  const [AnchorTraderID, setAnchorTraderID] = useState();
  const [showWiz, setshowWiz] = useState(false);
  const [WizardId, setWizardId] = useState();
  const [wizardInstanceId, setwizardInstanceId] = useState();
  const [presentDetails, setpresentDetails] = useState();
  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };

  const StartWizardApiCall = async () => {
    setLoading(true);
    try {
      const constructObj = contextObjforStartWizardReconcile(authContextObj);
      const response = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "reconcile-disbursement-wizard"
      );
      if (response?.response?.status === 401) {
        localStorage.removeItem("auth");
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        navigate("/creditbazaar/");
      }
      if (response.res.status === 200) {
        setshowWiz(true);
        console.log(
          "the response of the start wizard api is :",
          response?.res?.data?.data
        );
        setWizardId(response?.res?.data?.data?.reconcileDisbursementWizard?.id);
        setwizardInstanceId(
          response?.res?.data?.data?.reconcileDisbursementWizardStepData?.id
        );
      }
    } catch (error) {
      console.error("Error fetching accepted offer details:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  const openpopup = (id) => {
    setpopupbtnopen(true);
    setAnchorTraderID(id);
  };

  const closepopup = () => {
    setpopupbtnopen(false);
  };

  /*  const popupsaved = (innoice) => {
  };*/

  useEffect(() => {
    getIncomingDisb();
  }, [auth?.token]);

  const getIncomingDisb = async () => {
    setLoading(true);
    try {
      const response = await opscontroller(
        "post",
        auth?.token,
        null,
        "incoming-disbursements"
      );
      if (response?.response?.status === 401) {
        localStorage.removeItem("auth");
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        navigate("/creditbazaar/");
      }
      if (response.res.status === 200) {
        setTableData(response.res.data.data.escrowTransactionDetailsList);
      }
    } catch (error) {
      console.error("Error fetching accepted offer details:", error);
    } finally {
      setLoading(false);
    }
  };
  console.log("this is the table data", tableData);
  return (
    <Acceptedoff>
      <div>
        {!showWiz && (
          <div>
            <h2> Disbursment Details</h2>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100vh",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                {" "}
                {tableData.length > 0 ? (
                  <table style={{ border: "1px solid #ddd" }}>
                    <thead>
                      <tr>
                        <th
                          style={{
                            padding: "8px",
                            justifycontent: "center",
                            alignitems: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          ID
                        </th>
                        <th
                          style={{
                            padding: "8px",
                            justifycontent: "center",
                            alignitems: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          Escrow Trnx Details Ulid Id
                        </th>

                        <th
                          style={{
                            padding: "8px",
                            justifycontent: "center",
                            alignitems: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          Batch Amt
                        </th>
                        <th
                          style={{
                            padding: "8px",
                            justifycontent: "center",
                            alignitems: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          Batch Amt Ccd
                        </th>
                        <th
                          style={{
                            padding: "8px",
                            justifycontent: "center",
                            alignitems: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          Credit Date
                        </th>
                        <th
                          style={{
                            padding: "8px",
                            justifycontent: "center",
                            alignitems: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          Va Number
                        </th>

                        <th
                          style={{
                            padding: "8px",
                            justifycontent: "center",
                            alignitems: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          Remitter Name
                        </th>
                        <th
                          style={{
                            padding: "8px",
                            justifycontent: "center",
                            alignitems: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          Remitter Account Number
                        </th>
                        <th
                          style={{
                            padding: "8px",
                            justifycontent: "center",
                            alignitems: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          Ifsc Code
                        </th>
                        <th
                          style={{
                            padding: "8px",
                            justifycontent: "center",
                            alignitems: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData?.map((item, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              padding: "8px",
                              justifycontent: "center",
                              alignitems: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.id}
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              justifycontent: "center",
                              alignitems: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.escrowTrnxDetailsUlidId}
                          </td>

                          <td
                            style={{
                              padding: "8px",
                              justifycontent: "center",
                              alignitems: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.batchAmt}
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              justifycontent: "center",
                              alignitems: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.batchAmtCcd}
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              justifycontent: "center",
                              alignitems: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.creditDate}
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              justifycontent: "center",
                              alignitems: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.vaNumber}
                          </td>

                          <td
                            style={{
                              padding: "8px",
                              justifycontent: "center",
                              alignitems: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.remitterName}
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              justifycontent: "center",
                              alignitems: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.remitterAccountNumber}
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              justifycontent: "center",
                              alignitems: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.ifscCode}
                          </td>

                          <td
                            style={{
                              padding: "8px",
                              justifycontent: "center",
                              alignitems: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            <Button
                              style={{
                                borderRadius: "5px",
                                backgroundColor: "#4A3AFF",
                                color: "white",
                                border: "none",
                                cursor: "pointer",
                                height: "fit-content",
                                boxShadow:
                                  "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                                margin: "5px",
                              }}
                              onClick={() => {
                                setpresentDetails(item);
                                StartWizardApiCall();
                              }}
                            >
                              Proceed
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <Pagenotfound />
                )}
              </>
            )}
          </div>
        )}
        {showWiz && (
          <Wizard
            selectedRecordDetails={presentDetails}
            setshowWiz={setshowWiz}
            WizardId={WizardId}
            setWizardId={setWizardId}
            wizardInstanceId={wizardInstanceId}
            setwizardInstanceId={setwizardInstanceId}
          />
        )}
      </div>
    </Acceptedoff>
  );
};

export default IncomingDisbursement;

const Acceptedoff = styled.div`
  table {
    // width: 300%;
    border-collapse: collapse;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
  }
  th {
    background-color: #f8f9fa;
    font-weight: bold;
  }
`;
