import React from 'react';
import styled from 'styled-components';

const BigNumberWidget = ({ number, label, color = 'blue' }) => {
  return (
    <WidgetContainer>
      <NumberValue color={color}>{number}</NumberValue>
      <Label>{label}</Label>
    </WidgetContainer>
  );
};

// Styled Components
const WidgetContainer = styled.div`
  padding: 1.5rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 8px -1px rgba(0, 0, 0, 0.1);
  }
`;

const NumberValue = styled.div`
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: ${props => props.color};

  @media (min-width: 768px) {
    font-size: 2.25rem;
  }
`;

const Label = styled.div`
  color: #4b5563;
  font-size: 0.875rem;
  
  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;

export default BigNumberWidget;