import React, { useState } from "react";
import styled from "styled-components";
import {
  Alert,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
} from "@mui/material";
import { useAuth } from "../../../context/auth";
import {
  completeParticipantSettlement,
  CompleteSettlement,
  contextObjforCloseWizardReconcileDisb,
  contextObjforCloseWizardSettlement,
  contextObjForParticipantSettlement,
  contextObjForParticipantSettlementCompletion,
  contextObjForSettlementCompletion,
  contextObjFundsTransferSettlement,
  objectforBeneval,
  PrepareParticipantSettlement,
  PreparetriggerSettlementBeneValidation,
  PreparetriggerSettlementFundsTransfer,
} from "../../Common/Constants";
import { controllerAPI, opscontroller } from "../../API/FP/Actions";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";

const Step5 = ({
  step1output,
  selectedRecordDetails,
  step2output,
  nextStep,
  handleChange,
  formData,
  setstep5output,
  step4output,
  prevStep,
  secondStep1Output,

  wizardRecordId,
  wizardRecordInstanceId,
  setWizardRecordInstanceId,
  wizardPageId,
  setWizardPageId,
  actionButtonId,
  setActionButtonId,
  NextButtonId,
  setNextButtonId,
}) => {
  const handleInputChange = (e) => {
    handleChange(e.target.name, e.target.value);
  };

  const [btnenable, setbtnenable] = useState(false);
  const [auth, setAuth] = useAuth();
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [showdata, setshowdata] = useState(false);
  const [data, setdata] = useState([]);
  const [closeId, setCloseId] = useState(1);
  const navigate = useNavigate();
  const [wizardRecordInstanceIdStep1, setwizardRecordInstanceIdStep1] =
    useState(wizardRecordInstanceId);
  const [wizardRecordInstanceIdStep2, setwizardRecordInstanceIdStep2] =
    useState();
  step1output = Array.isArray(step1output) ? step1output : [step1output];
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };

  const ActionButtonResponse = async (
    participantSettlement,
    fundsTransferTransactionDetails
  ) => {
    setLoading(true);
    try {
      setLoading(true);
      const constructObj = contextObjForParticipantSettlementCompletion(
        authContextObj,
        wizardRecordId,
        wizardRecordInstanceId,
        wizardPageId,
        actionButtonId,
        participantSettlement,
        fundsTransferTransactionDetails
      );
      // Updated API call with pagination parameters
      const ATTradesresponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        `settlement-wizard`
      );

      if (ATTradesresponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        navigate("/creditbazaar/");
      }

      if (ATTradesresponse.res.status === 200) {
        console.log("sucess");
        setActionButtonId(actionButtonId + 1);
        setwizardRecordInstanceIdStep1(
          ATTradesresponse?.res?.data?.data?.executeSettlementWizardStepData?.id
        );
        setOpenSnackbar(true);
        setApiResultMessage("Participant Settlement completed successfully ");
        setAlertSeverity("success");
        setbtnenable(true);
        setshowdata(true);
        try {
          const parseMsg = JSON.parse(
            ATTradesresponse?.res?.data?.data?.executeSettlementWizardStepData
              ?.apiResponse
          );
          const parsTwo = JSON.parse(parseMsg);
          console.log("api response data is:", parsTwo);
          setstep5output(parsTwo?.data);
          setdata(parsTwo?.data);
          console.log("data being set is :", parsTwo?.data);
        } catch (error) {
          console.error("Failed to parse API response:", error);
        }
      }
      if (ATTradesresponse?.response?.status === 400) {
        console.log(
          "response?.response?.data?.error",
          ATTradesresponse?.response?.data?.erro
        );
        setOpenSnackbar(true);
        setApiResultMessage(ATTradesresponse?.response?.data?.error);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error fetching  details:", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error in Completing participant settlement!");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const ActionButtonResponseForCompleteSettlement = async (
    financeRequest,
    requestOffer,
    placedOffer,
    acceptedOffer,
    repayment,
    settlement
  ) => {
    setLoading(true);
    try {
      setLoading(true);
      const constructObj = contextObjForSettlementCompletion(
        authContextObj,
        wizardRecordId,
        wizardRecordInstanceIdStep1,
        wizardPageId,
        actionButtonId,
        financeRequest,
        requestOffer,
        placedOffer,
        acceptedOffer,
        repayment,
        settlement
      );
      // Updated API call with pagination parameters
      const ATTradesresponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        `settlement-wizard`
      );

      if (ATTradesresponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        navigate("/creditbazaar/");
      }

      if (ATTradesresponse.res.status === 200) {
        console.log("sucess");
        setActionButtonId(actionButtonId + 1);
        setwizardRecordInstanceIdStep2(
          ATTradesresponse?.res?.data?.data?.executeSettlementWizardStepData?.id
        );
        setOpenSnackbar(true);
        setApiResultMessage("Settlement done successfully ");
        setAlertSeverity("success");
        setbtnenable(true);
        setshowdata(true);
        setTimeout(() => {
          closBtn();
        }, 1);
        try {
          const parseMsg = JSON.parse(
            ATTradesresponse?.res?.data?.data?.executeSettlementWizardStepData
              ?.apiResponse
          );
          const parsTwo = JSON.parse(parseMsg);
          console.log("api response data is:", parsTwo);
          //setstep5output(parsTwo?.data);
          //  setdata(parsTwo?.data);
          console.log("data being set is :", parsTwo?.data);
        } catch (error) {
          console.error("Failed to parse API response:", error);
        }
      }
      if (ATTradesresponse?.response?.status === 400) {
        console.log(
          "response?.response?.data?.error",
          ATTradesresponse?.response?.data?.erro
        );
        setOpenSnackbar(true);
        setApiResultMessage(ATTradesresponse?.response?.data?.error);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error fetching  details:", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error in Completing  settlement!");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  //close wizard

  const closBtn = async () => {
    setLoading(true);
    try {
      setLoading(true);
      const constructObj = contextObjforCloseWizardSettlement(
        authContextObj,
        wizardRecordId,
        wizardRecordInstanceIdStep2,
        closeId
      );
      // Updated API call with pagination parameters
      const ATTradesresponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        `settlement-wizard`
      );

      if (ATTradesresponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        navigate("/creditbazaar/");
      }

      if (ATTradesresponse.res.status === 200) {
        console.log("sucess");
        window.location.reload();
      }
      if (ATTradesresponse?.response?.status === 400) {
        console.log(
          "response?.response?.data?.error",
          ATTradesresponse?.response?.data?.erro
        );
        setOpenSnackbar(true);
        setApiResultMessage(ATTradesresponse?.response?.data?.error);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error fetching  details:", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error in Closing settlement wizard!");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const TriggerCompleteSettlement = async (
    financeRequest,
    requestOffer,
    placedOffer,
    acceptedOffer,
    repayment,
    settlement
  ) => {
    const constructObj = CompleteSettlement(
      financeRequest,
      requestOffer,
      placedOffer,
      acceptedOffer,
      repayment,
      settlement
    );
    try {
      setLoading(true);
      const response = await opscontroller(
        "post",
        auth?.token,
        constructObj,
        "complete-settlement"
      );
      if (response.res.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        navigate("/creditbazaar/");
      }
      if (response?.res?.status === 200) {
        setOpenSnackbar(true);
        setApiResultMessage("Settlement done successfully ");
        setAlertSeverity("success");
        // setstep3output(response?.res?.data?.data);
        setdata(response?.res?.data?.data);
        setbtnenable(true);
        setshowdata(true);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
      if (response?.response?.status === 400) {
        console.log(
          "response?.response?.data?.error",
          response?.response?.data?.erro
        );
        setOpenSnackbar(true);
        setApiResultMessage(response?.response?.data?.error);
        setAlertSeverity("error");
      }
      if (response?.response?.status === 500) {
        setOpenSnackbar(true);
        setApiResultMessage(response?.response?.data?.error);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error fetching  details:", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error in Bene Validation!");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const CompleteParticiapntSettlement = async (
    participantSettlement,
    fundsTransferTransactionDetails
  ) => {
    const constructObj = completeParticipantSettlement(
      participantSettlement,
      fundsTransferTransactionDetails
    );
    try {
      setLoading(true);
      const response = await opscontroller(
        "post",
        auth?.token,
        constructObj,
        "complete-participant-settlement"
      );
      if (response?.res?.status === 200) {
        setOpenSnackbar(true);
        setApiResultMessage("Participant Settlement completed successfully ");
        setAlertSeverity("success");
        setstep5output(response?.res?.data?.data);
        setdata(response?.res?.data?.data);
        setbtnenable(true);
        setshowdata(true);
        if (secondStep1Output === false) {
          const timeoutId = setTimeout(() => {
            window.location.reload();
          }, 2000);

          return () => clearTimeout(timeoutId);
        }
      }
      if (response?.response?.status === 400) {
        console.log(
          "response?.response?.data?.error",
          response?.response?.data?.erro
        );
        setOpenSnackbar(true);
        setApiResultMessage(response?.response?.data?.error);
        setAlertSeverity("error");
      }
      if (response?.response?.status === 500) {
        setOpenSnackbar(true);
        setApiResultMessage(response?.response?.data?.error);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error fetching  details:", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error in Bene Validation!");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  console.log("financeRequest is :", step2output?.financeRequest);
  console.log("requestOffer is :", step2output?.requestOffer);
  console.log("placedOffer is :", step2output?.placedOffer);
  console.log("acceptedOffer is :", step2output?.acceptedOffer);
  console.log("repayment is :", step2output?.repayment);
  console.log("settlement is :", step2output?.settlement);
  /*console.log(
    "repayment map for last step is:",
    selectedRecordDetails?.fundTransferS3Path
  );
  console.log(
    "settlement map for last step is:",
    selectedRecordDetails?.settlement
  );*/
  console.log("secondStep1Output is from step 5 is :", secondStep1Output);
  console.log(
    "participantSettlement map for last step is:",
    selectedRecordDetails?.step1output
  );
  console.log(
    "fundsTransferTransactionDetails map for last step is:",
    step4output?.data?.fundTransferEscrowUtilityAPIResponseData?.data
      ?.ftTrnxDetails
  );
  return (
    <StepContainer>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="step-content">
          <h2>STEP 5:Complete Participant Settlement</h2>
          <h3>
            {" "}
            Cross-verify the participant settlement details
            <br />
          </h3>
          <TableContainer>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Participant Id</th>
                  <th>Participant Name</th>
                  <th>Gst Id</th>
                  <th>Settlement Type</th>
                  <th>Charge Type</th>
                  <th>Settlement Amount</th>
                  <th>Settlement Due Date</th>
                  <th>Patron Of Payment</th>
                  <th>Recipient Of Payment</th>
                  <th>Participant Settlement Status</th>
                </tr>
              </thead>
              <tbody>
                {step1output?.map((row, index) => (
                  <tr key={index}>
                    <td>{row?.id}</td>
                    <td>{row?.participantId}</td>
                    <td>{row?.participantName}</td>
                    <td>{row?.gstId}</td>
                    <td>{row?.settlementType}</td>
                    <td>{row?.chargeType}</td>
                    <td>{row?.settlementAmount}</td>
                    <td>{row?.settlementDueDate}</td>
                    <td>{row?.patronOfPayment}</td>
                    <td>{row?.recipientOfPayment}</td>
                    <td>{row?.participantSettlementStatus}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableContainer>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "800px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h3>To Complete Participant Settlement Click to Proceed</h3>
              <Button
                disabled={btnenable}
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#4A3AFF",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                  height: "fit-content",
                  boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                  margin: "5px",
                  opacity: !btnenable ? "1" : "0.5",
                }}
                onClick={() => {
                  ActionButtonResponse(
                    step1output?.[0],
                    step4output?.fundTransferEscrowUtilityAPIResponseData?.data
                      ?.ftTrnxDetails
                  );
                }}
              >
                Proceed
              </Button>
            </div>
          )}
          {showdata === true ? (
            <>
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "800px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Participant settlement completed
                  </p>
                </div>
                <div className="ButtonsPlacement">
                  <button
                    className="Backbtn"
                    onClick={() => window.location.reload()}
                  >
                    Back
                  </button>
                  <button
                    className="CompleteSettlemetn"
                    onClick={() => {
                      ActionButtonResponseForCompleteSettlement(
                        step2output?.financeRequest,
                        step2output?.requestOffer,
                        step2output?.placedOffer,
                        step2output?.acceptedOffer,
                        step2output?.repayment,
                        step2output?.settlement
                      );
                    }}
                  >
                    Complete Settlement
                  </button>
                </div>
              </>
            </>
          ) : (
            <>
              <p></p>
            </>
          )}

          <Snackbar
            open={openSnackbar}
            //autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={alertSeverity}
              sx={{ width: "100%" }}
            >
              {apiResultMessage}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </StepContainer>
  );
};

export default Step5;

const StepContainer = styled.div`
  .step-content {
    max-width: 980px;
    margin: auto;
  }

  .button {
    padding: 10px 20px;
    margin: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }

  .button:hover {
    background-color: #0056b3;
  }
  .ButtonsPlacement {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .CompleteSettlemetn {
    font-size: 16px;
    border-radius: 5px;
    background-color: #297fff;
    font-family: "Open Sans";
    font-weight: 400; /* Remove quotes */
    color: white;
    height: 45px;
    width: 120px;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    align-self: flex-end; /* Corrected */
  }
  .Backbtn {
    color: black; /* Corrected to lowercase */
    height: 45px;
    width: 120px;
    background-color: white;
    border: 1px #404040 solid;
    display: flex; /* To use justify-content and align-items */
    justify-content: center; /* Remove quotes */
    align-items: center; /* Remove quotes */
    padding: 20px 36px;
    border-radius: 2px;
    cursor: pointer;
    align-self: flex-start; /* Corrected */
    border-radius: 5px;
  }
`;

const TableContainer = styled.div`
  max-width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
  }
`;
