import React from "react";

const Workflow = ({ fill = "#0C0C0C" }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_565_1842)">
      <path
        d="M15.957 4.24997L12 0.292969L8.043 4.24997L11.5 7.70697V9.53797C9.945 9.77897 8.75 11.127 8.75 12.749C8.75 14.371 9.945 15.719 11.5 15.96V17.999H9V23.999H15V17.999H12.5V15.96C14.055 15.719 15.25 14.371 15.25 12.749C15.25 11.127 14.055 9.77897 12.5 9.53797V7.70697L15.957 4.24997ZM14 23H10V19H14V23ZM12 1.70697L14.543 4.24997L12 6.79297L9.457 4.24997L12 1.70697ZM14.25 12.75C14.25 13.99 13.241 15 12 15C10.759 15 9.75 13.99 9.75 12.75C9.75 11.51 10.759 10.5 12 10.5C13.241 10.5 14.25 11.509 14.25 12.75ZM7.664 19.65C8.151 20.136 8.151 20.928 7.664 21.415L5.02 24.059L4.313 23.352L6.665 21H2.5C1.122 21 0 19.879 0 18.5V13.5C0 12.122 1.122 11 2.5 11H7V12H2.5C1.673 12 1 12.673 1 13.5V18.5C1 19.327 1.673 20 2.5 20H6.599L4.313 17.714L5.02 17.007L7.664 19.65ZM24 5.49997V10.5C24 11.878 22.878 13 21.5 13H17.335L19.687 15.352L18.98 16.059L16.336 13.415C15.85 12.928 15.85 12.137 16.336 11.65L18.98 9.00597L19.687 9.71297L17.401 11.999H21.5C22.327 11.999 23 11.326 23 10.499V5.49897C23 4.67197 22.327 3.99897 21.5 3.99897H17V2.99897H21.5C22.878 2.99897 24 4.12197 24 5.49997Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_565_1842">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Workflow;
