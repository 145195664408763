import { createSlice } from "@reduxjs/toolkit";

const apiResponseSlice = createSlice({
  name: "apiResponse",
  initialState: {
    data: null, // Holds the API response
    status: "idle", // Status: idle | loading | succeeded | failed
    error: null, // To track errors
  },
  reducers: {
    fetchDataSuccess: (state, action) => {
      state.data = action;
      state.status = "succeeded";
    },
    fetchDataFailure: (state, action) => {
      state.error = action.payload;
      state.status = "failed";
    },
  },
});

export const { fetchDataSuccess, fetchDataFailure } = apiResponseSlice.actions;

export default apiResponseSlice.reducer;
