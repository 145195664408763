import React from "react";
import { createSlice } from "@reduxjs/toolkit";

const DisbursmentWizardReducer =createSlice({
    

        name :"disbursment wizard reducer",
        initialState :{
            stepOneOutput:" ",
            status:"Idle"
        },
    
        reducers :{
            saveApiData:(state,action)=>{
                state.stepOneOutput = action.payload
            }  
        },
})


export const  {saveApiData}  = DisbursmentWizardReducer.actions

export default DisbursmentWizardReducer.reducer