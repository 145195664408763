import { Alert, CircularProgress, Input, Snackbar } from "@mui/material";
import React, { useState } from "react";
import { useAuth } from "../../../context/auth";
import {
  CreateTradeConstructObject,
  gstCompanyDetailsContextObjController,
} from "../../Common/Constants";
import { controllerAPI } from "../../API/FP/Actions";
import { Navigate } from "react-router-dom";

function Component5({
  isctive,
  nextStep,
  prevStep,
  setIsLoading,
  tpGSTID,
  tradeMasterDocReader,
  goBack,
}) {
  const [auth, setAuth] = useAuth();
  const [enableProceed, setEnableProceed] = useState(false);
  const [responseFetchDetails, setResponseFetchDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null); // Error state
  const [loading, setLoading] = useState(false); // Loading state
  const [showBtn, setShowBtn] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: "1",
  };

  const handleCreateTrade = async (tradeMasterDocReader) => {
    setLoading(true);
    try {
      const constructObject = CreateTradeConstructObject(
        authContextObj,
        tradeMasterDocReader
      );
      const ATTradesresponse = await controllerAPI(
        "post",
        auth?.token,
        constructObject,
        "create-trade"
      );
      console.log("ATTradesresponse", ATTradesresponse);
      if (ATTradesresponse?.res?.status == 200) {
        console.log("trade created successfully ");
        setOpenSnackbar(true);
        setApiResultMessage("Trade created successfully ");
        setAlertSeverity("success");
        setDisableBtn(true);
      }
      if (ATTradesresponse?.response?.status == 400) {
        setLoading(false);
        setOpenSnackbar(true);
        setApiResultMessage(ATTradesresponse?.response?.data?.message);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.log("error ", error);
      setOpenSnackbar(true);
      setApiResultMessage(error?.config?.response?.data?.message);
      setAlertSeverity("error");
      console.log("error is ", error);
    } finally {
      setLoading(false);
      // setOpenSnackbar(true);
      // setApiResultMessage("Unable to create trade  ");
      // setAlertSeverity("error");
    }
  };
  const handleProceed = async () => {
    setLoading(true);
    setErrorMessage(null); // Clear error message before API call
    try {
      const constructObj = gstCompanyDetailsContextObjController(
        authContextObj,
        tpGSTID
      );
      const ATTradesresponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "create-tradepartner"
      );
      console.log("ATTradesresponse", ATTradesresponse);
      if (ATTradesresponse.res?.status === 200) {
        console.log("srikar the create tp is success ");
        setShowBtn(true);
        setOpenSnackbar(true);
        setApiResultMessage("Trade Partner details fetched successfully !");
        setAlertSeverity("success");
      } else {
        setEnableProceed(false); // Disable Proceed on failure
        setOpenSnackbar(true);
        setApiResultMessage("Failed to fetch tp details !");
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error fetching details:", error);

      setEnableProceed(false);
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };

  const validateGSTDetailsApi = async () => {
    setLoading(true);
    setErrorMessage(null); // Clear error message before API call
    try {
      const constructObj = gstCompanyDetailsContextObjController(
        authContextObj,
        tpGSTID
      );
      const ATTradesresponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "fetch-company-details"
      );

      if (ATTradesresponse.res?.status === 200) {
        const companyDetails = ATTradesresponse?.res?.data?.data?.company;
        setResponseFetchDetails(companyDetails);
        setEnableProceed(true); // Enable Proceed when data is fetched successfully
        setOpenSnackbar(true);
        setApiResultMessage("GST is valid !");
        setAlertSeverity("success");
      } else {
        setErrorMessage("Failed to fetch company details.");
        setEnableProceed(false); // Disable Proceed on failure
        setOpenSnackbar(true);
        setApiResultMessage("Error in Fetching details !");
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error fetching details:", error);
      setErrorMessage("Error fetching details. Please try again.");
      setEnableProceed(false); // Disable Proceed on error
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };

  return (
    <>
      {loading ? (
        <CircularProgress
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            zIndex: "1100",
          }}
        />
      ) : (
        <div
          className="ThirdComponent"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            margin: "50px 50px 0px",
          }}
        >
          <h3
            style={{
              color: "black",
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "2",
              wordWrap: "break-word",
              textAlign: "left",
            }}
          >
            Validate the trade partner and create trade
          </h3>
          <div
            className="PdfDocHolderComp"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "stretch",
              width: "100%",
              height: "700px",
              gap: "10px",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <div
              className="leftComponent"
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                marginTop: "100px",
              }}
            >
              <h4
                style={{
                  fontWeight: "700",
                  color: "black",
                  marginBottom: "20px",
                }}
              >
                Trade Partner GST Number From Trade Doc Reader
              </h4>
              <Input
                id="TPGst"
                style={{
                  width: "75%",
                  height: "5%",
                  backgroundColor: "white",
                  opacity: "1",
                  color: "black",
                  WebkitTextFillColor: "black",
                }}
                type="text"
                placeholder={tpGSTID}
                disabled
              />

              <button
                type="button"
                disabled={tpGSTID == null || loading}
                style={{
                  opacity: tpGSTID == null || loading ? "0.5" : "1",
                  fontSize: "18px",
                  backgroundColor: "#297fff",
                  fontFamily: "Open Sans",
                  fontWeight: "400",
                  color: "white",
                  height: "45px",
                  width: "120px",
                  border: "0px",
                  borderRadius: "3px",
                  cursor: "pointer",
                  marginTop: "10px",
                  marginLeft: "10px",
                }}
                onClick={validateGSTDetailsApi}
              >
                Validate
              </button>
            </div>

            {/* Right Component - Form */}
            <div
              className="rightComponent"
              style={{
                flex: 1.5,
                display: "flex",
                flexDirection: "column",
                borderLeft: "1px solid #ddd",
                padding: "20px",
                background: "#fff",
              }}
            >
              <h2
                style={{
                  fontWeight: "700",
                  color: "black",
                  marginBottom: "20px",
                }}
              >
                Details of the trade partner
              </h2>
              <div
                style={{
                  maxHeight: "650px",
                  maxWidth: "400px",
                  padding: "10px 20px",
                  borderRadius: "10px",
                }}
              >
                <form>
                  <div style={{ marginBottom: "15px" }}>
                    <label
                      style={{
                        display: "block",
                        fontWeight: "bold",
                        marginBottom: "5px",
                        marginTop: "10px",
                      }}
                    >
                      Type of firm:{" "}
                    </label>
                    <Input
                      type="text"
                      placeholder="Firm Type"
                      style={{
                        width: "100%",
                        border: "none",
                        outline: "none",
                        padding: "5px 0",
                      }}
                      value={responseFetchDetails?.ctb || ""}
                      disabled
                    />

                    <label
                      style={{
                        display: "block",
                        fontWeight: "bold",
                        marginBottom: "5px",
                        marginTop: "10px",
                      }}
                    >
                      Legal Name of Business:{" "}
                    </label>
                    <Input
                      type="text"
                      placeholder="Name of the firm"
                      style={{
                        width: "100%",
                        border: "none",
                        outline: "none",
                        padding: "5px 0",
                      }}
                      disabled
                      value={responseFetchDetails?.lgnm || ""}
                    />
                  </div>
                </form>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {showBtn == true ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h3>
                      The Trade partner created successfully! Go ahead and
                      create trade{" "}
                    </h3>
                    <button
                      type="button"
                      disabled={disableBtn}
                      style={{
                        fontSize: "18px",
                        opacity: disableBtn ? "0.5" : "1",
                        backgroundColor: "#297fff",
                        fontFamily: "Open Sans",
                        fontWeight: "400",
                        color: "white",
                        height: "50px",
                        width: "140px",
                        border: "0px",
                        borderRadius: "3px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleCreateTrade(tradeMasterDocReader);
                      }}
                    >
                      Create Trade
                    </button>
                    <button
                      className="Backbtn"
                      style={{
                        color: "black",
                        height: "50px",
                        width: "140px",
                        fontSize: "16px",
                        borderRadius: "3px",
                        cursor: "pointer",
                        background: "white",
                        border: "0.5px solid",
                        marginTop: "10px",
                      }}
                      onClick={goBack}
                    >
                      Review more trades
                    </button>
                  </div>
                ) : (
                  <>
                    <div
                      className="ButtonsPlacement"
                      style={{
                        marginTop: "60px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center ",
                        gap: "20px",
                        width: "100%",
                      }}
                    >
                      <button
                        className="Backbtn"
                        style={{
                          color: "black",
                          height: "50px",
                          width: "140px",
                          fontSize: "16px",
                          borderRadius: "3px",
                          cursor: "pointer",
                          background: "white",
                          border: "0.5px solid",
                        }}
                        onClick={prevStep}
                      >
                        Back
                      </button>

                      <button
                        type="button"
                        disabled={!enableProceed || !responseFetchDetails?.ctb}
                        style={{
                          opacity:
                            enableProceed && responseFetchDetails?.ctb
                              ? "1"
                              : "0.5",
                          fontSize: "18px",
                          backgroundColor: "#297fff",
                          fontFamily: "Open Sans",
                          fontWeight: "400",
                          color: "white",
                          height: "50px",
                          width: "140px",
                          border: "0px",
                          borderRadius: "3px",
                          cursor: "pointer",
                        }}
                        onClick={handleProceed}
                      >
                        Proceed
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <Snackbar
            open={openSnackbar}
            //autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={alertSeverity}
              sx={{ width: "100%" }}
            >
              {apiResultMessage}
            </Alert>
          </Snackbar>
        </div>
      )}
    </>
  );
}

export default Component5;
