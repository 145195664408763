import styled from "styled-components";
import React from "react";
import fimg from "../images/Renova Logo.png";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

const data = {
  name: "Sruthi",
  company: "Renova Hospitals",
  persona: "Trade Partner",
  country: "India",
  phone: "9849333256",
  email: "enquiry@Example.com",
  bankname: "ICICI Bank ",
  account: "123456789045",
  branch: "Gachibowli",
  ifsc: "ICIC0001114",
  receipient: "Renova Hospitals",
};
function TradeProfileTabTwo() {
  return (
    <Finance>
      <div
        className="tab-pane fade profile-edit pt-3 active show"
        id="profile-edit"
        role="tabpanel"
      >
        <form>
          <div className="row mb-3">
            <label
              htmlFor="profileImage"
              className="col-md-4 col-lg-3 col-form-label"
            >
              Profile Image
            </label>
            <div className="col-md-8 col-lg-9">
              <img src={fimg} alt="Profile" />
              <div className="pt-2">
                <a
                  href="#"
                  className="btn btn-primary btn-sm"
                  title="Upload new profile image"
                >
                  <FileUploadOutlinedIcon />
                  <i className="bi bi-upload" />
                </a>
                <a
                  href="#"
                  className="btn btn-danger btn-sm"
                  title="Remove my profile image"
                >
                  <DeleteIcon />
                  <i className="bi bi-trash" />
                </a>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="fullName"
              className="col-md-4 col-lg-3 col-form-label"
            >
              Full Name
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                name="fullName"
                type="text"
                className="form-control"
                id="fullName"
                defaultValue={data.name}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="company"
              className="col-md-4 col-lg-3 col-form-label"
            >
              Company
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                name="company"
                type="text"
                className="form-control"
                id="company"
                defaultValue={data.company}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="Job" className="col-md-4 col-lg-3 col-form-label">
              Persona
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                name="job"
                type="text"
                className="form-control"
                id="Job"
                defaultValue={data.persona}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="Country"
              className="col-md-4 col-lg-3 col-form-label"
            >
              Country
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                name="country"
                type="text"
                className="form-control"
                id="Country"
                defaultValue={data.country}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="Address"
              className="col-md-4 col-lg-3 col-form-label"
            >
              Address
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                name="address"
                type="text"
                className="form-control"
                id="Address"
                defaultValue="Plot No. 245, IDA Rampur, Warangal, Telangana, 506151, India"
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="Phone" className="col-md-4 col-lg-3 col-form-label">
              Phone
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                name="phone"
                type="text"
                className="form-control"
                id="Phone"
                defaultValue={data.phone}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="Email" className="col-md-4 col-lg-3 col-form-label">
              Email
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                name="email"
                type="email"
                className="form-control"
                id="Email"
                defaultValue={data.email}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="Linkedin"
              className="col-md-4 col-lg-3 col-form-label"
            >
              Bank Name
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                name="linkedin"
                type="text"
                className="form-control"
                defaultValue={data.bankname}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="Twitter"
              className="col-md-4 col-lg-3 col-form-label"
            >
              Bank Account
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                name="twitter"
                type="text"
                className="form-control"
                id="Twitter"
                defaultValue={data.account}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="Linkedin"
              className="col-md-4 col-lg-3 col-form-label"
            >
              Branch
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                name="linkedin"
                type="text"
                className="form-control"
                defaultValue={data.branch}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="Facebook"
              className="col-md-4 col-lg-3 col-form-label"
            >
              IFSC Code
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                name="facebook"
                type="text"
                className="form-control"
                defaultValue={data.ifsc}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="Instagram"
              className="col-md-4 col-lg-3 col-form-label"
            >
              Receipient
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                name="instagram"
                type="text"
                className="form-control"
                defaultValue={data.receipient}
              />
            </div>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary"
              style={{ marginTop: "30px", marginLeft: "48%" }}
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </Finance>
  );
}

export default TradeProfileTabTwo;

const Finance = styled.div`
  .col-form-label {
    width: 250px;
    font-weight: 600;
    font-size: 22px;
    color: rgba(1, 41, 112, 0.6);
  }
  .row {
    display: flex;
    margin-top: 30px;
  }
  .form-control {
  }
  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="email"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    width: 622px;
    height: 50px;
  }

  .btn {
    color: white;
    background-color: #007bff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-left: 90px;
  }

  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
`;
