import { useState, useEffect, useContext, createContext } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    user: null,
    token: "",
    userDisplayName: "",
    userDetails: null,
  });

  //default axios
  axios.defaults.headers.common["Authorization"] = auth?.token;

  useEffect(() => {
    const data = localStorage.getItem("auth");
    const parseData = JSON.parse(data);

    if (data) {
      const token = parseData.accessToken;
      if (token) {
        try {
          const decoded = jwtDecode(token);
          const currentTime = Date.now() / 1000; // Get current time in seconds
          if (decoded.exp < currentTime) {
            // Token is POEX, navigate to login page
            setAuth({
              ...auth,
              user: null,
              token: "",
              userDisplayName: "",
              userDetails: null,
            });
          } else {
            setAuth({
              ...auth,
              user: parseData.username,
              token: parseData.accessToken,
              userDisplayName: parseData.companyName,
              userDetails: parseData,
            });
          }
        } catch (error) {
          console.error("Error decoding token:", error);
          // Handle invalid token
        }
      } else {
        // Token not found, navigate to login page
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
      }
    }
    //eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider value={[auth, setAuth]}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { useAuth, AuthProvider };
