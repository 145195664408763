import React from "react";

const Dashboard = ({ fill = "#0C0C0C" }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1293_14)">
      <path
        d="M14.0391 6.5L14.0391 0.5L18.2891 0.5C19.2541 0.5 20.0391 1.285 20.0391 2.25L20.0391 6.5L14.0391 6.5ZM15.0391 1.5L15.0391 5.5L19.0391 5.5L19.0391 2.25C19.0391 1.836 18.7031 1.5 18.2891 1.5L15.0391 1.5ZM14.0391 15.5L14.0391 9.5L20.0391 9.5L20.0391 15.5L14.0391 15.5ZM15.0391 10.5L15.0391 14.5L19.0391 14.5L19.0391 10.5L15.0391 10.5ZM14.0391 24.5L14.0391 18.5L20.0391 18.5L20.0391 22.75C20.0391 23.715 19.2541 24.5 18.2891 24.5L14.0391 24.5ZM15.0391 19.5L15.0391 23.5L18.2891 23.5C18.7031 23.5 19.0391 23.164 19.0391 22.75L19.0391 19.5L15.0391 19.5ZM4.03906 6.5L4.03906 2.25C4.03906 1.285 4.82406 0.499999 5.78906 0.499999L10.0391 0.5L10.0391 6.5L4.03906 6.5ZM9.03906 1.5L5.78906 1.5C5.37506 1.5 5.03906 1.836 5.03906 2.25L5.03906 5.5L9.03906 5.5L9.03906 1.5ZM4.03906 15.5L4.03906 9.5L10.0391 9.5L10.0391 15.5L4.03906 15.5ZM5.03906 10.5L5.03906 14.5L9.03906 14.5L9.03906 10.5L5.03906 10.5ZM4.03906 22.75L4.03906 18.5L10.0391 18.5L10.0391 24.5L5.78906 24.5C4.82406 24.5 4.03906 23.715 4.03906 22.75ZM5.03906 19.5L5.03906 22.75C5.03906 23.164 5.37506 23.5 5.78906 23.5L9.03906 23.5L9.03906 19.5L5.03906 19.5Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_1293_14">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(24 0.5) rotate(90)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Dashboard;
