import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import newLogo from "../components/images/Logo.svg";
import newLogoExpand from "../components/images/logoExpand.svg";
import Dashboard from "../components/images/Dashboard.js";
import RequestForFinance from "../components/images/requestForFinance.js";
import faq from "../components/images/faq.svg";
import Onboarding from "../components/images/onboarding.js";
import Workflow from "../components/images/workflow.js";
import RequestLogImg from "../components/images/requestLog.js";
import arrowLeft from "../components/images/arrow.svg";
import logout from "../components/images/logout.svg";
import help from "../components/images/help.svg";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Home from "./Home";
import { Avatar, Menu } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../context/auth";
import Footer from "./Common/Footer";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  // marginTop: "60px",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  // marginTop: "60px",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  // zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#fff",
  // boxShadow: "0px 2px 20px rgba(1, 41, 112, 0.1)",
  boxShadow: "none",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  // marginTop: "7% !important",
  "& .MuiDrawer-paper": {
    // opacity: 5.5,
    // width: "1"
    marginTop: "7% !important",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    "& .MuiDrawer-paper": {
      width: "0px",
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Header() {
  const path = window.location.pathname;
  const theme = useTheme();
  let { role } = useParams();
  const [auth, setAuth] = useAuth();

  const setPersona = (auth) => {
    if (auth?.userDetails?.defaultPersona === "ANCHOR_TRADER") {
      return "anchorTrader";
    } else if (auth?.userDetails?.defaultPersona === "TRADE_PARTNER") {
      return "tradePartner";
    } else if (auth?.userDetails?.defaultPersona === "FINANCE_PARTNER") {
      return "financePartner";
    } else {
      return "financePartner";
    }
  };
  // console.log("11111111111 auth", auth);
  const [open, setOpen] = React.useState(true);
  const [userRole, setUserRole] = React.useState(setPersona(auth));
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [pageActiveIndex, setPageActiveIndex] = React.useState(30);
  const [hover, setHover] = React.useState(false);

  const fill = hover ? "#0000FF" : "#000000";
  React.useEffect(() => {
    var urlLast = window.location.href.split("/");
    var temp = urlLast;
    // console.log("111111111111111111111111 temp", temp);
    if (
      temp.includes("create-request") ||
      temp.includes("placed-requests") ||
      temp.includes("finance-request") ||
      temp.includes("alltrades") ||
      temp.includes("dashboardAt") ||
      temp.includes("anchortrader-profile")
    ) {
      setUserRole("anchorTrader");
    } else if (
      temp.includes("buyer-request-information") ||
      temp.includes("suppliers-list") ||
      temp.includes("tradepartner-profile") ||
      temp.includes("tradepartner-dashboard")
    ) {
      setUserRole("tradePartner");
    } else {
      setUserRole("financePartner");
    }
  }, []);

  let navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
      userDisplayName: "",
      userDetails: null,
    });
    localStorage.removeItem("auth");
    navigate("/creditbazaar/");
  };
  const sidebarItems = {
    anchorTrader: [
      {
        name: "My Profile",
        route: "/creditbazaar/anchortrader-profile",
        id: "at1",
        img: <Onboarding fill="#FF5733" />,
      },

      {
        name: "Create Request",
        route: "/creditbazaar/create-request",
        id: "at2",
        img: <RequestForFinance fill="#ffffff" />,
      },
      {
        name: "Finance Requests",
        route: "/creditbazaar/finance-request",
        id: "at3",
        img: <RequestLogImg fill="#fffff" />,
      },
      {
        name: "Trade Details",
        route: "/creditbazaar/alltrades",
        id: "at4",
        img: <Dashboard fill="#FF0000" />,
      },
      {
        name: "Analytics",
        route: "/creditbazaar/dashboardAt",
        id: "fp4",
        img: <Workflow fill={"#ffffff"} />,
        // Use an appropriate icon/image for the Reports link
      },
      // {
      //   name: "Dashboard",
      //   route: "/creditbazaar/sellersdashboard",
      //   id: "at4",
      //   img: dashboardImg,
      // },
      // {
      //   name: "Workflow",
      //   route: "/creditbazaar/workflow",
      //   id: "at5",
      //   img: workflow,
      // },
      // {
      //   name: "F.A.Q",
      //   route: "/creditbazaar/faq",
      //   id: "at6",
      //   img: faq,
      // },
    ],
    tradePartner: [
      {
        name: "My Profile",
        route: "/creditbazaar/tradepartner-profile",
        id: "tp1",
        img: <Onboarding fill="#ffffff" />,
      },
      {
        name: "Dashboard",
        route: "/creditbazaar/tradepartner-dashboard",
        id: "tp2",
        img: <Dashboard fill="#FF0000" />,
      },
      {
        name: "Vendor List",
        route: "/creditbazaar/suppliers-list",
        id: "tp3",
        img: <Onboarding fill="#ffff" />,
      },

      {
        name: "Invoice Validation",
        route: "/creditbazaar/buyer-request-information",
        id: "tp4",
        img: <RequestForFinance fill="#fffffff" />,
      },
      {
        name: "Repayment",
        route: "/creditbazaar/repayment",
        id: "tp5",
        img: <RequestLogImg fill="#fffff" />,
      },

      {
        name: "Settlement",
        route: "/creditbazaar/settlement",
        id: "tp6",
        img: <Workflow fill={"#ffffff"} />,
      },
    ],
    financePartner: [
      /*{
        name: "My Profile",
        route: "/creditbazaar/financesdashboard",
        id: "fp1",
        img: onboarding,
      },*/
      {
        name: "Open Requests",
        route: "/creditbazaar/offer-information",
        id: "fp2",
        img: <RequestForFinance fill="#fffff" />,
      },
      {
        name: "Placed Offers",
        route: "/creditbazaar/placed-offers",
        id: "fp3",
        img: <RequestLogImg fill="#fffff" />,
      },
      {
        name: "Reports",
        route: "/creditbazaar/paisabuddy-dashboard",
        id: "fp4",
        img: <Workflow fill={"#ffffff"} />,
        // Use an appropriate icon/image for the Reports link
      },

      /*  {
        name: "Dashboard",
        route: "/creditbazaar/financesdashboard",
        id: "fp4",
        img: dashboardImg,
      },
      {
        name: "Workflow",
        route: "/creditbazaar/workflow",
        id: "fp5",
        img: workflow,
      },
      {
        name: "F.A.Q",
        route: "/creditbazaar/faq",
        id: "fp6",
        img: faq,
      },*/
    ],
  };

  const pageSidebarItems = {
    anchorTrader: [
      // {
      //   name: "Support",
      //   route: "/creditbazaar/support",
      //   id: "pat1",
      //   img: help,
      // },
      {
        name: "Logout",
        // route: "/creditbazaar/faq",
        id: "pat2",
        img: logout,
      },
    ],
    tradePartner: [
      {
        name: "Support",
        route: "/creditbazaar/support",
        id: "ptp1",
        img: help,
      },
      {
        name: "Logout",
        route: "/creditbazaar/faq",
        id: "ptp2",
        img: logout,
      },
    ],
    financePartner: [
      /* {
        name: "Support",
        route: "/creditbazaar/support",
        id: "pfp1",
        img: help,
      },*/
      {
        name: "Logout",
        route: "/creditbazaar/faq",
        id: "pfp2",
        img: logout,
      },
    ],
  };

  // console.log("111111", sidebarItems[`${userRole}`]);

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      // id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );
  const renderMobileMenu = (
    <Menu
      id="fade-menu"
      MenuListProps={{
        "aria-labelledby": "fade-button",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      {/* <MenuItem
        onClick={() => {
          navigate("/creditbazaar/anchortrader-profile");
          setMobileMoreAnchorEl(null);
        }}
      >
        Profile
      </MenuItem> */}

      <MenuItem onClick={handleLogout}>Logout</MenuItem>
      {/* <MenuItem>My account</MenuItem> */}
    </Menu>
  );
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        {/* <Toolbar> */}
        <div className="container">
          <div className="item" style={{ paddingLeft: "25px" }}>
            {/* <img
              src={creditlogo}
              className="leftContent"
              style={{ width: "150px" }}
            /> */}
          </div>
          <div className="item">
            {/* <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawer}
              edge="start"
              sx={{
                //   marginRight: 5,
                marginLeft: "10%",
                //   ...(open && { display: "none" }),
                color: "#012970",
              }}
            >
              <MenuIcon />
            </IconButton>{" "} */}
          </div>
          <div className="item" />
          <div className="item" />
          <div className="item" />

          <div className="item" />

          <div className="item" />

          <div className="item" />
          <div className="item" />

          <div className="item">
            <FormControl
              sx={{
                m: 1,
                minWidth: 120,
                backgroundColor: "white",
                color: "blue",
              }}
            >
              <Select
                value={userRole}
                onChange={(e) => setUserRole(e.target.value)}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                style={{ color: "#1976d2", padding: "0px" }}
                sx={{
                  "& .MuiSelect-select": {
                    padding: "6px",
                  },
                }}
                // disabled
              >
                {auth?.userDetails?.personaIds["ANCHOR_TRADER-1"] && (
                  <MenuItem
                    value="anchorTrader"
                    onClick={() => {
                      navigate("/creditbazaar/create-request");
                      setActiveIndex(1);
                    }}
                  >
                    Anchor Trader
                  </MenuItem>
                )}
                {auth?.userDetails?.personaIds["TRADE_PARTNER-2"] && (
                  <MenuItem
                    value="tradePartner"
                    onClick={() => {
                      navigate("/creditbazaar/buyer-request-information");
                      setActiveIndex(3);
                    }}
                  >
                    Trade Partner
                  </MenuItem>
                )}
                {auth?.userDetails?.personaIds["FINANCE_PARTNER-3"] && (
                  <MenuItem
                    value="financePartner"
                    onClick={() => {
                      navigate("/creditbazaar/offer-information");
                      setActiveIndex(0);
                    }}
                  >
                    Finance Partner
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </div>

          <div style={{ display: "flex" }}>
            <div>
              <Avatar
                alt="Remy Sharp"
                // className={classes.proPic}
              />
            </div>
            <div>
              {/* <Typography className={classes.userText}>Welcome back</Typography> */}
              <div style={{ marginLeft: "5px" }}>
                <strong
                  // className={classes.userNameText}
                  style={{ color: "#012970" }}
                >
                  {auth.userDisplayName}
                </strong>
                <IconButton
                  aria-label="show more"
                  // aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                  style={{ color: "#012970" }}
                >
                  <ArrowDropDownIcon
                  // className={classes.dropDownArrow}
                  />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
        {/* </Toolbar> */}
      </AppBar>
      {renderMobileMenu}

      {renderMenu}

      <Drawer
        variant="permanent"
        open={open}
        sx={{
          "& .MuiDrawer-paper": {
            backgroundColor: "#ECF1FC",
          },
        }}
      >
        <Divider />
        <List style={{ backgroundColor: "#ECF1FC" }}>
          <ListItem
            disablePadding
            key={`logo`}
            sx={{
              display: "block",
              cursor: "default",

              // padding: "0px 16px"
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                color: "#0C0C0C",
                // padding: "0px 10px",
                // px: 2.5,
                display: "inline-flex",
                backgroundColor: "#ECF1FC",
                ":hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
                style={{ marginLeft: open ? "15px" : "0px" }}
              >
                <img src={!open ? newLogo : newLogoExpand} />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
        <List style={{ backgroundColor: "#ECF1FC" }}>
          {sidebarItems[`${userRole}`]?.map((item, index) => (
            <ListItem
              disablePadding
              key={item.id}
              sx={{
                display: "block",
                cursor: "default",
                // padding: "0px 16px"
              }}
              active={index === activeIndex}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  ":hover": {
                    backgroundColor: "transparent",
                  },
                }}
                onClick={() => {
                  navigate(`${item.route}`);
                  setActiveIndex(index);
                  setPageActiveIndex(null);
                }}
              >
                <div
                  style={{
                    display: "flex",

                    padding: open ? "5px 10px" : "0px",
                    ...(open && {
                      backgroundColor:
                        index === activeIndex ? "#FFFFFF" : "#ECF1FC",
                    }),
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      padding: !open ? "5px" : 0,
                      "&:hover": {
                        color: "#297FFF",
                        backgroundColor: "rgba(74, 58, 255, 0.10)",
                      },
                    }}
                    style={{
                      marginRight: open ? "5px" : "0px",
                      color: index === activeIndex ? "#297FFF" : "#0C0C0C",
                    }}
                  >
                    {typeof item.img === "string" ? (
                      <img src={item.img} alt={item.name} />
                    ) : (
                      React.cloneElement(item.img, { fill: "currentColor" })
                    )}
                  </ListItemIcon>

                  {open && (
                    <ListItemText
                      primary={item.name}
                      sx={{
                        opacity: open ? 1 : 0,
                        color: index === activeIndex ? "#297FFF" : "#0C0C0C",
                        borderRadius: "4px",
                        margin: "0px 5px",
                      }}
                    />
                  )}
                </div>
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <List style={{ marginTop: "auto", backgroundColor: "#ECF1FC" }}>
          {pageSidebarItems[`${userRole}`]?.map((item, index) => (
            <ListItem
              disablePadding
              key={item.id}
              sx={{
                display: "block",
                cursor: "default",
                // padding: "0px 16px"
              }}
              active={index === pageActiveIndex}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  ":hover": {
                    backgroundColor: "transparent",
                  },
                }}
                onClick={() => {
                  navigate(`${item.route}`);
                  setActiveIndex(null);
                  setPageActiveIndex(index);
                  item.name === "Logout" && handleLogout();
                }}
              >
                <div
                  style={{
                    display: "flex",

                    padding: open ? "5px 10px" : "0px",
                    ...(open && {
                      backgroundColor:
                        index === pageActiveIndex
                          ? "rgba(74, 58, 255, 0.20)"
                          : "#ECF1FC",
                    }),
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      ...(open && {
                        "& .MuiListItemIcon-root": {},
                      }),
                      ...(!open && {
                        backgroundColor:
                          index === pageActiveIndex
                            ? "rgba(74, 58, 255, 0.20)"
                            : "white",
                        padding: "5px",
                      }),
                    }}
                    style={{ marginRight: open ? "5px" : "0px" }}
                  >
                    <img src={item.img} />
                  </ListItemIcon>
                  {open && (
                    <ListItemText
                      primary={item.name}
                      sx={{
                        opacity: open ? 1 : 0,
                        color:
                          index === pageActiveIndex ? "#0C0C0C" : "#0C0C0C",
                        borderRadius: "4px",
                        margin: "0px 5px",
                        ":hover": {
                          // backgroundColor: "#f6f9ff",
                          color: "#0C0C0C",
                        },
                      }}
                    />
                  )}
                </div>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box component="main" className="box-mainHeader">
        {/* <DrawerHeader /> */}
        <Home setActiveIndex={setActiveIndex} />
        {/* <Routes /> */}
        {/* <Footer /> */}
      </Box>
    </Box>
  );
}
