import React, { useState } from "react";
import { useAuth } from "../../../context/auth";
import { controllerAPI, controllerAPICall } from "../../API/FP/Actions";
import { Navigate } from "react-router-dom";
import {
  controllerObjectForShowInfo,
  pdfControllerObj,
} from "../../Common/Constants";
import { Alert, Snackbar } from "@mui/material";

function Component3({
  nextStep,
  selectedFilesName,
  isctive,
  prevStep,
  setIsLoading,
  component1Output,
  setTradeMasterDocReader,
  setPdfUrl,
}) {
  const [s3Path, sets3Path] = useState();
  const [auth, setAuth] = useAuth();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  console.log("Hey srikar :", auth);
  const PreSignedUrl = async () => {};

  const pdfViewerObject = async (path) => {
    console.log("the path is", path);
    const stripPath = path.replace("gs://", "");
    const bucket = stripPath.split("/")[0];

    const key = stripPath.split("/").slice(1).join("/");
    try {
      const controllerObj = pdfControllerObj(key, bucket);

      const ATTradesresponse = await controllerAPI(
        "post",
        auth?.token,
        controllerObj,
        "generate-url"
      );
      console.log("Success", ATTradesresponse);
      if (ATTradesresponse?.res?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        Navigate("/creditbazaar/");
      }

      // if (ATTradesresponse.res.status === 200) {
      //   console.log("Success", ATTradesresponse);
      //   //nextStep();
      //   setIsLoading(false);
      // }

      if (ATTradesresponse.res.status === 200) {
        console.log("the pdf url is ", ATTradesresponse?.res?.data?.signed_url);
        setPdfUrl(ATTradesresponse?.res?.data?.signed_url);
      }
    } catch (error) {
      console.error("Error fetching  details:", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error in fertching !");
      setAlertSeverity("error");
    }
  };

  const TradeDocReaderShowInfo = async (path) => {
    setIsLoading(true);
    try {
      const controllerObj = controllerObjectForShowInfo(path);

      const ATTradesresponse = await controllerAPI(
        "post",
        auth?.token,
        controllerObj,
        "show-info-gcs-file"
      );
      console.log("Success", ATTradesresponse);
      if (ATTradesresponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        Navigate("/creditbazaar/");
      }

      // if (ATTradesresponse.res.status === 200) {
      //   console.log("Success", ATTradesresponse);
      //   //nextStep();
      //   setIsLoading(false);
      // }

      if (ATTradesresponse.res.status === 200) {
        console.log(
          "Success is",
          ATTradesresponse?.response?.data?.message?.msgDetails?.tradeMaster
        );
        setTradeMasterDocReader(
          ATTradesresponse?.res?.data?.message?.msgDetails?.tradeMaster
        );
        //nextStep();
        setIsLoading(false);
        nextStep();
      }
      if (ATTradesresponse?.res?.status === 400) {
      }
    } catch (error) {
      console.error("Error fetching  details:", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error in fetching details!");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="ThirdComponent"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "50px 50px 0px",
      }}
    >
      <h3
        style={{
          color: "black",
          fontSize: "18px",
          fontWeight: "600",
          lineHeight: "2",
          wordWrap: "break-word",
        }}
      >
        Review and select the files from the list below to proceed
      </h3>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          margin: "20px 0",
          fontSize: "18px",
          textAlign: "left",
          border: "2px solid #ddd", // Overall table border
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                padding: "8px",
                background: "rgba(0, 123, 255, 0.10)",
                color: "#615E83",
              }}
            >
              Filename
            </th>
            <th
              style={{
                padding: "8px",
                background: "rgba(0, 123, 255, 0.10)",
                color: "#615E83",
              }}
            >
              Filesize
            </th>
            <th
              style={{
                padding: "8px",
                background: "rgba(0, 123, 255, 0.10)",
                color: "#615E83",
              }}
            >
              Last Modified Date
            </th>
            <th
              style={{
                padding: "8px",
                background: "rgba(0, 123, 255, 0.10)",
                color: "#615E83",
              }}
            >
              Review
            </th>
          </tr>
        </thead>
        <tbody>
          {selectedFilesName.map((file, index) => (
            <tr key={index}>
              <td style={{ padding: "15px" }}>{file.fileName}</td>
              <td style={{ padding: "15px" }}>{file.fileSize}</td>
              <td style={{ padding: "15px" }}>{file.lastModified}</td>
              <td style={{ padding: "15px" }}>
                <button
                  type="button"
                  style={{
                    background: "#28A745",
                    borderRadius: "2px",
                    color: "white",
                    border: "0.5px #00000099 solid",
                    width: "80px",
                    height: "30px",
                  }}
                  onClick={() => {
                    TradeDocReaderShowInfo(component1Output + file.fileName);
                    pdfViewerObject(component1Output + file.fileName);
                  }}
                >
                  Review{" "}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div
        className="ButtonsPlacement"
        style={{
          marginTop: "60px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: " 100%",
        }}
      >
        <button
          className="Backbtn"
          style={{
            color: "black",
            height: "45px",
            width: "120px",
            backgroundColor: "white",
            border: "1px solid",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px 36px",
            borderRadius: "3px",
            cursor: "pointer ",
            alignSelf: "flexStart",
          }}
          onClick={() => prevStep()}
        >
          Back
        </button>
      </div>
      <Snackbar
        open={openSnackbar}
        //autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {apiResultMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Component3;
