import React, { useCallback, useEffect, useState } from "react";
import { getplacedoffers } from "../API/FP/Actions";
import { styled } from "styled-components";
import Footer from "../Common/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumbs, CircularProgress, Link, Typography } from "@mui/material";
import { controllerAPI, callAPI } from "../API/FP/Actions";
import { dateFormat, requestStates } from "../Common/Constants";
import { NumericFormat } from "react-number-format";
import { useAuth } from "../../context/auth";
import {
  contextObjforatplaced,
  contextObjforapicall,
  contextObj,
} from "../Common/Constants";
import { PlacedRequestsMockData } from "../MockData/AnchorTraderMock";

function PlacedRequests() {
  const [apidata, getapidata] = useState([]);
  const location = useLocation();
  const { state } = location;
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const requestData = state?.requestData;
  const [status, setStatus] = useState("");
  const token = state?.authToken;
  const [data, setData] = useState([]);
  const [authToken, setAuthToken] = useState("");
  const [auth, setAuth] = useAuth();
  const [anchorTraderID, setAnchorTraderID] = useState(
    auth?.userDetails?.personaIds["ANCHOR_TRADER-1"]
  );
  const [requestdata, setrequestdata] = useState(null);
  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        setLoading(true); // Set loading to true when fetching data starts
        const constructObj = contextObjforapicall(
          authContextObj,
          " 01HRRS01QJ2QTGXCJCT1QHABBV" // finace req ulid id
        );
        const placedOffersResponse = await controllerAPI(
          "post",
          auth?.token,
          constructObj,
          "get-at-placed-offers"
        );

        if (placedOffersResponse?.response?.status === 401) {
          setAuth({
            ...auth,
            user: null,
            token: "",
            userDisplayName: "",
            userDetails: null,
          });
          localStorage.removeItem("auth");
          navigate("/creditbazaar/");
        }

        if (placedOffersResponse.res.status === 200) {
          setData(placedOffersResponse.res.data.data);
        }
      } catch (err) {
        console.log("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [requestData, auth?.token, state]);

  console.log("about the status of atulid id ");

  const creditScoreColor = (score) => {
    return score > 7 ? "#00692A" : "#D20319";
  };

  // Function to execute when link is clicked
  const handleLinkClick = (requestId, fid) => {
    console.log("Link clicked for request ID:", requestId);
    console.log("Status:", fid); // Log the status
    navigate(`/creditbazaar/placed-requests/${requestId}`, { state: { fid } });
  };

  return (
    <>
      <Finance1>
        <div>
          <div
            role="presentation"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              color: "#4A3AFF",
              marginTop: "50px",
            }}
          >
            <div className="header">
              <h3 style={{ margin: "0px" }} className="section-heading">
                Placed Offers
              </h3>
            </div>
          </div>
        </div>
      </Finance1>

      <Dash className="body-text">
        <div className="main-content">
          <div className="record-details">
            <table>
              <tbody>
                <tr>
                  <th>Request ID</th>
                  <th>Placed Offer Date</th>
                  <th>Trade Value (INR)</th>
                  <th>Trade Partner Name</th>
                  <th style={{ textAlign: "start" }}>Status</th>
                </tr>

                {loading ? (
                  <tr>
                    <td colSpan="5">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress
                          style={{
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ) : (
                  data?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <Link
                          to={{
                            pathname: `/creditbazaar/placed-requests/${item.requestOfferRefNo}`,
                            state: {
                              status: item.financerequest.financeRequestId,
                            },
                          }}
                          onClick={() =>
                            handleLinkClick(
                              item.requestOfferRefNo,
                              item.financerequest.financeRequestId
                            )
                          }
                        >
                          {item.requestOfferRefNo}
                        </Link>
                      </td>
                      <td style={{ cursor: "pointer" }}>
                        {item.placedOfferDate}
                      </td>
                      <td>
                        <NumericFormat
                          displayType="text"
                          value={item.value}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                        />
                      </td>
                      <td>{item.tradePartner}</td>
                      <td>
                        <span
                          style={{
                            padding: "2px 15px",
                            backgroundColor:
                              item?.status === "PENDING_DISBURSEMENT"
                                ? "rgb(255, 202, 190)"
                                : "rgb(216, 247, 230)",
                            color:
                              item.status === "PENDING_DISBURSEMENT"
                                ? "rgb(255, 77, 0)"
                                : "rgb(0, 105, 42) ",
                            borderRadius: "100px",
                            display: "inline-block",
                          }}
                        >
                          {item?.atStatusDescCode}
                        </span>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Dash>
      <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div>
    </>
  );
}

export default PlacedRequests;

const Finance1 = styled.div`
  .custom-link:hover {
    color: #4a3aff;
  }
  .btn {
    color: white;
    background-color: #007bff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-left: 20px;
    font-size: 20px;
    // margin-top: 20vh;
  }
  .btn1 {
    color: white;
    background-color: orange;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 20px;
    border-radius: 5px;
    display: inline-block;
    margin-left: 20px;
  }
  .btn:hover {
    background-color: #0056b3;
  }

  .content {
    margin-left: 20px;
    margin-top: 20px;
    display: flex;
  }
  .offer-card {
    border: 2px solid black;
    // width: 170%;
    margin-bottom: 20px;
  }
  .lender-details {
    margin-left: 40px;
  }

  .header {
    font-size: 18px;
    margin-top: 15px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    // width: 300%;
    border-collapse: collapse;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
  }
  th {
    background-color: #f8f9fa;
    font-weight: bold;
  }
  .search-bar {
    display: flex;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .search-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #dddddd;
    border-radius: 4px 0 0 4px;
    font-size: 14px;
    align-items: center;
  }
  .search-button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 8px;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .search-button:hover {
    background-color: #0056b3;
  }
  .popup {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  .popup .popuptext {
    width: 700px;
    visibility: hidden;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    left: 50%;
    margin-left: -80px;
    overflow-y: auto;
    border-width: 3px;
    border-style: solid;
    border-color: #000;
  }
  .popup .popuptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  .popup .show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  }
  .popup .popuptext .btn {
    visibility: hidden;
  }
  .btn5 {
    color: white;
    background-color: #007bff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-left: 65vh;
    margin-bottom: 15px;
  }
  .btn5:hover {
    background-color: #0056b3;
  }
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Dash = styled.div`
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }

  .content {
    display: flex;
    margin-top: 20px;
  }

  .main-content {
    display: flex;
    padding: 20px 0px 10px 0px;
    flex-direction: column;
  }
  .table-container {
    margin-top: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    // border-left: 1px solid rgba(0, 0, 128, 0.2);
    // border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 5px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    background: rgba(74, 58, 255, 0.1);
  }
  .record-details {
  }
`;
