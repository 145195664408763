import React, { useState } from "react";
import styled from "styled-components";
import {
  Alert,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
} from "@mui/material";
import { useAuth } from "../../../context/auth";
import {
  contextObjBeneValidation,
  contextObjforNextWizardSettlement,
  contextObjFundsTransferSettlement,
  objectforBeneval,
  PrepareParticipantSettlement,
  PreparetriggerSettlementBeneValidation,
  PreparetriggerSettlementFundsTransfer,
} from "../../Common/Constants";
import { controllerAPI, opscontroller } from "../../API/FP/Actions";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";

const Step4 = ({
  step2output,
  selectedRecordDetails,
  nextStep,
  handleChange,
  formData,
  setstep4output,

  wizardRecordId,
  wizardRecordInstanceId,
  setWizardRecordInstanceId,
  wizardPageId,
  setWizardPageId,
  actionButtonId,
  setActionButtonId,
  NextButtonId,
  setNextButtonId,
}) => {
  const handleInputChange = (e) => {
    handleChange(e.target.name, e.target.value);
  };

  const [btnenable, setbtnenable] = useState(false);
  const [auth, setAuth] = useAuth();
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [showdata, setshowdata] = useState(false);
  const [data, setdata] = useState([]);
  const navigate = useNavigate();
  const [wizardRecordInstanceIdStep1, setwizardRecordInstanceIdStep1] =
    useState(wizardRecordInstanceId);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };

  const NextButton = async (id) => {
    setLoading(true);
    try {
      setLoading(true);
      const constructObj = contextObjforNextWizardSettlement(
        authContextObj,
        wizardRecordId,
        wizardRecordInstanceIdStep1,
        wizardPageId,
        NextButtonId
      );
      // Updated API call with pagination parameters
      const ATTradesresponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        `settlement-wizard`
      );

      if (ATTradesresponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        Navigate("/creditbazaar/");
      }

      if (ATTradesresponse.res.status === 200) {
        console.log("sucess");
        setNextButtonId(NextButtonId + 1);
        setWizardRecordInstanceId(
          ATTradesresponse?.res?.data?.data?.executeSettlementWizardStepData?.id
        );

        setWizardPageId(wizardPageId + 1);
        nextStep();
      }
      if (ATTradesresponse?.response?.status === 400) {
        console.log(
          "response?.response?.data?.error",
          ATTradesresponse?.response?.data?.erro
        );
        setOpenSnackbar(true);
        setApiResultMessage(ATTradesresponse?.response?.data?.error);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error fetching  details:", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error in Reconcile Disbursment!");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const ActionButtonResponse = async (fundTransferS3Path) => {
    setLoading(true);
    try {
      setLoading(true);
      const constructObj = contextObjFundsTransferSettlement(
        authContextObj,
        wizardRecordId,
        wizardRecordInstanceId,
        wizardPageId,
        actionButtonId,
        fundTransferS3Path
      );
      // Updated API call with pagination parameters
      const ATTradesresponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        `settlement-wizard`
      );

      if (ATTradesresponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        navigate("/creditbazaar/");
      }

      if (ATTradesresponse.res.status === 200) {
        console.log("sucess");
        setActionButtonId(actionButtonId + 1);
        setwizardRecordInstanceIdStep1(
          ATTradesresponse?.res?.data?.data?.executeSettlementWizardStepData?.id
        );
        setOpenSnackbar(true);
        setApiResultMessage("Funds Transfer done successfully ");
        setAlertSeverity("success");
        setbtnenable(true);
        setshowdata(true);
        try {
          const parseMsg = JSON.parse(
            ATTradesresponse?.res?.data?.data?.executeSettlementWizardStepData
              ?.apiResponse
          );
          const parsTwo = JSON.parse(parseMsg);
          console.log("api response data is:", parsTwo);
          setstep4output(parsTwo?.data);
          setdata(parsTwo?.data);
          console.log("data being set is :", parsTwo?.data);
        } catch (error) {
          console.error("Failed to parse API response:", error);
        }
      }
      if (ATTradesresponse?.response?.status === 400) {
        console.log(
          "response?.response?.data?.error",
          ATTradesresponse?.response?.data?.erro
        );
        setOpenSnackbar(true);
        setApiResultMessage(ATTradesresponse?.response?.data?.error);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error fetching  details:", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error in Funds Transfer!");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const triggerSettlementFundsTransfer = async (fundTransferS3Path) => {
    const constructObj =
      PreparetriggerSettlementFundsTransfer(fundTransferS3Path);
    try {
      setLoading(true);
      const response = await opscontroller(
        "post",
        auth?.token,
        constructObj,
        "trigger-funds-transfer"
      );
      if (response?.response?.status === 401) {
        localStorage.removeItem("auth");
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        navigate("/creditbazaar/");
      }
      if (response?.res?.status === 200) {
        setOpenSnackbar(true);
        setApiResultMessage("Funds Transfer done successfully ");
        setAlertSeverity("success");
        setstep4output(response?.res?.data?.data);
        setdata(response?.res?.data?.data);
        setbtnenable(true);
        setshowdata(true);
      }
      if (response?.response?.status === 400) {
        console.log(
          "response?.response?.data?.error",
          response?.response?.data?.erro
        );
        setOpenSnackbar(true);
        setApiResultMessage(response?.response?.data?.error);
        setAlertSeverity("error");
      }
      if (response?.response?.status === 500) {
        setOpenSnackbar(true);
        setApiResultMessage(response?.response?.data?.error);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error fetching  details:", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error in Bene Validation!");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  console.log("fundTransferS3Path is:", step2output?.fundTransferS3Path);
  console.log(
    "output data :",
    data?.fundTransferEscrowUtilityAPIResponseData?.data
      ?.fundTransferEscrowResponse?.data?.initiateAuthGenericFundTransferAPIResp
      ?.resourceData
  );
  return (
    <StepContainer>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="step-content">
          <h2>STEP 4: Trigger Actual Funds Transfer</h2>
          <h3> To trigger Funds Transfer Click to Proceed</h3>
          <p>
            Please Click the Below Link to view Creditor Bank Account Details:{" "}
            <a
              href={step2output?.fundTransferS3Path}
              target="_blank"
              style={{ fontWeight: "bold" }}
            >
              {step2output?.fundTransferS3Path}
            </a>
            <iframe
              style={{ marginTop: "20px" }}
              src={step2output?.fundTransferS3Path}
              width="700px"
              height="200px"
            />
          </p>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "400px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                disabled={btnenable}
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#4A3AFF",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                  height: "fit-content",
                  boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                  margin: "5px",
                  opacity: !btnenable ? "1" : "0.5",
                }}
                onClick={() => {
                  ActionButtonResponse(step2output?.fundTransferS3Path);
                }}
              >
                Proceed
              </Button>
              {showdata === true ? (
                <>
                  <p>
                    FundTransfer Successful
                    <br />
                    <br />
                    Transaction ReferenceNo:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {
                        data?.fundTransferEscrowUtilityAPIResponseData?.data
                          ?.fundTransferEscrowResponse?.data
                          ?.initiateAuthGenericFundTransferAPIResp?.resourceData
                          ?.transactionReferenceNo
                      }{" "}
                    </span>
                  </p>

                  <p>
                    FundTransfer done Successfully for :{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {
                        data?.fundTransferEscrowUtilityAPIResponseData?.data
                          ?.ftTrnxDetails?.beneficiaryName
                      }{" "}
                    </span>
                    <br />
                    Credit Account Number:{"  "}
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      {
                        data?.fundTransferEscrowUtilityAPIResponseData?.data
                          ?.ftTrnxDetails?.creditAccountNumber
                      }
                    </span>
                  </p>
                </>
              ) : (
                <>
                  <p></p>
                </>
              )}
            </div>
          )}

          <Snackbar
            open={openSnackbar}
            //autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={alertSeverity}
              sx={{ width: "100%" }}
            >
              {apiResultMessage}
            </Alert>
          </Snackbar>
        </div>
        <button
          style={{
            borderRadius: "10px",
            backgroundColor: "#4A3AFF",
            color: "white",
            border: "none",
            cursor: "pointer",
            height: "fit-content",
            boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
            margin: "5px",
            opacity: btnenable ? "1" : "0.5",
            padding: "15px 30px",
            fontSize: "15px",
          }}
          onClick={NextButton}
          className="button"
          disabled={!btnenable}
        >
          Next
        </button>
      </div>
    </StepContainer>
  );
};

export default Step4;

const StepContainer = styled.div`
  .step-content {
    max-width: 600px;
    margin: auto;
  }

  .button {
    padding: 10px 20px;
    margin: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }

  .button:hover {
    background-color: #0056b3;
  }
`;
