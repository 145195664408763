import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";

const Logout = () => {
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const handleNavigate = (res) => {
    const roles = res?.userDetails?.roles;
    //setRole(res?.data?.defaultPersona);
    if (res?.userDetails?.defaultPersona === "ANCHOR_TRADER") {
      navigate("/creditbazaar/anchortrader-profile");
    } else if (res?.userDetails?.defaultPersona === "TRADE_PARTNER") {
      navigate("/creditbazaar/buyer-request-information");
    } else if (res?.userDetails?.defaultPersona === "FINANCE_PARTNER") {
      navigate("/creditbazaar/offer-information");
    } else if (res?.userDetails?.defaultPersona === "PFT") {
      navigate("/creditbazaar/AcceptedOffer");
    } else {
      navigate("/creditbazaar/offer-information");
    }
  };
  useEffect(() => {
    //navigate("/creditbazaar/");
    handleNavigate(auth);
  }, [auth, setAuth, navigate]);

  return null; // No UI for the logout component
};

export default Logout;
