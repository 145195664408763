import React, { useState } from "react";
import { styled } from "styled-components";
import Footer from "../Common/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumbs, Divider, Link, Typography } from "@mui/material";
import arrowLeft from "../../components/images/arrow.svg";
import RequestDetails from "./RequestLogDetails/RequestDetails";
import RequestOffer from "./RequestLogDetails/RequestOffer";
import RequestDisbursement from "./RequestLogDetails/RequestDisbursement";
import RequestRepayment from "./RequestLogDetails/RequestRepayment";
import RequestSettlement from "./RequestLogDetails/RequestSettlement";

function TradePartnerRequestLogIndividual({
  creditScoreReportCheck,
  setCreditScoreReportCheck,
}) {
  const { state } = useLocation();
  let navigate = useNavigate();
  const [details, setDetails] = useState(true);
  const [offer, setOffer] = useState(false);
  const [disbursement, setDisbursement] = useState(false);
  const [repayment, setRepayment] = useState(false);
  const [settlement, setSettlement] = useState(false);

  let tableHeaderNames = [
    "Date",
    "Invoice Number",
    "Trade Partner Name",
    "Trade Amount (INR)",
    "Term (Days)",
  ];
  let tableData = [
    {
      date: "Nov 18, 2023",
      invoiceNumber: "INV569829",
      tradePartnerName: "Apollo Hospitals",
      tradeAmount: "4,50,000",
      term: 80,
    },
    {
      date: "Nov 19, 2023",
      invoiceNumber: "INV403441",
      tradePartnerName: "Renova Hospitals",
      tradeAmount: "3,00,000",
      term: 70,
    },
    {
      date: "Nov 19, 2023",
      invoiceNumber: "INV457608",
      tradePartnerName: "Renova Hospitals",
      tradeAmount: "3,50,000",
      term: 70,
    },
  ];

  return (
    <>
      <div>
        <Finance1>
          <div>
            <div
              role="presentation"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                color: "#4A3AFF",
                marginTop: "50px",
              }}
            >
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  color="rgba(74, 58, 255, 0.5)"
                  //   href="/financesdashboard"
                  onClick={() => {
                    navigate("/creditbazaar/buyer-dashboard");
                  }}
                  className="custom-link"
                >
                  Dashboard
                </Link>
                <Link
                  underline="hover"
                  color="rgba(74, 58, 255, 0.5)"
                  //   href="/request_log"
                  onClick={() => {
                    navigate(
                      "/creditbazaar/buyer-request-information/request_log"
                    );
                  }}
                  className="custom-link"
                >
                  Request logs
                </Link>
                <Typography color="#4A3AFF">{state?.id ?? ""}</Typography>
              </Breadcrumbs>
              <div className="header">
                <h3 style={{ margin: "0px" }}>Details</h3>
                <Typography>
                  Below is the information of the Finance request
                </Typography>
              </div>
            </div>

            <div style={{ marginTop: "15px" }}>
              <button
                className="report-button"
                onClick={() => {
                  setDetails(true);
                  setOffer(false);
                  setDisbursement(false);
                  setRepayment(false);
                  setSettlement(false);
                }}
                style={{
                  backgroundColor: details ? "#4a3aff" : "white",
                  color: details ? "white" : "#4a3aff",
                }}
              >
                Details
              </button>
              <button
                className="report-button"
                onClick={() => {
                  setDetails(false);
                  setOffer(true);
                  setDisbursement(false);
                  setRepayment(false);
                  setSettlement(false);
                }}
                style={{
                  backgroundColor: offer ? "#4a3aff" : "white",
                  color: offer ? "white" : "#4a3aff",
                }}
              >
                Offer
              </button>
              <button
                className="report-button"
                onClick={() => {
                  setDetails(false);
                  setOffer(false);
                  setDisbursement(true);
                  setRepayment(false);
                  setSettlement(false);
                }}
                style={{
                  backgroundColor: disbursement ? "#4a3aff" : "white",
                  color: disbursement ? "white" : "#4a3aff",
                }}
              >
                Disbursement
              </button>
              <button
                className="report-button"
                onClick={() => {
                  setDetails(false);
                  setOffer(false);
                  setDisbursement(false);
                  setRepayment(true);
                  setSettlement(false);
                }}
                style={{
                  backgroundColor: repayment ? "#4a3aff" : "white",
                  color: repayment ? "white" : "#4a3aff",
                }}
              >
                Repayment
              </button>
              <button
                className="report-button"
                onClick={() => {
                  setDetails(false);
                  setOffer(false);
                  setDisbursement(false);
                  setRepayment(false);
                  setSettlement(true);
                }}
                style={{
                  backgroundColor: settlement ? "#4a3aff" : "white",
                  color: settlement ? "white" : "#4a3aff",
                }}
              >
                Settlement
              </button>
            </div>
          </div>
        </Finance1>
        <RequestInformation>
          {details && <RequestDetails />}
          {offer && <RequestOffer />}
          {disbursement && <RequestDisbursement />}
          {repayment && <RequestRepayment />}
          {settlement && <RequestSettlement />}
        </RequestInformation>
      </div>
      {/* <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div> */}
    </>
  );
}

export default TradePartnerRequestLogIndividual;

const Finance1 = styled.div`
  .header {
    font-size: 18px;
    margin-top: 15px;
    // margin-left: 10px;
    // display: flex;
  }
  .report-button {
    border-radius: 2px;
    background-color: white;
    color: #4a3aff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    height: fit-content;
    margin-right: 25px;
    border: 1px solid rgba(0, 0, 128, 0.2);
  }
`;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 5px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    // width: 55%;
  }
  .main-content {
    margin-top: 30px;
  }
`;
