import React, { useState } from "react";
import styled from "styled-components";
import {
  Button,
  TextField,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import {
  contextObjforapicallNextBtnWizard,
  contextObjforapicallStartWizard,
  objectforOPSReconciledisb,
  objectforOPSsetdisb,
} from "../../Common/Constants";
import { controllerAPI, opscontroller } from "../../API/FP/Actions";
import { useAuth } from "../../../context/auth";
import { useNavigate } from "react-router-dom";

const Step1 = ({
  dataFromAcceptedOffers,
  nextStep,
  handleChange,
  formData,
  setstep1output,
  setshowWiz,
  wizardId,
  setWizardPageId,
  wizardPageId,
  setNextButtonId,
  nextButtonId,
  wizardStepInstanceIdWizard,
  setWizardStepInstanceIdWizard,
}) => {
  const navigate = useNavigate();
  const [btnenable, setbtnenable] = useState(true);
  const [auth, setAuth] = useAuth();
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [data, setTableData] = useState([]);
  // const ReconcileDisb = async (id) => {
  //   const constructObj = objectforOPSReconciledisb(id);
  //   try {
  //     setLoading(true);
  //     const response = await opscontroller(
  //       "post",
  //       auth?.token,
  //       constructObj,
  //       "reconcile-disbursement"
  //     );
  //     if (response?.response?.status === 401) {
  //       localStorage.removeItem("auth");
  //       setAuth({
  //         ...auth,
  //         user: null,
  //         token: "",
  //         userDisplayName: "",
  //         userDetails: null,
  //       });
  //       navigate("/creditbazaar/");
  //     }
  //     if (response?.res?.status === 200) {
  //       console.log("sucessfull for the reconcile disbursment ");
  //       setOpenSnackbar(true);
  //       setApiResultMessage("Reconcile Disbursment is Successful !");
  //       setAlertSeverity("success");
  //       setstep1output(response?.res?.data?.data);
  //       setTableData(response?.res?.data?.data);
  //       setbtnenable(true);
  //     }
  //     if (response?.response?.status === 400) {
  //       console.log(
  //         "response?.response?.data?.error",
  //         response?.response?.data?.erro
  //       );
  //       setOpenSnackbar(true);
  //       setApiResultMessage(response?.response?.data?.error);
  //       setAlertSeverity("error");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching  details:", error);
  //     setOpenSnackbar(true);
  //     setApiResultMessage("Error in Reconcile Disbursment!");
  //     setAlertSeverity("error");
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };

  const nextStepWizardApi = async () => {
    setLoading(true);
    try {
      setLoading(true);
      const constructObj = contextObjforapicallNextBtnWizard(
        authContextObj,
        wizardId,
        wizardStepInstanceIdWizard,
        wizardPageId,
        nextButtonId
      );
      // Updated API call with pagination parameters
      const ATTradesresponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        `disbursement-wizard`
      );

      if (ATTradesresponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        navigate("/creditbazaar/");
      }

      if (ATTradesresponse.res.status === 200) {
        console.log("sucess");
        setWizardPageId(wizardPageId + 1);
        setNextButtonId(nextButtonId + 1);
        setWizardStepInstanceIdWizard(
          ATTradesresponse?.res?.data?.data?.setDisbursementWizardStepData?.id
        );
        nextStep();
      }
    } catch (err) {
      console.log("Error fetching data:", err);
    } finally {
      setLoading(false);
    }
  };

  console.log();
  // const nextClickedWizard = async () => {
  //   nextStepWizardApi();
  // };

  return (
    <StepContainer>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <button
            style={{
              margin: "10px",
              color: "black",
              height: "45px",
              width: "130px",
              backgroundColor: "white",
              border: " 1px #404040 solid",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px 36px",
              borderRadius: "2px",
              cursor: "pointer",
              alignSelf: "flexStart",
            }}
            onClick={() => {
              setshowWiz(false);
            }}
            className="button"
            disabled={!btnenable}
          >
            ← Back
          </button>
          <div className="step-content">
            <h2>STEP 1 :Accepted Offers Details</h2>
            <h4>
              Cross-check the details below to perform the set Disbursement:
              {dataFromAcceptedOffers.id}
            </h4>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
                padding: "20px",
              }}
            >
              {
                <React.Fragment>
                  <div style={{ padding: "8px" }}>
                    <label style={{ fontWeight: "bold" }}>ID:</label>
                  </div>
                  <div style={{ padding: "8px" }}>
                    <span>{dataFromAcceptedOffers.id}</span>
                  </div>

                  <div style={{ padding: "8px" }}>
                    <label style={{ fontWeight: "bold" }}>
                      Accepted Offer Ulid Id:
                    </label>
                  </div>
                  <div style={{ padding: "8px" }}>
                    <span>{dataFromAcceptedOffers.acceptedOfferUlidId}</span>
                  </div>

                  <div style={{ padding: "8px" }}>
                    <label style={{ fontWeight: "bold" }}>
                      Accepted Offer Ref No:
                    </label>
                  </div>
                  <div style={{ padding: "8px" }}>
                    <span>{dataFromAcceptedOffers.acceptedOfferRefNo}</span>
                  </div>

                  <div style={{ padding: "8px" }}>
                    <label style={{ fontWeight: "bold" }}>Interest Ptg:</label>
                  </div>
                  <div style={{ padding: "8px" }}>
                    <span>{dataFromAcceptedOffers.interestPtg}</span>
                  </div>

                  <div style={{ padding: "8px" }}>
                    <label style={{ fontWeight: "bold" }}>Term:</label>
                  </div>
                  <div style={{ padding: "8px" }}>
                    <span>{dataFromAcceptedOffers.term}</span>
                  </div>

                  <div style={{ padding: "8px" }}>
                    <label style={{ fontWeight: "bold" }}>Net Amount:</label>
                  </div>
                  <div style={{ padding: "8px" }}>
                    <span>{dataFromAcceptedOffers.netAmount}</span>
                  </div>
                  <div style={{ padding: "8px" }}>
                    <label style={{ fontWeight: "bold" }}>Offer Date:</label>
                  </div>
                  <div style={{ padding: "8px" }}>
                    <span>{dataFromAcceptedOffers.offerDate}</span>
                  </div>

                  <div style={{ padding: "8px" }}>
                    <label style={{ fontWeight: "bold" }}>
                      Offer Accepted Date:
                    </label>
                  </div>
                  <div style={{ padding: "8px" }}>
                    <span>{dataFromAcceptedOffers.offerAcceptedDate}</span>
                  </div>

                  <div style={{ padding: "8px" }}>
                    <label style={{ fontWeight: "bold" }}>Anchor Trader:</label>
                  </div>
                  <div style={{ padding: "8px" }}>
                    <span>{dataFromAcceptedOffers.anchortrader?.id}</span>
                  </div>

                  <div style={{ padding: "8px" }}>
                    <label style={{ fontWeight: "bold" }}>
                      Finance Partner:
                    </label>
                  </div>
                  <div style={{ padding: "8px" }}>
                    <span>{dataFromAcceptedOffers.financepartner?.id}</span>
                  </div>
                </React.Fragment>
              }
            </div>

            <Snackbar
              open={openSnackbar}
              //autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity={alertSeverity}
                sx={{ width: "100%" }}
              >
                {apiResultMessage}
              </Alert>
            </Snackbar>
          </div>

          <button
            style={{
              margin: "10px",
              color: "black",
              height: "45px",
              width: "130px",
              backgroundColor: "white",
              border: " 1px #404040 solid",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px 36px",
              borderRadius: "2px",
              cursor: "pointer",
              alignSelf: "flexStart",
            }}
            onClick={nextStepWizardApi}
            className="button"
            disabled={!btnenable}
          >
            Next →
          </button>
        </div>
      )}
    </StepContainer>
  );
};

export default Step1;

const StepContainer = styled.div`
  .step-content {
    max-width: 600px;
    margin: auto;
  }
  table {
    // width: 300%;
    margin: 10px;
    border-collapse: collapse;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
  }
  tr {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
  }
  th {
    background-color: #f8f9fa;
    font-weight: bold;
  }
  .button {
    padding: 10px 20px;
    margin: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }

  .button:hover {
    background-color: #0056b3;
  }
`;
