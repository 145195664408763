import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ProfileGSTOTP from "./ProfileGSTOTP";
// import { getGstDetails, getOTP, getToken } from "../Actions";
import {
  Alert,
  Button,
  FormControl,
  NativeSelect,
  Snackbar,
  TextField,
} from "@mui/material";
import InputLabel, { inputLabelClasses } from "@mui/material/InputLabel";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth } from "../../../context/auth";
import { controllerAPI } from "../../API/FP/Actions";
import {
  gstCompanyDetailsContextObjController,
  gstCompanyGenerateOTPContextObjController,
} from "../../Common/Constants";
import VERFIED from "../../images/VERIFIED.png";

function ProfileGST(gstinList) {
  const [role, setRole] = useState("seller");
  const [auth, setAuth] = useAuth();
  const [accessTokenAPI, setAccessTokenAPI] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorAPi, setErrorApi] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: "1",
  };
  const [gstinListt, setgstinList] = useState([]);
  const [gstID, setGstID] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const navigate = useNavigate();
  const [gstId, setGstId] = useState("");
  const [gstidverified, setgstidverified] = useState(false);
  const [username, setUsername] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [otpNotGenerated, setOtpNotGenerated] = useState(false);
  const [allValues, setAllValues] = useState({
    legalName: "",
    tradeName: "",
    constitutionOfBusiness: "",
    administrativeOffice: "",
  });

  useEffect(() => {
    setgstinList(gstinList?.gstinList);
    setGstNumber(gstinList?.gstinList[0]?.gstId);
    setGstID(gstinList?.gstinList[0]?.id);
    console.log();
    if (gstId === "12345") {
      // debugger;
      setAllValues({
        legalName: "Prime Textiles",
        tradeName: "Prime Textiles",
        constitutionOfBusiness: "Partnership",
        administrativeOffice: "Administrative Office Address",
      });
    } else {
      setAllValues({
        username: "",
        legalName: "",
        tradeName: "",
        constitutionOfBusiness: "",
        administrativeOffice: "",
      });
    }
  }, [gstId, gstinList]);

  const handleButtonClick = () => {
    setIsButtonDisabled(true);
  };
  const fetchUserData = async () => {
    setIsLoading(true);
    const constructObj = gstCompanyDetailsContextObjController(
      authContextObj,
      gstNumber
    );
    const response = await controllerAPI(
      "post",
      auth?.token,
      constructObj,
      "fetch-company-details"
    );
    try {
      if (response.res.status === 200) {
        const rData = response.res.data.data;
        setIsLoading(false);
        setAllValues({
          ...allValues,
          legalName: rData.company?.lgnm,
          tradeName: rData.company?.tradenam,
          constitutionOfBusiness: rData.company?.ctb,
          administrativeOffice: rData.company?.ctj,
        });
        setgstidverified(true);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorApi(true);
      console.log("Error", err);
    }
  };
  const handleDropDownChange = (event) => {
    setGstNumber(event.target.value);
    setGstID(event.target.value);
  };
  console.log("The gstinListt is :", gstinListt);
  console.log("gstNumber to send is ", gstNumber);
  console.log("gstID is:", gstID);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const constructObj = gstCompanyGenerateOTPContextObjController(
      authContextObj,
      gstNumber,
      username
    );
    const response = await controllerAPI(
      "post",
      auth?.token,
      constructObj,
      "generate-gst-otp"
    );
    try {
      console.log(response);
      if (response.res.status === 200) {
        setAccessTokenAPI(response.res.data.data.access_token);
        response.res.data?.data?.data?.error
          ? setOtpNotGenerated(true)
          : setShowOTP(true);
        // setShowOTP(true);
      }
    } catch (err) {
      setIsLoading(false);

      console.log("Error", err);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorApi(false);
  };
  const gstids = [
    "36AAIFP3688H1ZS",
    "36AAIFP3688H1ZP",
    "36AAIFP3688H1ZK",
    "36AAIFP3688H1ZM",
  ];
  return (
    <div className="main-container">
      <div className="mainDiv">
        <div>
          {isLoading && (
            <CircularProgress
              style={{ position: "absolute", left: "50%", top: "50%" }}
            />
          )}
          {/* 
          <h2>Get API Access Consent</h2>
          <h4>
            {!showOTP
              ? "Provide your GST Details"
              : "Enter the OTP sent to your registered mobile number"}
          </h4> */}
        </div>
        {!showOTP && (
          <div>
            <form className="form" onSubmit={handleSubmit}>
              <div className="main-signinGST">
                <div className="formwrap">
                  <div>
                    <div style={{ display: "flex" }}>
                      {/*<TextField
                        id="gstId"
                        inputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{
                          sx: {
                            color: "#4A3AFF",
                            fontWeight: "bold",
                            [`&.${inputLabelClasses.shrink}`]: {
                              color: "#4A3AFF",
                            },
                          },
                        }}
                        label="GST ID"
                        value={gstId}
                        onChange={(e) => {
                          setGstId(e.target.value);
                          setgstidverified(false);
                        }}
                        variant="standard"
                      />*/}
                      <FormControl fullWidth>
                        <InputLabel
                          variant="standard"
                          htmlFor="uncontrolled-native"
                        >
                          GST ID
                        </InputLabel>
                        <NativeSelect
                          inputProps={{
                            name: "GSTID",
                            id: "uncontrolled-native",
                          }}
                          value={gstID}
                          onChange={handleDropDownChange}
                        >
                          {gstinListt?.map((item, index) => (
                            <option key={index} value={item?.gstId}>
                              {item?.gstId}
                            </option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                      {gstidverified === true ? (
                        <img
                          src={VERFIED}
                          alt="Verified"
                          style={{
                            marginLeft: "20px",
                            marginRight: "50px",
                            height: "fit-content",
                            borderRadius: "3px",
                            height: "60px",
                            alignSelf: "start",
                            transform: "scale(3.5)", // Adjust the scale value as needed to zoom in more or less
                            transformOrigin: "center", // Ensures the zoom is centered
                            paddingTop: "10px",
                          }}
                        />
                      ) : (
                        <Button
                          style={{
                            padding: "2px 10px",
                            height: "fit-content",
                            color: "white",
                            backgroundColor: "#4A3AFF",
                            border: "none",
                            marginLeft: "8px",
                            borderRadius: "3px",
                            textTransform: "none",
                            alignSelf: "end",
                          }}
                          onClick={() => fetchUserData()}
                        >
                          Validate GST
                        </Button>
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      paddingBottom: "10px",
                    }}
                  >
                    <TextField
                      id="legalname"
                      inputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{
                        sx: {
                          color: "#4A3AFF",
                          fontWeight: "bold",
                          [`&.${inputLabelClasses.shrink}`]: {
                            color: "#4A3AFF",
                          },
                        },
                      }}
                      label="Legal Name of Business"
                      value={allValues.legalName}
                      // onChange={(e) => setPassword(e.target.value)}
                      style={{ width: "100%" }}
                      variant="standard"
                      disabled
                    />
                  </div>
                  <div
                    style={{
                      paddingBottom: "10px",
                    }}
                  >
                    <TextField
                      id="tradename"
                      inputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{
                        sx: {
                          color: "#4A3AFF",
                          fontWeight: "bold",
                          [`&.${inputLabelClasses.shrink}`]: {
                            color: "#4A3AFF",
                          },
                        },
                      }}
                      label="Trade Name"
                      value={allValues.tradeName}
                      // onChange={(e) => setPassword(e.target.value)}
                      style={{ width: "100%" }}
                      variant="standard"
                      disabled
                    />
                  </div>
                  <div
                    style={{
                      paddingBottom: "10px",
                    }}
                  >
                    <TextField
                      id="constitution"
                      inputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{
                        sx: {
                          color: "#4A3AFF",
                          fontWeight: "bold",
                          [`&.${inputLabelClasses.shrink}`]: {
                            color: "#4A3AFF",
                          },
                        },
                      }}
                      label="Constitution of Business"
                      value={allValues.constitutionOfBusiness}
                      // onChange={(e) => setPassword(e.target.value)}
                      style={{ width: "100%" }}
                      variant="standard"
                      disabled
                    />
                  </div>
                  <div
                    style={{
                      paddingBottom: "10px",
                    }}
                  >
                    <TextField
                      id="administrative"
                      inputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{
                        sx: {
                          color: "#4A3AFF",
                          fontWeight: "bold",
                          [`&.${inputLabelClasses.shrink}`]: {
                            color: "#4A3AFF",
                          },
                        },
                      }}
                      label="Administrative Office"
                      value={allValues.administrativeOffice}
                      // onChange={(e) => setPassword(e.target.value)}
                      style={{ width: "100%" }}
                      variant="standard"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="main-signinGST">
                <div className="formwrap">
                  {/* <form className="form" onSubmit={handleSubmit}> */}
                  <div>
                    <TextField
                      id="username"
                      inputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{
                        sx: {
                          color: "#4A3AFF",
                          fontWeight: "bold",
                          [`&.${inputLabelClasses.shrink}`]: {
                            color: "#4A3AFF",
                          },
                        },
                      }}
                      label="GST Username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      style={{ width: "100%" }}
                      variant="standard"
                      // disabled
                      required
                    />
                  </div>
                  {otpNotGenerated && (
                    <div
                      style={{
                        color: "red",
                        textAlign: "center",
                        marginTop: "10px",
                      }}
                    >
                      OTP Not Generated
                    </div>
                  )}
                  <div style={{ textAlign: "center" }}>
                    <button
                      type="submit"
                      style={{
                        padding: "8px",
                        color: "white",
                        backgroundColor: "green",
                        border: "none",
                        marginTop: "15px",
                        borderRadius: "5px",
                        opacity: allValues.legalName !== "" ? "1" : "0.5",
                      }}
                      disabled={
                        allValues.legalName !== ""
                          ? false
                          : true || isButtonDisabled
                      }
                      onClick={handleButtonClick}
                    >
                      Generate OTP
                    </button>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </form>
            <Snackbar
              open={errorAPi}
              //autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                Invalid GST ID !
              </Alert>
            </Snackbar>
          </div>
        )}

        {showOTP && (
          <ProfileGSTOTP
            gstNumber={gstNumber}
            gstUsername={username}
            accessToken={accessTokenAPI}
            authContextObj={authContextObj}
          />
        )}
      </div>
    </div>
  );
}

export default ProfileGST;
