import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useLocation } from "react-router-dom";

import { CircularProgress, Tooltip } from "@mui/material";
import { controllerAPI } from "../../API/FP/Actions";
import { contextObj } from "../../Common/Constants";
import { NumericFormat } from "react-number-format";
import { useAuth } from "../../../context/auth";
import Pagenotfound from "../../Common/pagenotfound";
import RequestOfferFullDetails from "./RequestOfferFullDetails";

function RequestOffer() {
  const location = useLocation();
  const { state } = useLocation();
  const requestData = location.state?.requestData || {};
  const token = location.state?.authToken;
  const [tradeDetails, setTradeDetails] = useState([]);
  const [showFullOfferDetails, setShowFullOfferDetails] = useState(false);
  const [loading, setLoading] = useState(true);
  const [auth] = useAuth();
  const [currentRequestDetails, setCurrentRequestDetails] = useState({});
  const financeRequestData = state?.financeRequestData;

  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };
  // Pagination  variables
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const lastindex = currentPage * pageSize;
  const firstindex = lastindex - pageSize;
  const recordss = tradeDetails.slice(firstindex, lastindex);
  const numbers = [...Array(totalPages + 1).keys()].slice(1);
  useEffect(() => {
    fetchTradeData();
  }, [currentPage]);

  const tableHeaderNames = [
    "Finance Partner name",
    "Interest p.a (%)",
    "Margin (%)",
    "Tenure (Days)",
    "Action",
  ];

  const fetchTradeData = async () => {
    setLoading(true);
    try {
      const constructObj = contextObj(authContextObj, state.fid);
      const placedOffersResponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        `get-at-placed-offers?page=${
          currentPage - 1
        }&size=${pageSize}&sort=id,desc`
      );

      if (placedOffersResponse.res.status === 200) {
        const filteredData = placedOffersResponse?.res?.data?.data;
        setTradeDetails(filteredData);
        setTotalPages(
          Math.ceil(placedOffersResponse?.res?.data?.totalElements / pageSize)
        );
      }
    } catch (err) {
      console.log("Error fetching data:", err);
    } finally {
      setLoading(false);
    }
  };

  const isOfferAccepted = tradeDetails.filter((item) =>
    ["POAD", "OFAD", "DBCD", "AODCD", "AORIN", "RPCD"].includes(item.status)
  );

  const returnButtonName = (status) => {
    console.log("status is :", status);
    if (isOfferAccepted.length > 0) {
      return status === "OFPD" ||
        status === "RPCD" ||
        status === "AORIN" ||
        status === "DISBURSEMENT_DONE" ||
        status === "DBCD" ||
        status === "AODCD" ||
        status === "POAD" ||
        status === "OFAD" ||
        status === "FRCD"
        ? " View Offer"
        : "Rejected";
    } else {
      if (status === "FRARJ" || status === "FRDL" || status === "FRCD") {
        return "Rejected";
      } else {
        return "View Offer";
      }
    }
  };

  const enableOnClick = (item) => {
    if (isOfferAccepted.length > 0) {
      if (item.status !== "OFPD") {
        setShowFullOfferDetails(true);
        setCurrentRequestDetails(item);
      }
    } else {
      setShowFullOfferDetails(true);
      setCurrentRequestDetails(item);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const ChangePage = (pageNumber) => () => setCurrentPage(pageNumber);

  return (
    <>
      {!showFullOfferDetails && (
        <Dash className="body-text">
          <div className="main-content">
            <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
              Offers received
            </h3>
            <div>
              {tradeDetails.length === 0 && !loading ? (
                <>
                  <table style={{ width: "90%" }}>
                    <tbody>
                      <tr>
                        {tableHeaderNames?.map((x) => (
                          <th key={x}>{x}</th>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                  <Pagenotfound />
                </>
              ) : (
                <table style={{ width: "90%" }}>
                  <tbody>
                    <tr>
                      {tableHeaderNames?.map((x) => (
                        <th key={x}>{x}</th>
                      ))}
                    </tr>
                    {loading ? (
                      <tr>
                        <td colSpan="5">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <CircularProgress />
                          </div>
                        </td>
                      </tr>
                    ) : (
                      tradeDetails?.map((item) => (
                        <tr key={item.id}>
                          <td>{item.financepartner?.customerName}</td>
                          <td>{item.interestPtg}</td>
                          <td>{item.marginPtg}</td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={item.term}
                              thousandsGroupStyle="lakh"
                              thousandSeparator=","
                            />
                          </td>
                          <td>
                            {returnButtonName(item.status) === "Rejected" ? (
                              <Tooltip
                                title="This Offer is rejected by the anchor trader"
                                placement="right"
                              >
                                <button
                                  style={{
                                    borderRadius: "2px",
                                    backgroundColor: "#4A3AFF",
                                    color: "white",
                                    border: "none",
                                    padding: "5px 25px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => enableOnClick(item)}
                                  disabled
                                >
                                  Rejected
                                </button>
                              </Tooltip>
                            ) : (
                              <button
                                style={{
                                  borderRadius: "2px",
                                  backgroundColor: "#4A3AFF",
                                  color: "white",
                                  border: "none",
                                  padding: "5px 25px",
                                  cursor: "pointer",
                                }}
                                onClick={() => enableOnClick(item)}
                              >
                                {returnButtonName(item.status)}
                              </button>
                            )}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              )}

              {tradeDetails.length !== 0 && (
                <>
                  <nav
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                      paddingLeft: "500px",
                    }}
                  >
                    <ul
                      style={{
                        display: "flex",
                        listStyleType: "none",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <li style={{ marginRight: "8px" }}>
                        <p
                          style={{
                            padding: "8px 12px",
                            borderRadius: "4px",
                            cursor:
                              currentPage === 1 ? "not-allowed" : "pointer",
                            backgroundColor:
                              currentPage === 1 ? "#f0f0f0" : "white",
                          }}
                          onClick={handlePrevPage}
                        >
                          {"<"}
                        </p>
                      </li>
                      {/* Showing current page and total pages information */}
                      <li
                        style={{
                          marginRight: "8px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "14px",
                        }}
                      >
                        Showing {currentPage} of {totalPages}
                      </li>
                      <li style={{ marginRight: "8px" }}>
                        <p
                          style={{
                            padding: "8px 12px",
                            borderRadius: "4px",
                            cursor:
                              currentPage === totalPages
                                ? "not-allowed"
                                : "pointer",
                            backgroundColor:
                              currentPage === totalPages ? "#f0f0f0" : "white",
                          }}
                          onClick={handleNextPage}
                        >
                          {">"}
                        </p>
                      </li>
                    </ul>
                  </nav>
                </>
              )}
            </div>
          </div>
        </Dash>
      )}

      {showFullOfferDetails && (
        <RequestOfferFullDetails
          setShowFullOfferDetails={setShowFullOfferDetails}
          data={currentRequestDetails}
          financeRequestData={financeRequestData}
        />
      )}
    </>
  );
}

export default RequestOffer;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 40% !important;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    width: 55%;
    // background: rgba(74, 58, 255, 0.1);
  }
  .main-content {
    margin-top: 30px;
  }
`;

const Dash = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 70% !important;
    border-collapse: collapse;
    // border-left: 1px solid rgba(0, 0, 128, 0.2);
    // border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    // width: 55%;
    background: rgba(74, 58, 255, 0.1);
  }
  .main-content {
    margin-top: 30px;
  }
`;
