import React from 'react';
import styled from 'styled-components';

const TableWidget = ({ columns, data, column1, column2 }) => {
  console.log("Data recieved to table Widget  is :", data)

  const prepDataObj= data?.map((i,index)=>(
    {
      column1:i?.[column2],
      column2:parseFloat(i?.[column1]).toFixed(4)
      
    }
  ))

  return (
    <TableContainer>
      <StyledTable>
        <TableHead>
          <tr>
            {columns?.map((col) => (
              <TableHeader
                key={col.key}
              >
                {col.label}
              </TableHeader>
            ))}
          </tr>
        </TableHead>
        <TableBody>
          {  column1!=null? (prepDataObj?.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns?.map((col) => (
                <TableCell
                  key={col.key}
                >
                  {row[col.key]}
                </TableCell>
              ))}
            </TableRow>
          ))):(data?.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns?.map((col) => (
                <TableCell
                  key={col.key}
                >
                  {row[col.key]}
                </TableCell>
              ))}
            </TableRow>
          ))) }
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

// Styled Components
const TableContainer = styled.div`
  overflow-x: auto;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: white;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d1d5db;
    border-radius: 4px;
    
    &:hover {
      background: #9ca3af;
    }
  }
`;

const StyledTable = styled.table`
  min-width: 100%;
  border-collapse: collapse;
`;

const TableHead = styled.thead`
  background-color: #f9fafb;
  border-bottom: 1px solid #e5e7eb;
`;

const TableHeader = styled.th`
  padding: 0.75rem 1.5rem;
  text-align: left;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #4b5563;
  white-space: nowrap;

  &:first-child {
    padding-left: 1.5rem;
  }

  &:last-child {
    padding-right: 1.5rem;
  }
`;

const TableBody = styled.tbody`
  background-color: white;
  divide-y divide-gray-200;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #e5e7eb;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f9fafb;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const TableCell = styled.td`
  padding: 1rem 1.5rem;
  white-space: nowrap;
  font-size: 0.875rem;
  color: #6b7280;

  &:first-child {
    padding-left: 1.5rem;
  }

  &:last-child {
    padding-right: 1.5rem;
  }
`;

export default TableWidget;