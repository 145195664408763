import React, { useState } from "react";
import styled from "styled-components";
import {
  Button,
  TextField,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useAuth } from "../../../context/auth";
import {
  contextObjforapicallCloseWizard,
  contextObjforCloseWizardReconcileDisb,
  contextObjReconcileRepaymentAction,
  contextObjSetSettlementAction,
  objectforsetSettlement,
  objecttocompleteRepayment,
} from "../../Common/Constants";
import { controllerAPI, opscontroller } from "../../API/FP/Actions";
import { useNavigate } from "react-router-dom";
const Step3 = ({
  step2output,
  handleChange,
  formData,
  handleSubmit,

  wizardRecordId,
  wizardRecordInstanceId,
  setWizardRecordInstanceId,
  wizardPageId,
  setWizardPageId,
  actionButtonId,
  setActionButtonId,
  NextButtonId,
  setNextButtonId,
}) => {
  const handleInputChange = (e) => {
    handleChange(e.target.name, e.target.value);
  };
  const [auth, setAuth] = useAuth();
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [showtable, setshowtable] = useState(false);
  const [showdata, setshowdata] = useState(false);
  const [closeId, setCloseId] = useState(1);
  const [disableBtn, setDisableBtn] = useState(false);
  const [data, setTableData] = useState([]);
  const navigate = useNavigate();
  const [wizardRecordInstanceIdStep1, setwizardRecordInstanceIdStep1] =
    useState(wizardRecordInstanceId);

  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };
  const ActionButtonResponse = async (id) => {
    setLoading(true);
    try {
      setLoading(true);
      const constructObj = contextObjSetSettlementAction(
        authContextObj,
        wizardRecordId,
        wizardRecordInstanceId,
        wizardPageId,
        actionButtonId,
        id
      );
      // Updated API call with pagination parameters
      const ATTradesresponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        `reconcile-repayment-wizard`
      );

      if (ATTradesresponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        navigate("/creditbazaar/");
      }

      if (ATTradesresponse.res.status === 200) {
        console.log("sucess");
        setActionButtonId(actionButtonId + 1);
        setwizardRecordInstanceIdStep1(
          ATTradesresponse?.res?.data?.data?.reconcileRepaymentWizardStepData
            ?.id
        );
        setOpenSnackbar(true);
        setApiResultMessage("Settlement created Succesfully !");
        setAlertSeverity("success");
        setshowdata(true);
        setDisableBtn(true);
        try {
          const parseMsg = JSON.parse(
            ATTradesresponse?.res?.data?.data?.reconcileRepaymentWizardStepData
              ?.apiResponse
          );

          console.log("api response data is:", parseMsg);
          // setstep2output(parseMsg?.data);
          setTableData(parseMsg?.data);
        } catch (error) {
          console.error("Failed to parse API response:", error);
        }

        setDisableBtn(true);
      }
      if (ATTradesresponse?.response?.status === 400) {
        console.log(
          "response?.response?.data?.error",
          ATTradesresponse?.response?.data?.erro
        );
        setOpenSnackbar(true);
        setApiResultMessage(ATTradesresponse?.response?.data?.error);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error while setting settlement :", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error while setting settlement !");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const closBtn = async () => {
    setLoading(true);
    try {
      setLoading(true);
      const constructObj = contextObjforCloseWizardReconcileDisb(
        authContextObj,
        wizardRecordId,
        wizardRecordInstanceIdStep1,
        closeId
      );
      // Updated API call with pagination parameters
      const ATTradesresponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        `reconcile-repayment-wizard`
      );

      if (ATTradesresponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        navigate("/creditbazaar/");
      }

      if (ATTradesresponse.res.status === 200) {
        console.log("sucess");
        window.location.reload();
      }
      if (ATTradesresponse?.response?.status === 400) {
        console.log(
          "response?.response?.data?.error",
          ATTradesresponse?.response?.data?.erro
        );
        setOpenSnackbar(true);
        setApiResultMessage(ATTradesresponse?.response?.data?.error);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error fetching  details:", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error in Reconcile Repayment!");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const setsettlement = async (id) => {
    const constructObj = objectforsetSettlement(id);
    try {
      setLoading(true);
      const response = await opscontroller(
        "post",
        auth?.token,
        constructObj,
        "set-settlement"
      );
      if (response?.response?.status === 401) {
        localStorage.removeItem("auth");
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        navigate("/creditbazaar/");
      }
      if (response?.res?.status === 200) {
        setOpenSnackbar(true);
        setApiResultMessage("Settlement created Succesfully !");
        setAlertSeverity("success");
        setTableData(response?.res?.data?.data);
        setshowtable(true);
        setTableData(response?.res?.data?.data);
        setDisableBtn(true);
        window.location.reload();
      }
      if (response?.response?.status === 400) {
        console.log(
          "response?.response?.data?.error",
          response?.response?.data?.erro
        );
        setOpenSnackbar(true);
        setApiResultMessage(response?.response?.data?.error);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error while setting settlement :", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error while setting settlement !");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  return (
    <StepContainer>
      <div className="step-content">
        <h2>Step 3: Set Settlement</h2>
        <p>Please check the details and click below </p>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <p>
              The Repayment Id is:
              <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                {" "}
                {step2output?.repayment?.id}
              </span>
            </p>
            <p>
              The Remitter name is :
              <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                {step2output?.escrowTransactionDetails?.remitterName}{" "}
              </span>
            </p>
            <p>
              Repayment Status :{" "}
              <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                {step2output?.repayment?.repaymentStatus}
              </span>
            </p>
            <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
              <button
                className="button"
                onClick={() => ActionButtonResponse(step2output?.repayment?.id)}
                disabled={disableBtn === true}
                style={{ opacity: disableBtn === true ? "0.5" : "1" }}
              >
                Set Settlement
              </button>

              <>
                {showdata === true ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "400px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <Button
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          height: "45px",
                          width: "130px",
                          backgroundColor: "white",
                          border: " 1px #404040 solid",
                          cursor: "pointer",
                          height: "fit-content",
                          boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                          margin: "5px",
                          opacity: "1",
                        }}
                        onClick={() => {
                          closBtn();
                        }}
                      >
                        Close
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <p></p>
                  </>
                )}
              </>
            </div>
          </>
        )}

        <Snackbar
          open={openSnackbar}
          //autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity={alertSeverity}
            sx={{ width: "100%" }}
          >
            {apiResultMessage}
          </Alert>
        </Snackbar>
      </div>
    </StepContainer>
  );
};

export default Step3;

const StepContainer = styled.div`
  .step-content {
    max-width: 600px;
    margin: auto;
  }

  .button {
    padding: 10px 20px;
    margin: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }

  .button:hover {
    background-color: #0056b3;
  }
`;
