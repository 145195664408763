import { React, useEffect, useState } from "react";
import styled from "styled-components";
import pimg from "../images/IKF Finance Limited.png";
import { Link } from "react-router-dom";
import FinanceTabone from "./FinanceTabone";
import FinanceTabtwo from "./FinanceTabtwo";
import FinanceTabthree from "./FinanceTabthree";
import FinanceTabFour from "./FinanceTabFour";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";


export default function FinanceProfileOverview() {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <User1>

<div className="pagetitle">
        <h1>Profile</h1>
      </div>

      <div style={{ display: "flex" }}>
        <div className="card">
          <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
            <img src={pimg} alt="Profile" className="img" />
            <h2>Siddartha</h2>
            <h3>Finance Partner</h3>
          </div>
        </div>
        
        <div className="allcont">
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label=" Overview" value="1" />
            <Tab label="Edit Profile" value="2" />
            <Tab label="Change Password" value="3" />
            <Tab label=" KYC" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <FinanceTabone/>
        </TabPanel>
        <TabPanel value="2">
          <FinanceTabtwo/>
        </TabPanel>
        <TabPanel value="3">
          <FinanceTabthree/>
        </TabPanel>
        <TabPanel value="4">
          <FinanceTabFour/>
        </TabPanel>
      </TabContext>
    </Box>
    </div>
    </div>
    </User1>
  );
}

const User1=styled.div`

.pagetitle h1 {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 400;
  color: #012970;
}
.card {
  border: none;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
  width: 32%;
  height: 15%;
  display: flex;
  justify-content: center;
  background: white;
  margin-top: 30px;
}
.card-body {
  padding: 30px 20px 20px 20px;
}
.profile .profile-overview .label {
  font-weight: 400;
  color: rgba(1, 41, 112, 0.6);
}
.col-md-4:first-child {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.allcont {
  border: none;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
  margin-left: 30px;
  background: white;
  margin-top: 30px;
}
.contents{
  display:none;
}

`;
