import styled from "styled-components";
import React from "react";

const data={
  name:"Siddartha",
  company:"IKF Finance",
  persona:"Finance Partner",
  country:"India",
  phone:"040 2341 2083",
  email:"info@ikf.com",
  bankname:"ICICI Bank ",
  account:"123456789014",
  branch:"Gachibowli",
  ifsc:"ICIC0001114",
  receipient:"IKF Finance"

}

function FinanceTabone() {



  return (
    <Finance>
      <div className="tab-content pt-2">
        <div
          className="tab-pane fade profile-overview active show"
          id="profile-overview"
          role="tabpanel"
        >
          <h5 className="card-title">Profile Details</h5>
          <div className="row">
            <div className="col-lg-3 col-md-4 label ">Full Name</div>
            <div className="col-lg-9 col-md-8">{data.name}</div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 label">Company</div>
            <div className="col-lg-9 col-md-8">{data.company}</div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 label">Persona</div>
            <div className="col-lg-9 col-md-8">{data.persona}</div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 label">Country</div>
            <div className="col-lg-9 col-md-8">{data.country}</div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 label">Address</div>
            <div className="col-lg-9 col-md-8">
              CFG4+6PF Central Plaza, #6-3-902/A,PLOT NO.9, BUILDING, Raj Bhavan
              <br/>
              Rd, near YASHODA HOSPITAL, Durga Nagar Colony, Somajiguda,
              <br/>
              Hyderabad, Telangana 500082
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 label">Phone</div>
            <div className="col-lg-9 col-md-8">{data.phone}</div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 label">Email</div>
            <div className="col-lg-9 col-md-8">{data.email}</div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 label">Bank Name</div>
            <div className="col-lg-9 col-md-8">{data.bankname}</div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 label">Bank Account</div>
            <div className="col-lg-9 col-md-8">{data.account}</div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 label">Branch</div>
            <div className="col-lg-9 col-md-8">{data.branch}</div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 label">IFSC Code</div>
            <div className="col-lg-9 col-md-8">{data.ifsc}</div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 label">Receipient</div>
            <div className="col-lg-9 col-md-8">{data.receipient}</div>
          </div>
        </div>
        
      </div>
    </Finance>
  );
}

export default FinanceTabone;

const Finance = styled.div`

.tab-content {
    padding-top: 2rem;
}

.label {
   
    font-weight: 600;
    font-size:22px;
    color: rgba(1, 41, 112, 0.6);
    width:300px;
}

.row {
    display:flex;
    margin-bottom: 10px;
    padding-left:30px;
    
    
}
.card-title{
  font-weight:bold;
  font-size:24px;
}
`;
