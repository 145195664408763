import axios from "axios";

export const getOpenRequests = async (authToken) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_URL}api/request-offers`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const getOfferRequests = async (authToken) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_URL}api/placed-offers`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const getOfferData = async (authToken) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_URL}api/accepted-offers`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const getDisbursementData = async (authToken) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_URL}api/disbursements`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const getRepaymentData = async (authToken) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_URL}api/repayments`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const getTransactionData = async (authToken) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_URL}api/collection-transaction-details`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const getTradeData = async (authToken) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_URL}api/trades`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  return await axios(config)
    .then((res) => {
      return { res };
    })
    .catch((err) => {
      return err;
    });
};

export const patchOfferStatus = async (authToken, requestData) => {
  var config = {
    method: "put",
    url: `${process.env.REACT_APP_BACKEND_URL}api/request-offers/${requestData.id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    data: requestData,
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const getAllInvoicesDetails = async (authToken) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_URL}api/trades`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const getAnchorTraderDetails = async (authToken) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_URL}api/anchor-traders`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const sendPlacedOfferDetails = async (authToken, requestData) => {
  var config = {
    method: "post",
    // url: `${process.env.REACT_APP_BACKEND_URL}api/placed-offers/${requestData.id}`,
    url: `${process.env.REACT_APP_BACKEND_URL}api/placed-offers`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    data: requestData,
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const getPFTCreditRecommendationScores = async (authToken) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_URL}api/individual-assessments`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const getObservations = async (authToken) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_URL}api/cre-observations`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const callAPI = async (authToken, requestData, type) => {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_URL}controller/${type}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    data: requestData,
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const controllerAPI = async (method, authToken, requestData, type) => {
  var config = {
    method: method,
    url: `${process.env.REACT_APP_BACKEND_URL}controller/${type}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    data: requestData,
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const opscontroller = async (method, authToken, requestData, type) => {
  var config = {
    method: method,
    url: `${process.env.REACT_APP_API}/api/service/creditbazaar/controller/${type}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    data: requestData,
  };
  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const controllerAPIForCRELimits = async (
  method,
  authToken,
  requestData,
  atUlidId,
  type
) => {
  var config = {
    method: method,
    url: `${process.env.REACT_APP_BACKEND_URL}controller/${type}`,
    anchorTraderUlidId: atUlidId,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },

    data: requestData,
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const controllerAPIForFileUpload = async (
  method,
  authToken,
  selectedFile,
  type,
  transactionDate,
  userId,
  tenantId,
  atId,
  clientCode,
  typeOfFirm,
  kycMasterId
) => {
  var temp = {};

  var test = JSON.stringify(temp);
  var formdata = new FormData();
  if (selectedFile !== null) {
    // const bl = new Blob([test], { type: "application/json" });
    const ll = new Blob([selectedFile], { type: `${selectedFile.type}` });
    // formdata.append("corporateUserRequest", bl);
    formdata.append("file", ll, `${selectedFile.name}`);
  }
  // const bl = new Blob([test], { type: "application/json" });
  const ll = new Blob([selectedFile]);
  // formdata.append("fileUpload", bl);
  var config = {
    method: method,
    url: `${process.env.REACT_APP_BACKEND_URL}controller/${type}?transactionDate=${transactionDate}&userId=${userId}&tenantId=${tenantId}&personaCode=1&id=${atId}&clientCode=${clientCode}&typeOfFirm=${typeOfFirm}&kycMasterId=${kycMasterId}`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${authToken}`,
    },
    data: formdata,
  };

  return await axios(config)
    .then((res) => {
      // if (res.data.success) {
      return { res };
      // }
    })
    .catch((err) => {
      return err;
    });
};
