import React from "react";
import styled from "styled-components";

function FinanceTabthree() {
  return (
    <Finance>
      <div
        className="tab-pane fade pt-3 active show"
        id="profile-change-password"
        role="tabpanel"
      >
       
        <form>
          <div className="row mb-3">
            <label
              htmlFor="currentPassword"
              className="col-md-4 col-lg-3 col-form-label"
            >
              Current Password
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                name="password"
                type="password"
                className="form-control"
                id="currentPassword"
              />
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="newPassword"
              className="col-md-4 col-lg-3 col-form-label"
            >
              New Password
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                name="newpassword"
                type="password"
                className="form-control"
                id="newPassword"
              />
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="renewPassword"
              className="col-md-4 col-lg-3 col-form-label"
            >
              Re-enter New Password
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                name="renewpassword"
                type="password"
                className="form-control"
                id="renewPassword"
              />
            </div>
          </div>
          <div className="text-center">
            <button type="submit" className="btn btn-primary" style={{marginTop:"30px",marginLeft:"48%"}}>
              Change Password
            </button>
          </div>
        </form>
        
      </div>
    </Finance>
  );
}

export default FinanceTabthree;

const Finance = styled.div`



.col-form-label {
    width:250px;
   font-weight: 600;
   font-size:22px;
//    color: rgba(1, 41, 112, 0.6);
  
 }
 .row{
   display:flex;
   margin-top:30px;
 }
 .form-control{
  
 }
 input[type="password"],input[type="renewPassword"] {
  
   padding: 10px;
   border: 1px solid #ccc;
   border-radius: 4px;
   box-sizing: border-box;
   width:615px;
   height:50px;
 }
 .btn {
    color: white;
    background-color: #007BFF;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-left: 90px;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
 

 
`;
