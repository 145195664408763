import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../context/auth";
import Footer from "../Common/Footer";
import OTPValidation from "../images/OTPVerifcation.png";
import { Alert, CircularProgress, Snackbar, Tooltip } from "@mui/material";
import { multigstController, signUp } from "../Common/Constants";
import { controllerAPI } from "../API/FP/Actions";

import iSymbol from "../images/Isymbol.png";
import Signup3 from "./Signup3";

function Signup2({ phoneNumber }) {
  const [auth, setAuth] = useAuth();
  const [spinnerLoading, setIsspinnerLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [noneState, setNoneState] = useState(false);
  const [timeLeft, setTimeLeft] = useState(600); // 10 minutes in seconds
  const [isDisabled, setIsDisabled] = useState(true);
  const [otp, setOtp] = useState("");
  const [step3, setStep3] = useState(false);

  const handleInputChange = (event) => {
    setOtp(event.target.value);
  };

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setIsDisabled(false);
    }
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const maskPhoneNumber = (phoneNumber) => {
    // Replace all digits except for the last two with stars
    const lastTwoDigits = phoneNumber.slice(-2);
    const maskedPart = phoneNumber.slice(1, -2).replace(/\d/g, "*");
    return maskedPart + lastTwoDigits;
  };

  const resendOtpClicked = () => {
    setIsDisabled(true);
  };
  const navigate = useNavigate();

  const VerifyOtpClicked = () => {
    setStep3(true);
  };
  return (
    <>
      {step3 === false ? (
        <>
          {spinnerLoading ? (
            <>
              <CircularProgress
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                }}
              />
            </>
          ) : (
            <>
              <Sign1>
                <div className="mainContent">
                  <div className="firstSection">
                    {" "}
                    <img
                      src={OTPValidation}
                      style={{ width: "70%", height: "50%" }}
                    />
                  </div>
                  <div className="secondSection">
                    <form className="Form">
                      <p
                        style={{
                          width: "100%",
                          color: "black",
                          fontSize: "30",
                          fontWeight: "700",
                          wordWrap: "break-word",
                        }}
                      >
                        Verification
                      </p>
                      <p
                        style={{
                          width: "100%",
                          color: "black",
                          fontSize: "17",
                          fontWeight: "400",
                          wordWrap: "break-word",
                        }}
                      >
                        We’ve send you the verification code <br /> on +91{" "}
                        {maskPhoneNumber(phoneNumber)}
                      </p>

                      <div className="firstInput">
                        <div
                          className="DivForInfoButton"
                          style={{
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <label htmlFor="username">Enter OTP</label>
                          <Tooltip
                            title="Enter OTP sent to your registered mobile number"
                            placement="right"
                          >
                            <img src={iSymbol} />
                          </Tooltip>
                        </div>

                        <input
                          type="txt"
                          id="orgid"
                          name=""
                          value={otp}
                          onChange={handleInputChange}
                          placeholder="Enter OTP"
                          required
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          flexDirection: "column",
                        }}
                      >
                        {/* {error && <div className="error-message">{error}</div>} */}

                        <button
                          type="submit"
                          className="login-button"
                          style={{
                            backgroundColor: otp ? "#28A745" : "#6C757D",
                          }}
                          disabled={!otp}
                          onClick={VerifyOtpClicked}
                        >
                          {/* {isLoading ? <div className="spinner"></div> : "Log In"} */}
                          Verify and create account
                        </button>
                      </div>
                    </form>{" "}
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {
                        <p>
                          <Link
                            to="/creditbazaar/signup1"
                            style={{
                              textDecoration: "underline",
                              color: "black",
                              fontWeight: "500",
                              opacity: isDisabled ? "0.5" : "1", // Change opacity based on timer
                              pointerEvents: isDisabled ? "none" : "auto", // Disable click when the link is inactive
                            }}
                            onClick={resendOtpClicked}
                          >
                            Re-send
                          </Link>{" "}
                          OTP in {formatTime(timeLeft)}
                        </p>
                      }
                    </div>
                  </div>
                </div>
              </Sign1>
            </>
          )}
        </>
      ) : (
        <>
          <Signup3 />
        </>
      )}
    </>
  );
}

export default Signup2;

const Sign1 = styled.div`
  * {
    font-family: "Poppins";
  }
  .mainContent {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
  }
  .firstSection {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #297fff;
  }
  .secondSection {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .DivForInfoButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  input[type="number"],
  input[type="txt"],
  input[type="password"] {
    height: 30px;
    width: 350px;
    margin-bottom: 20px;
    padding: 5px;
  }

  button[type="submit"],
  button [type="button"] {
    font-size: 16px;
    color: white;
    height: 40px;
    width: 60%;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .login-button {
    font-size: 16px;
    height: 40px;
    width: 50%;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 600;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
`;
