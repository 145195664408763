import React, { useState } from "react";
import { Alert, Input, Modal, Snackbar } from "@mui/material";
import { TextField, Box, Button, Typography } from "@mui/material";

function Component4({
  isctive,
  nextStep,
  prevStep,
  setIsLoading,
  tradeMasterDocReader,
  setTPGSTID,
  pdfUrl,
}) {
  const handleNextStep = () => {
    nextStep();
  };

  const initialFormValues = {
    sourceName: tradeMasterDocReader?.sourceName || "",
    sourceVal: tradeMasterDocReader?.sourceVal || "",
    targetName: tradeMasterDocReader?.targetName || "",
    targetVal: tradeMasterDocReader?.targetVal || "",
    tradeAdvance: tradeMasterDocReader?.tradeAdvance,
    tradeAmount: tradeMasterDocReader?.tradeAmount || "",
    tradeDate: tradeMasterDocReader?.tradeDate || "",
    tradeDocumentCredibility:
      tradeMasterDocReader?.tradeDocumentCredibility || "",
    tradeMileStone: tradeMasterDocReader?.tradeMileStone || "",
    tradeNo: tradeMasterDocReader?.tradeNo || "",
    tradeSource: tradeMasterDocReader?.tradeSource || "",
    tradeStatus: tradeMasterDocReader?.tradeStatus || "",
    tradeType: tradeMasterDocReader?.tradeType || "",
    channelData: tradeMasterDocReader?.channelData || "",
    hasTradeAdvanceAmount: tradeMasterDocReader?.hasTradeAdvanceAmount || false,
    hsnCode: tradeMasterDocReader?.hsnCode || "",
    irn: tradeMasterDocReader?.irn || "",
    isDisbursed: tradeMasterDocReader?.isDisbursed || false,
    isManuallyAdded: tradeMasterDocReader?.isManuallyAdded || false,
    isOfferAccepted: tradeMasterDocReader?.isOfferAccepted || false,
    isRepaid: tradeMasterDocReader?.isRepaid || false,
    isSettled: tradeMasterDocReader?.isSettled || false,
    metricEInvoiceDoc: tradeMasterDocReader?.metricEInvoiceDoc || "",
    metricEInvoiceFlag: tradeMasterDocReader?.metricEInvoiceFlag || false,
    metricEWayBillFlag: tradeMasterDocReader?.metricEWayBillFlag || false,
    metricGRNDoc: tradeMasterDocReader?.metricGRNDoc || "",
    metricGRNFlag: tradeMasterDocReader?.metricGRNFlag || false,
    metricTPConfirmationDoc:
      tradeMasterDocReader?.metricTPConfirmationDoc || "",
    sacCode: tradeMasterDocReader?.sacCode || "",
    tradeDocumentDescription:
      tradeMasterDocReader?.tradeDocumentDescription || "",
    tradePartnerGSTComplianceScore:
      tradeMasterDocReader?.tradePartnerGSTComplianceScore || "",
    tradePartnerLocation: tradeMasterDocReader?.tradePartnerLocation || "",
    tradeTerms: tradeMasterDocReader?.tradeTerms || "",
  };

  // State to manage form data
  const [formValues, setFormValues] = useState(initialFormValues);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    nextStep();
    e.preventDefault();
    console.log("Form Values:", formValues);
    setTPGSTID(formValues?.targetVal);
  };

  return (
    <div
      className="ThirdComponent"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "50px 50px 0px",
      }}
    >
      <h3
        style={{
          color: "black",
          fontSize: "18px",
          fontWeight: "600",
          lineHeight: "2",
          wordWrap: "break-word",
          textAlign: "left",
        }}
      >
        Review and select the files from the list below to proceed
      </h3>
      <div
        className="PdfDocHolderComp"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
          width: "110%",
          height: "800px",
          gap: "10px",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <div
          className="leftComponent"
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#f9f9f9",
            border: "1px solid",
          }}
        >
          <iframe
            src={pdfUrl}
            title="PDF viewer"
            style={{
              border: "none",
              width: "100%",
              height: "100%",
              display: "block",
              overflow: "auto",
              margin: "0",
              padding: "30px",
            }}
            scrolling="yes"
          />
        </div>
        <div
          className="rightComponent"
          style={{
            flex: 1.5,
            display: "flex",
            flexDirection: "column",
            borderLeft: "1px solid #ddd",
            padding: "20px",
            background: "#fff",
          }}
        >
          <h2
            style={{
              fontWeight: "700",
              color: "black",
              marginBottom: "20px",
            }}
          >
            Summary of Your Trade Detail
          </h2>
          <div
            style={{
              maxHeight: "650px",
              overflowY: "scroll",
              padding: "10px 20px",
              borderRadius: "10px",
              scrollbarWidth: "thin",
            }}
          >
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: "100%",
                maxWidth: 400,
                margin: "auto",
                mt: 4,
              }}
            >
              <Box>
                <Typography variant="subtitle1">Source Name *</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="sourceName"
                  value={formValues.sourceName}
                  onChange={handleInputChange}
                  required
                  placeholder="Source Name"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">Source GST *</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="sourceVal"
                  value={formValues.sourceVal}
                  onChange={handleInputChange}
                  required
                  placeholder="Source GST"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">Target Name *</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="targetName"
                  value={formValues.targetName}
                  onChange={handleInputChange}
                  placeholder="Target Name"
                  required
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">Target GST *</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="targetVal"
                  value={formValues.targetVal}
                  required
                  onChange={handleInputChange}
                  placeholder="Target GST"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">Trade Advance</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="tradeAdvance"
                  value={formValues.tradeAdvance}
                  onChange={handleInputChange}
                  placeholder="Trade Advance"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">Trade Amount</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="tradeAmount"
                  value={formValues.tradeAmount}
                  onChange={handleInputChange}
                  placeholder="Trade Amount"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">Trade Date</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="tradeDate"
                  value={formValues.tradeDate}
                  onChange={handleInputChange}
                  placeholder="Trade Date"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">
                  Trade Document Credibility
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="tradeDocumentCredibility"
                  value={formValues.tradeDocumentCredibility}
                  onChange={handleInputChange}
                  placeholder="Trade Document Credibility"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">Trade Mile Stone</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="tradeMileStone"
                  value={formValues.tradeMileStone}
                  onChange={handleInputChange}
                  placeholder="Trade Mile Stone"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">Trade No</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="tradeNo"
                  value={formValues.tradeNo}
                  onChange={handleInputChange}
                  placeholder="Trade No"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">Trade Source</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="tradeSource"
                  value={formValues.tradeSource}
                  onChange={handleInputChange}
                  placeholder="Trade Source"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">Trade Status</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="tradeStatus"
                  value={formValues.tradeStatus}
                  onChange={handleInputChange}
                  placeholder="Trade Status"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">Trade Type</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="tradeType"
                  value={formValues.tradeType}
                  onChange={handleInputChange}
                  placeholder="Trade Type"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">Channel Data</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="channelData"
                  value={formValues.channelData}
                  onChange={handleInputChange}
                  placeholder="Channel Data"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">
                  Has Trade Advance Amount
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="hasTradeAdvanceAmount"
                  value={formValues.hasTradeAdvanceAmount}
                  onChange={handleInputChange}
                  placeholder="Has Trade Advance Amount"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">HSN Code</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="hsnCode"
                  value={formValues.hsnCode}
                  onChange={handleInputChange}
                  placeholder="HSN Code"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">IRN</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="irn"
                  value={formValues.irn}
                  onChange={handleInputChange}
                  placeholder="IRN"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">Is Disbursed</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="isDisbursed"
                  value={formValues.isDisbursed}
                  onChange={handleInputChange}
                  placeholder="Is Disbursed"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">Is Manually Added</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="isManuallyAdded"
                  value={formValues.isManuallyAdded}
                  onChange={handleInputChange}
                  placeholder="Is Manually Added"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">Is Offer Accepted</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="isOfferAccepted"
                  value={formValues.isOfferAccepted}
                  onChange={handleInputChange}
                  placeholder="Is Offer Accepted"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">Is Repaid</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="isRepaid"
                  value={formValues.isRepaid}
                  onChange={handleInputChange}
                  placeholder="Is Repaid"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">Is Settled</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="isSettled"
                  value={formValues.isSettled}
                  onChange={handleInputChange}
                  placeholder="Is Settled"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">Metric EInvoice Doc</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="metricEInvoiceDoc"
                  value={formValues.metricEInvoiceDoc}
                  onChange={handleInputChange}
                  placeholder="Metric EInvoice Doc"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">
                  Metric EWay Bill Flag
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="metricEWayBillFlag"
                  value={formValues.metricEWayBillFlag}
                  onChange={handleInputChange}
                  placeholder="Metric EWay Bill Flag"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">Metric GRNDoc</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="metricGRNDoc"
                  value={formValues.metricGRNDoc}
                  onChange={handleInputChange}
                  placeholder="Metric GRNDoc"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">Metric GRN Flag</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="metricGRNFlag"
                  value={formValues.metricGRNFlag}
                  onChange={handleInputChange}
                  placeholder="Metric GRN Flag"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">
                  Metric TPConfirmation Doc
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="metricTPConfirmationDoc"
                  value={formValues.metricTPConfirmationDoc}
                  onChange={handleInputChange}
                  placeholder="Metric TPConfirmation Doc"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">Sac Code</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="sacCode"
                  value={formValues.sacCode}
                  onChange={handleInputChange}
                  placeholder="Sac Code"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">
                  Trade Document Description
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="tradeDocumentDescription"
                  value={formValues.tradeDocumentDescription}
                  onChange={handleInputChange}
                  placeholder="Trade Document Description"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">
                  Trade Partner GST Compliance Score
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="tradePartnerGSTComplianceScore"
                  value={formValues.tradePartnerGSTComplianceScore}
                  onChange={handleInputChange}
                  placeholder="Trade Partner GST Compliance Score"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">
                  Trade Partner Location
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="tradePartnerLocation"
                  value={formValues.tradePartnerLocation}
                  onChange={handleInputChange}
                  placeholder="Trade Partner Location"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1">Trade Terms</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="tradeTerms"
                  value={formValues.tradeTerms}
                  onChange={handleInputChange}
                  placeholder="Trade Terms"
                />
              </Box>
            </Box>
          </div>
          <div
            className="ButtonsPlacement"
            style={{
              marginTop: "60px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center ",
              gap: "20px",
              width: " 100%",
            }}
          >
            <button
              className="Backbtn"
              style={{
                color: "black",
                height: "45px",
                width: "120px",
                backgroundColor: "white",
                border: "1px solid",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px 36px",
                borderRadius: "3px",
                cursor: "pointer ",
                // alignSelf: "flexStart",
              }}
              onClick={() => prevStep()}
            >
              Back
            </button>
            <button
              type="button"
              disabled={
                !formValues.sourceName ||
                !formValues.sourceVal ||
                !formValues.targetName ||
                !formValues.targetVal
              }
              style={{
                opacity:
                  formValues.sourceName &&
                  formValues.sourceVal &&
                  formValues.targetName &&
                  formValues.targetVal
                    ? "1"
                    : "0.5",
                fontSize: "18px",
                backgroundColor: "#297fff",
                fontFamily: "Open Sans, sans-serif",
                fontWeight: "400",
                color: "white",
                height: "45px",
                width: "120px",
                border: "none",
                borderRadius: "3px",
                cursor:
                  formValues.sourceName &&
                  formValues.sourceVal &&
                  formValues.targetName &&
                  formValues.targetVal
                    ? "pointer"
                    : "not-allowed",
              }}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <Snackbar
        open={openSnackbar}
        //autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {apiResultMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Component4;
